import { AbstractControl, ValidationErrors } from '@angular/forms';
import { countryRules } from './iban.validator';


// BIC format, ex: LLLL LL XX XXX
//            bank country location branch
export const bicRegexp: RegExp = /^ *([A-Z]{4})([A-Z]{2})([A-Z0-9]{2})([A-Z0-9]{3})? *$/;

export function bicValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return null;
  }
  const bic = control.value.toUpperCase().replace(/[^A-Z0-9]/g, '');
  const code = bic.match(bicRegexp);
  return code && Object.keys(countryRules).includes(code[2]) ? null : { bic: { country: code ? code[2] : null } };
}
