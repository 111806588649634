<div class="mt-3"></div>
<mat-grid-list  rowHeight="20px" gutterSize="25px" *ngFor="let rowConf of config; let i = index" cols="{{userInfo && i == '1' ? '16':'12'}}" class="mt-3">
    <mat-grid-tile class="shadow" *ngFor="let conf of rowConf | keyvalue :nosort; let index=index" colspan="4" [rowspan]="i>=1?3:5">
        <mat-card>
            <mat-card-header class="d-flex justify-content-between">
                <mat-card-title class="color-primary-50"><h4>{{conf.key}}</h4></mat-card-title>
                <mat-icon class="color-primary-600 w-50 mr-4" [svgIcon]="iconMapping[conf.key]"></mat-icon>
            </mat-card-header>
            <mat-card-content>
                <div *ngFor="let c of conf.value" class="d-flex">
                    <div *ngIf="c.key" class="col-auto pl-0 pr-1">{{c.key}} : </div>
                    <div class="card-content-value">{{(displayValue(c.value) && c.mapping)?c.mapping(displayValue(c.value)):displayValue(c.value)}}</div>
                </div>
            </mat-card-content>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>
