import { Component, ElementRef, forwardRef, Input, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormArray, FormControl, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { FormUtilsService } from 'src/app/services/form-utils/form-utils.service';
import { ibanValidatorForm } from '../../validators/iban.validator';

@Component({
  selector: 'app-iban-form',
  templateUrl: './iban-form.component.html',
  styleUrls: ['./iban-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IbanFormComponent),
      multi: true
    }
  ]
})
export class IbanFormComponent implements ControlValueAccessor, OnInit {

  @Input() label: string;
  @Input() error: string;
  @Input() maxLength = 32;          // Total size
  @Input() size = 4;                // (max) Number of characters per segment
  @Input() segments: number = null; // Number of segments
  @Input() set value(v: string) {
    this.writeValue(v);
  }
  constructor(protected formUtilsService: FormUtilsService, private elementRef: ElementRef) { }

  form = this.formUtilsService.buildForm([]) as FormArray;

  onfocus(event?) {
    this.form.markAllAsTouched();
    this.elementRef.nativeElement.querySelectorAll('mat-form-field').forEach(element => {
      element.classList.add('mat-focused');
    });
  }
  onblur(event?) {
    this.elementRef.nativeElement.querySelectorAll('mat-form-field').forEach(element => {
      element.classList.remove('mat-focused');
    });
  }

  ngOnInit(): void {
    this.maxLength = Number(this.maxLength);
    if (this.segments) {
      this.segments = Number(this.segments);
      if (this.segments < 1) {
        this.segments = 1;
      } else if (this.segments > this.maxLength) {
        this.segments = this.maxLength;
      }
      this.size = Math.ceil(this.maxLength / this.segments);
    } else if (this.size) {
      this.size = Number(this.size);
      if (this.size < 1) {
        this.size = 1;
      } else if (this.size > this.maxLength) {
        this.size = this.maxLength;
      }
      this.segments = Math.ceil(this.maxLength / this.size);
    }

    for (let i = 0; i < this.segments; i++) {
      this.form.push(new FormControl(null, (control: AbstractControl): ValidationErrors | null => control?.parent?.errors));
    }

    this.form.setValidators([ibanValidatorForm]);
    this.form.updateValueAndValidity();
  }

  pasteFunction(ev: ClipboardEvent, i: number): void {
    ev.preventDefault();
    const target = this.elementRef.nativeElement.querySelector('#seg' + i);
    const start = target.selectionStart;
    const clipText = (ev.clipboardData || ( window as any).clipboardData).getData('text');
    const value = this.form.getRawValue().join('');
    this.writeValue(value.substring(0, i * this.size + target.selectionStart) + clipText + value.substring(i * this.size + target.selectionEnd, this.maxLength));
    this.focusAtCharacter(i * this.size + start + clipText.length);
  }

  focusAtCharacter(i: number): void {
    const target = this.elementRef.nativeElement.querySelector('#seg' + Math.floor(i / this.size));
    if (target) {
      target.focus({ preventScroll: true });
      target.selectionStart = i % this.size;
      target.selectionEnd = i % this.size;
    }
    setTimeout(()=>this.onfocus(),100);
  }

  KeyDownHandler(event: KeyboardEvent, i: number): void {
    const elem = this.elementRef.nativeElement.querySelector('#seg' + i);
    let characterStart = this.size * i + elem.selectionStart;
    let characterEnd = this.size * i + elem.selectionEnd;
    const value = this.form.getRawValue().join('');
    if (event.key.length == 1 && !event.ctrlKey && !event.altKey && !event.metaKey) {
      event.preventDefault();
      this.writeValue(value.substring(0, characterStart) + event.key + value.substring(characterEnd, this.maxLength));
      this.focusAtCharacter(characterStart + 1);
    } else {
      switch (event.key || event.code) {
        case 'Backspace': {
          event.preventDefault();
          if (characterStart == characterEnd) { characterStart--; }
          this.writeValue(value.substring(0, characterStart) + value.substring(characterEnd, this.maxLength));
          this.focusAtCharacter(characterStart);
          break;
        }
        case 'Delete': {
          event.preventDefault();
          if (characterStart == characterEnd) { characterEnd++; }
          this.writeValue(value.substring(0, characterStart) + value.substring(characterEnd, this.maxLength));
          this.focusAtCharacter(characterStart);
          break;
        }
        case 'ArrowLeft': {
          event.preventDefault();
          this.focusAtCharacter(characterStart - 1);
          break;
        }
        case 'ArrowRight': {
          event.preventDefault();
          this.focusAtCharacter(characterStart + 1);
          break;
        }
      }
    }
  }

  writeValue(obj: any) {
    if(obj)this.form.markAllAsTouched();
    this.form.patchValue((obj || '').replace(/\s/g, '').substring(0, this.maxLength).padEnd(this.maxLength).match(new RegExp('.{1,' + this.size + '}', 'g')) || []);
  }

  registerOnChange(fn: any): void {
    this.form.valueChanges.subscribe(v => fn(v.join('')));
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

}
