import {Component, ModuleWithProviders} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {OffersComponent} from './components/offers/offers.component';
import {CustomerComponent} from './components/customers/customer/customer.component';
import {CustomersComponent} from './components/customers/customers.component';
import {LogoutComponent} from './components/logout/logout.component';
import {LoginComponent} from './components/login/login.component';
import {OrderComponent} from './components/orders/order/order.component';
import {OrdersComponent} from './components/orders/orders.component';
import {OfferComponent} from './components/offers/offer/offer.component';
import {AuthGuard} from './guards/auth/auth.guard';
import {ReferentialResolver} from './resolvers/referential/referential.resolver';
import {MessagesComponent} from './components/messages/messages.component';
import {MessageComponent} from './components/messages/message/message.component';
import {CerteuropeDisplayComponent} from './utils/display/certeurope-display/certeurope-display.component';

const appRoutes: Routes = [
  {
    path: 'signature',
    runGuardsAndResolvers: 'always',
    data: {
      title: 'Signature electronique',
      roles: [],
      groups: []
    },
    children: [
      {
        path: ':idOrder/:hash',
        data: {
          title: ':idOrder/:hash',
          resolve: {
            order: {
              url: 'inactive/certeurope/order/:idOrder/:hash'
            }
          }
        },
        resolve: {
          resolve: ReferentialResolver
        },
        component: CerteuropeDisplayComponent,
      },
      {
        path: '**',
        component: CerteuropeDisplayComponent,
      },
      {
        path: '',
        component: CerteuropeDisplayComponent,
      }
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    data: {
      title: 'Mon TPE',
      roles: [],
      groups: []
    },
    children: [
      {
        path: 'orders',
        data: {
          title: 'Suivi de commandes'
        },
        children: [
          {
            path: '',
            component: OrdersComponent,
          },
          {
            path: ':id',
            data: {
              title: ':id',
              resolve: {
                order: {
                  url: 'orders/:id'
                },
                actions: {
                  options: {
                    params: {
                      searchCriterias: JSON.stringify(
                        [
                          {
                            logical: 'and',
                            path: 'order.id',
                            operation: 'equal',
                            value: ':id'
                          }
                        ]
                      ),
                      sort: 'date,id,asc'
                    }
                  }
                },
                nepting: {
                  url: 'nepting/neptings',
                  options: {
                    params: {
                      idOrder: ':id',
                      sort: 'id,asc'
                    }
                  }
                },
                refOffers: {
                  // options: {
                  //     params: {
                  //         id: "sessionStorage.idOffer"
                  //     }
                  // }
                }
              }
            },
            resolve: {
              resolve: ReferentialResolver
            },
            component: OrderComponent,
          },
        ]
      },
      {
        path: 'offers',
        data: {
          title: 'Nouvelle commande'
        },
        children: [
          {
            path: '',
            data: {
              resolve: {
                refOffers: {
                  options: {
                    params: {
                      id: 'sessionStorage.idOffer'
                    }
                  }
                },
                refSoftwares: {
                  options: {
                    params: {
                      idOffer: 'sessionStorage.idOffer',
                      date: 'sessionStorage.date'
                    }
                  }
                }
              }
            },
            resolve: {
              resolve: ReferentialResolver
            },
            component: OffersComponent,
          },
          {
            path: ':id',
            data: {
              title: ':id',
              resolve: {
                refOffer: {
                  url: 'refOffers/:id'
                },
                refEquipments: {
                  url: 'inactive/refEquipments',
                  options: {
                    params: {
                      sort: 'installationType,id,asc',
                      idOffer: ':id',
                      date: 'sessionStorage.date'
                    }
                  }
                },
                inactiveRefEquipments: {
                  url: 'inactive/refEquipments',
                  options: {
                    params: {
                      sort: 'installationType,id,asc',
                      // idOffer: ":id",
                      date: 'sessionStorage.date'
                    }
                  }
                },
                refProductRequirements: {
                  options: {
                    params: {
                      searchCriterias: JSON.stringify(
                        [
                          {
                            logical: 'or',
                            modifier: null,
                            path: 'createdDate',
                            operation: 'lessThanOrEqualTo',
                            value: ['sessionStorage.date']
                          },
                          {
                            logical: 'or',
                            modifier: null,
                            path: 'createdDate',
                            operation: 'isNull',
                            value: null
                          },
                          {
                            logical: 'and',
                            modifier: null,
                            path: 'idOffer',
                            operation: 'equal',
                            value: ':id'
                          },
                          {
                            logical: 'or',
                            modifier: null,
                            path: 'updatedDate',
                            operation: 'greaterThanOrEqualTo',
                            value: ['sessionStorage.date']
                          },
                          {
                            logical: 'or',
                            modifier: null,
                            path: 'updatedDate',
                            operation: 'isNull',
                            value: null
                          }
                        ]
                      )
                    }
                  }
                },
                refProductIncompatibilities: {
                  options: {
                    params: {
                      searchCriterias: JSON.stringify(
                        [
                          {
                            logical: 'or',
                            modifier: null,
                            path: 'createdDate',
                            operation: 'lessThanOrEqualTo',
                            value: ['sessionStorage.date']
                          },
                          {
                            logical: 'or',
                            modifier: null,
                            path: 'createdDate',
                            operation: 'isNull',
                            value: null
                          },
                          {
                            logical: 'and',
                            modifier: null,
                            path: 'idOffer',
                            operation: 'equal',
                            value: ':id'
                          },
                          {
                            logical: 'or',
                            modifier: null,
                            path: 'updatedDate',
                            operation: 'greaterThanOrEqualTo',
                            value: ['sessionStorage.date']
                          },
                          {
                            logical: 'or',
                            modifier: null,
                            path: 'updatedDate',
                            operation: 'isNull',
                            value: null
                          }
                        ]
                      )
                    }
                  }
                }
              }
            },
            resolve: {
              resolve: ReferentialResolver
            },
            component: OfferComponent,
          }
        ]
      },
      {
        path: 'customers',
        data: {
          title: 'Mes clients'
        },
        children: [
          {
            path: '',
            component: CustomersComponent,
          },
          {
            path: ':id',
            data: {
              title: ':id',
              resolve: {
                refCustomer: {
                  url: 'refCustomers/:id',
                },
                actions: {
                  options: {
                    params: {
                      searchCriterias: JSON.stringify(
                        [
                          {
                            logical: 'and',
                            path: 'order.customer.idRef',
                            operation: 'equal',
                            value: ':id'
                          }
                        ]
                      )
                    }
                  }
                },
                refOffers: {
                  options: {
                    params: {
                      id: 'sessionStorage.idOffer'
                    }
                  }
                }
              }
            },
            resolve: {
              resolve: ReferentialResolver
            },
            component: CustomerComponent,
          },
        ]
      },
      {
        path: 'messages',
        canActivate: [AuthGuard],
        data: {
          title: 'Messages',
          roles: [],
          groups: ['admin']
        },
        children: [
          {
            path: '',
            component: MessagesComponent,
          },
          {
            path: ':id',
            data: {
              title: ':id',
              resolve: {
                message: {
                  url: 'messages/:id',
                }
              }
            },
            resolve: {
              resolve: ReferentialResolver
            },
            component: MessageComponent
          }
        ]
      },
      {
        path: 'login/:idpHint',
        data: {
          resolve: {
            refOffers: {},
            refSoftwares: {
              options: {
                params: {
                  idOffer: 'sessionStorage.idOffer',
                  date: 'sessionStorage.date',
                }
              }
            },
            messages: {
              options: {
                params: {
                  searchCriterias: JSON.stringify(
                    [
                      {
                        logical: 'or',
                        modifier: null,
                        path: 'startDate',
                        operation: 'lessThanOrEqualTo',
                        value: [new Date().toISOString().replace(/[A-Za-z]/g, ' ')]
                      },
                      {
                        logical: 'or',
                        modifier: null,
                        path: 'startDate',
                        operation: 'isNull',
                        value: null
                      },
                      {
                        logical: 'and',
                        modifier: null,
                        path: 'message',
                        operation: 'isNotNull',
                        value: null
                      },
                      {
                        logical: 'or',
                        modifier: null,
                        path: 'endDate',
                        operation: 'greaterThanOrEqualTo',
                        value: [new Date().toISOString().replace(/[A-Za-z]/g, ' ')]
                      },
                      {
                        logical: 'or',
                        modifier: null,
                        path: 'endDate',
                        operation: 'isNull',
                        value: null
                      }
                    ]
                  )
                }
              }
            }
          }
        },
        resolve: {
          resolve: ReferentialResolver
        },
        component: LoginComponent
      },
      {
        path: 'logout',
        component: LogoutComponent
      },
      {
        path: '**',
        redirectTo: '/offers',
        pathMatch: 'full'
      },
      {
        path: '',
        redirectTo: '/offers',
        pathMatch: 'full'
      }
    ]
  }
];

export const RoutingModule: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, {
  useHash: true,
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  onSameUrlNavigation: 'reload'
});
