import { Builder } from "../builder.model";

export class StoreAddress {
    constructor(values: Partial<StoreAddress> = {}) {
        Builder.build.bind(this)(values);
    }

    zipCodeStore: string = null;
    cityNameStore: string = null;
    countryCodeStore: string = null;
    addressStore: string = null;
    localityStore: string = null;
}
