import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, concat } from 'rxjs';
import { KeycloakOptions } from 'keycloak-angular';

export interface Config {
    keycloak: KeycloakOptions;
    backend: any;
    errorMessages: any;
    idpHint: string;
    mobileSoftwareName: string;
    midUniqueProxy: string;
    validators: {[key: string]: {[key: string]: string}};
}

@Injectable()
export class ConfigService {
    private _config: Config;
    private _appConfigUrl = 'config.app.json';
    private _envConfigUrl = 'config.env.json';
    private _app: string;

    constructor(private http: HttpClient) {
    }

    private merge(target, source): Object {
        Object.keys(source).forEach(key => {
            if (Array.isArray(source[key])) {
                target[key] = (target[key] || []).concat(source[key]);
            } else if (source[key] instanceof Object) {
                target[key] = this.merge(target[key] || {}, source[key]);
            } else {
                if(/ur(l|i)$/i.test(key) && !/^https?:\/\//.test(source[key])) target[key] = `${window.location.origin}/${source[key].replace(/^\//,"")}`;
                else target[key] = source[key];
            }
        });

        return target;
    }

    public init(): Promise<Config> {
        return new Promise((resolve, reject) => {
            const time: number = Math.round(new Date().getTime() / 3600000) * 3600000;
            let appConfig: Observable<Object>;
            let envConfig: Observable<Object>;
            const concatResponse: Object = {};

            appConfig = this.http.get(this._appConfigUrl, {
                params: {
                    v: time.toString()
                }
            });
            envConfig = this.http.get(this._envConfigUrl, {
                params: {
                    v: time.toString()
                }
            });
            concat(appConfig, envConfig).subscribe({
                next: response => {
                    this.merge(concatResponse, response);
                },
                error: error => {
                    if (error.url.indexOf('config.env.json') > -1) {
                        console.error('Please create your `config.env.json` file in src/config/ from the example and rebuild or restart your application.');
                        reject(error);
                    } else if (Object.keys(concatResponse).length) {
                        this._config = concatResponse as Config;
                        resolve(this._config);
                    }
                },
                complete: () => {
                    this._config = concatResponse as Config;
                    resolve(this._config);
                }
            });
        });
    }

    get config(): Config { return this._config; }
    get appConfigUrl(): string { return this._appConfigUrl; }
    get envConfigUrl(): string { return this._envConfigUrl; }
    get app(): string { return this._app; }

    set config(newConfig: Config) { this._config = newConfig; }
    set appConfigUrl(newConfigUrl: string) { this._appConfigUrl = newConfigUrl; }
    set envConfigUrl(newConfigUrl: string) { this._envConfigUrl = newConfigUrl; }
    set app(value: string) { this._app = value; }
}
