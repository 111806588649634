<div class="d-flex justify-content-between align-items-center my-4" [ngClass]="{'border-bottom border-dark' : namingPage != null}">
    <h2 class="col-auto color-primary-700">{{namingPage}}</h2>
    <form class="col-6 d-flex justify-content-center">
        <mat-form-field appearance="outline" *ngIf="stringFilter" class="w-100 accent" floatLabel='never'>
            <input matInput [formControl]="filter" [placeholder]="getPlaceholder()" autofocus>
            <mat-icon matSuffix color="accent">search</mat-icon>
        </mat-form-field>
    </form>
    <h2 class="col-auto invisible">{{namingPage}}</h2>
</div>

<div class="d-flex align-items-center my-4" *ngIf="form && config.length">
    <form class="w-100 d-flex justify-content-between align-items-center" >
        <ng-container *ngFor="let conf of config">
            <span class="d-flex justify-content-between align-items-center">
                <span *ngIf="conf.externalLabel" class="search-label pb-2">{{conf.externalLabel}}</span>
                <app-form-field [form]="form" [config]="conf" class="mx-4"></app-form-field>
            </span>
        </ng-container>
    </form>
</div>

<div class="shadow bnpp-title-shadow">
    <div class="d-flex justify-content-between bnpp-title align-items-center color-primary-700">{{namingTable}}</div>
    <div class="table-container">
        <mat-table matSort [dataSource]="dataSource">
            <ng-container *ngFor="let col of displayedColumns | keyvalue: nosort; let index=index" matColumnDef="{{col.key}}">
                <mat-header-cell class="px-0" *matHeaderCellDef mat-sort-header [disabled]="col.value.type=='checkbox'" [ngStyle]="getStyle(col.value)">
                    <ng-container *ngIf="col.value.type=='checkbox' && selection && selection.isMultipleSelection(); else label">
                        <mat-checkbox *ngIf="!col.value.disabled"
                        [color]="col.value.color"
                        (change)="$event ? toggleAll() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                    </ng-container>
                    <ng-template #label>{{col.value.label}}</ng-template>
                </mat-header-cell>
                <mat-cell *matCellDef="let element; let index = index" [ngStyle]="getStyle(col.value,getValue(element,col.key))" [ngClass]="col.value.class">
                    <span *ngIf="col.value.type=='icon'">
                        <mat-icon *ngIf="iconDisplay(element)" (click)="$event.stopPropagation();iconClick.emit(element)" [svgIcon]="svgIcon">{{col.key}}</mat-icon>
                    </span>
                    <mat-checkbox *ngIf="col.value.type=='checkbox'"
                    [color]="col.value.color"
                    [disabled]="col.value.disabled?col.value.disabled(element, selection):false"
                    (click)="$event.stopPropagation()"
                    (change)="$event ? selection.toggle(element) : null"
                    [checked]="isSelected(element)">
                    </mat-checkbox>
                    <a *ngIf="!col.value.type" [innerHTML]="col.value.mapping ? col.value.mapping(getValue(element,col.key)) : getValue(element,col.key)" [class.disabled]="!routerLinkCallback" [routerLink]="routerLinkCallback?routerLinkCallback(element, index):undefined"></a>
                </mat-cell>
                <ng-container *ngIf="footer">
                    <mat-footer-cell class="px-0" *matFooterCellDef mat-sort-footer [ngStyle]="getStyle(col.value)">
                        <ng-container *ngIf="footer==true; else dataFooter">
                            <!-- <input matInput (keyup)="filterEvent.emit([$event.target.value,col.key])" [placeholder]="col.value.label"> -->
                            <textarea matInput [(ngModel)]="objectFilter[col.key]" (keyup)="filterEvent.emit([$event.target.value,col.key])" [placeholder]="col.value.label"></textarea>
                        </ng-container>
                        <ng-template #dataFooter>
                            <span *ngIf="!col.value.type" [innerHTML]="col.value.mapping ? col.value.mapping(getValue(footer,col.key)) : getValue(footer,col.key)"></span>
                        </ng-template>
                    </mat-footer-cell>
                </ng-container>
            </ng-container>
        
            <mat-header-row *matHeaderRowDef="displayedColumns | keys"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns | keys;" (click)="rowClick.emit(row)"></mat-row>
            <ng-container *ngIf="footer">
                <mat-footer-row *matFooterRowDef="displayedColumns | keys"></mat-footer-row>
            </ng-container>
        </mat-table>
    </div>
    
    <div class="d-flex justify-content-between opposite-background-color-foreground-base align-items-center pb-3 px-3">
        <div>
            <div *ngIf="false && getNumberOfPages()>0">
                <span class="page-search color-primary-600 pr-2">Aller à la page (0 - {{getNumberOfPages()}})</span>
                <mat-form-field *ngIf="pageSelection" class="page-search-input">
                    <input matInput type=number min=0 [max]="getNumberOfPages()" (keyup)="pageEvent.emit($event.target.value)" [(ngModel)]="paginator && paginator.pageIndex">
                </mat-form-field>
            </div>
        </div>
        <mat-paginator class="color-primary-600" [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
    </div>
</div>
<div class="overlay d-flex justify-content-center" *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>