import { Builder } from "../builder.model";

export class ContractEMoney {
    constructor(values: Partial<ContractEMoney> = {}) {
        Builder.build.bind(this)(values);
    }

    bankCode: string = null;
    contractReference: string = null;
}
