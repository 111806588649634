<mat-card class="shadow bnpp-title-shadow" *ngIf="config.length">
    <mat-card-header class="bnpp-title">
        <mat-card-title class="color-primary-700">{{title}}</mat-card-title>
    </mat-card-header>
    <mat-progress-bar [ngStyle]="{left: 50/config.length+'%', width: 100*(1-1/config.length)+'%'}" mode="determinate" [value]="value" [color]="colors[2]"></mat-progress-bar>
    <mat-card-content class="d-flex justify-content-between p-3">
        <div *ngFor="let conf of config; let index=index" 
                    class="action-box col flex-column justify-content-between align-items-center text-center" 
                    [ngClass]="getClass(conf)">
            <div [matTooltip]="conf.tooltip" [matTooltipClass]="'bnpp-tooltip ' + conf.color">{{conf.date}}</div>
            <button mat-fab class="mat-elevation-z0" [ngClass]="getButtonClass(conf)" [matTooltip]="conf.tooltip" [matTooltipClass]="'bnpp-tooltip ' + conf.color">{{index+1}}</button>
            <div [matTooltip]="conf.tooltip" [matTooltipClass]="'bnpp-tooltip ' + conf.color">{{conf.label}}</div>
            <mat-icon [matTooltip]="conf.tooltip" [matTooltipClass]="'bnpp-tooltip ' + conf.color" [color]="conf.color" [svgIcon]="conf.svgIcon" >{{conf.icon}}</mat-icon>
            <ng-container *ngIf="canUseAction(conf)">
                <button *ngFor="let action of conf.actions | keyvalue : nosort" mat-stroked-button class="link mb-2" [ngClass]="getButtonClass(conf)" [class.disabled]="conf.status != 1 || conf.loading" [class.background-color-background-status-bar]="conf.status == 2 || conf.loading" [class.border-color-background-status-bar]="conf.status == 2 || conf.loading" (click)="buttonClick(conf, action.value)" style="width: 100%">{{action.key}}</button>
                <ng-container *ngIf="conf.status == 2">
                    <button *ngFor="let action of conf.passedActions | keyvalue : nosort" mat-stroked-button class="link mb-2" [ngClass]="getPassedButtonClass(conf)" [class.disabled]="conf.loading" [class.background-color-background-status-bar]="conf.loading" [class.border-color-background-status-bar]="conf.loading" (click)="buttonClick(conf, action.value)" style="width: 100%">{{action.key}}</button>
                </ng-container>
            </ng-container>
        </div>
    </mat-card-content>
</mat-card>
<div class="overlay d-flex justify-content-center" *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>
