import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { BackendService } from 'src/app/services/backend/backend.service';
import { FormUtilsService } from 'src/app/services/form-utils/form-utils.service';
import { Action } from 'src/app/utils/model/action.model';
import { Customer } from 'src/app/utils/model/customer.model';
import { Equipment } from 'src/app/utils/model/equipment.model';
import { Order } from 'src/app/utils/model/order.model';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  constructor(private formUtilsService: FormUtilsService, private backendService: BackendService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router) { }
  id = this.route.snapshot.params.id;
  actions = this.route.snapshot.data.resolve.actions;
  refCustomer = this.route.snapshot.data.resolve.refCustomer;
  refOffers = this.route.snapshot.data.resolve.refOffers;
  orders = this.refCustomer.customers.reduce((acc, val) => [...val.orders, ...acc], []);
  customer = new Customer({...this.backendService.refToEntity(this.refCustomer,true,true), orders: this.orders});
  form = this.formUtilsService.buildForm(this.customer) as FormGroup;

  get selectedIndex(): number {
    return Number(this.route.snapshot.fragment);
  }
  set selectedIndex(selectedIndex: number) {
    this.router.navigate([], {fragment: selectedIndex.toString(), relativeTo: this.route });
  }

  toggles = {
    "": false,
    "delivery_address.name": true,
    "delivery_address.phone": true,
    "delivery_address.email": true,
    "billing_address.name": true,
    "billing_address.phone": true,
    "billing_address.email": true,
    "fiscal_address.name": true,
    "fiscal_address.phone": true,
    "fiscal_address.email": true,
    "civility": true,
    "lastname": true,
    "firstname": true
  };

  ngOnInit(): void {
    this.form.get('orders').disable();
  }

  postCustomerUpdate() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const customer = this.form.getRawValue();
    const diff = this.backendService.objectDiff(this.customer, customer, false);
    if(diff) {
      if(diff.bank_account) {
        diff.old_bank_account = {};
        Object.keys(diff.bank_account).forEach(k => {
          diff.old_bank_account[k] = this.customer.bank_account[k];
        });
      }
      if(diff.delivery_address) {
        ["name","phone","email"].forEach(k => {
          if(diff.delivery_address[k]) diff["contact_"+k] = diff.delivery_address[k];
        });
      }
      ["id_customer_maintainer", "store_registration_number"].forEach(_ => diff[_] = customer[_])
      const date = new Date();
      const observables = (this.orders as Order[])
      .filter(order => order.action_name && order.action_name!="sign")
      .map(order => {
        const suborder = order.suborders[0];
        return new Action({
          name: "customer_update",
          date: date,
          sent: date,
          id_offer: suborder.id_offer,
          id_order: suborder.id_order,
          id_maintainer: suborder.id_maintainer,
          maintainer_information: diff
        })
      }).reduce((acc,val) => {
        const index = acc.findIndex(action => ["id_offer","id_maintainer"].every(_ => val[_]==action[_]));
        if(index == -1) acc.push(val);
        else acc[index] = val;
        return acc;
      },[])
      .map(action => this.backendService.request('post', 'publish/action', {body: action}));
      if(observables.length) {
        combineLatest(observables).subscribe(_ => {
          if (_) {
            // this.postCustomer(this.backendService.entityToRef(customer));
          }
        });
      } else {
        this.postCustomer(this.backendService.entityToRef(customer));
      }
    }
  }

  postCustomer(customer: Customer) {
    this.backendService.request('post', 'refCustomers', {body: customer}).subscribe(value => {
      if (value) {
        window.location.reload();
      }
    });
  }

  getOrdersNavigationState() {
    // const url = "/orders";
    // const json = JSON.parse(sessionStorage.getItem(url)) || {};
    // const key = Object.keys(json).find(k => json[k].start && json[k].end) || "created_date";
    // json[key] = {
    //   start: this.orders[this.orders.length-1][key],
    //   end: this.orders[0][key]
    // }
    // json.filter = this.form.get('store_registration_number').value;
    // sessionStorage.setItem(url,JSON.stringify(json));
    return {'customer.id_ref': this.id};
  }

  navigate(order: Order = null, equipment: Equipment = null) {
    let url = ["offers"];
    const id = order?.suborders[0]?.id_offer?.toString();
    if(id) {
      url.push(id);
    } else if(sessionStorage.getItem("idOffer")) {
      url.push(sessionStorage.getItem("idOffer"));
    }
    let state: Partial<Order> = new Order({
      customer: this.customer
    });
    state.suborders[0].contract_emoney = this.orders.slice(-1)[0]?.suborders[0]?.contract_emoney;
    if(equipment) {
      state.suborders[0].equipments = [new Equipment({...equipment, equipment_change_request: false})];
      state.suborders[0].additional_order = true;
    }
    const date = (state?.suborders?.[0]?.equipments?.[0]?.created_date ?? new Date())?.toISOString().split('T')[0];
    if(date) sessionStorage.setItem("date", date);
    else sessionStorage.removeItem("date");
    this.router.navigate(url, {state});
  }

  allowNewOrder() {
      return !sessionStorage.getItem('amgca');
  }

  displayNepting() {
    const id_offer = sessionStorage.getItem('idOffer');
    const refOffer = this.refOffers.find(refOffer => refOffer.id == id_offer);
    return (!refOffer || refOffer.nepting_user) && this.orders.some(order => order.suborders.some(suborder => (!id_offer || suborder.id_offer == id_offer) && suborder.equipments.some(equipment => equipment.nepting && equipment.softwares.some(software => software.nepting_application_type))));
  }

}
