<mat-expansion-panel class="my-3 shadow bnpp-title-shadow" [expanded]="true">
  <mat-expansion-panel-header class="bnpp-title">
    <mat-panel-title class="color-primary-700">Détail de la commande</mat-panel-title>
  </mat-expansion-panel-header>
  <ng-container [formGroup]="form">
    <div *ngFor="let equipment of form.controls; let e=index" [formGroupName]="e">
      <div class="d-flex justify-content-between align-items-center equipment-head w-100 mb-1">
        <span class="color-warn-100"><h4>Matériel {{e+1}} {{!form.parent.get('additional_order').value && equipment.get('equipment_change_request').value?'[Changement de gamme]':''}}</h4></span>
        <span class="color-warn-100 d-flex justify-content-between align-items-center">
          <span><h4>Quantité: <span *ngIf="equipment.get('quantity') && !formDisplay">{{equipment.get('quantity').value}}</span></h4></span>
          <app-number-form *ngIf="equipment.get('quantity') && formDisplay" label="Quantité" [min]="1"
                          formControlName="quantity"></app-number-form>
        </span>
      </div>
      <div class="d-flex flex-column justify-content-between align-items-center mx-4">
        <div class="header-line d-flex w-100 align-items-center">
          <div></div>
          <div>Désignation</div>
          <div>Contrat / Nature</div>
          <div></div>
          <div class="d-flex justify-content-end">Total (€ HT)</div>
          <div class="d-flex justify-content-end"></div>
        </div>
        <div class="equipment-line d-flex w-100 align-items-center my-2"
        [attr.data-id_equipment]="equipment.get('id').value"
        >
          <div>
            <img [src]="backendService.backendUrl+'img/equipment/'+equipment.get('id_ref').value+'.png'"
                onerror="this.src='../../../assets/icons/TPE.png'"
                height="30">
          </div>
          <div class="equipment-strong">
            {{getName(equipment.getRawValue())}} - {{equipment.get('communication_type').value}}
          </div>
          <div>{{getContractLabel(equipment.getRawValue())}} / {{getInstallationLabel(equipment.getRawValue())}}</div>
          <div></div>
          <div class="equipment-strong color-warn-100 d-flex justify-content-end "
            *ngIf="equipment.get('rental_equipment_total').value">{{equipment.get('rental_equipment_total').value/(equipment.get('id_order').value?1:1) | currency}}/mois
          </div>
          <div class="equipment-strong color-warn-100 d-flex justify-content-end "
            *ngIf="equipment.get('purchase_equipment_total').value">{{equipment.get('purchase_equipment_total').value | currency}}
          </div>
          <div class="d-flex justify-content-end"></div>
        </div>
        <ng-container formGroupName="softwares">
          <ng-container *ngFor="let software of equipment.controls.softwares['controls']; let s=index">
            <div *ngIf="software.get('id_order').value == form.parent.get('id_order').value" [formGroupName]="s"
                class="software-line w-100 d-flex align-items-center mb-1"
                [attr.data-id_equipment]="equipment.get('id').value"
                [attr.data-id_software]="software.get('id').value"
                >
                <div></div>
              <ng-container *ngFor="let conf of softwareConfig">
                <div>
                  <app-form-field [ngStyle]="{'width': 'calc((95% - 24px)/'+softwareConfig.length+')'}"
                                  [form]="form.get([e,'softwares',s])" [config]="conf"></app-form-field>
                </div>
              </ng-container>
              <div>
                <mat-icon *ngIf="canRemoveSoftware(software)" class="clickable"
                          (click)="removeSoftware(e,s)">delete
                </mat-icon>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container formGroupName="accessories">
          <ng-container *ngFor="let accessory of equipment.controls.accessories['controls']; let a=index">
            <div *ngIf="accessory.get('id_order').value == form.parent.get('id_order').value" [formGroupName]="a"
                class="accessory-line w-100 d-flex align-items-center mb-1"
                [attr.data-id_equipment]="equipment.get('id').value"
                [attr.data-id_accessory]="accessory.get('id').value"
                >
              <ng-container *ngIf="accessory && accessory.get('quantity').value">
                <div>
                <img [src]="backendService.backendUrl+'img/accessory/'+accessory.get('id_ref').value+'.png'"
                    onerror="this.src='../../../assets/icons/Accessories.png'" height="25">
                </div>
                <ng-container *ngFor="let conf of accessoryConfig">
                  <div>
                    <app-form-field [ngStyle]="{'width': 'calc((95% - 50px)/'+accessoryConfig.length+')'}"
                                    [form]="form.get([e,'accessories',a])" [config]="conf"></app-form-field>
                  </div>
                </ng-container>
                <div class="d-flex justify-content-end"></div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</mat-expansion-panel>
