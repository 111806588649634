import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label, PluginServiceGlobalRegistrationAndOptions, SingleOrMultiDataSet } from 'ng2-charts';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Color } from 'ng2-charts/lib/color';
import { BackendService } from 'src/app/services/backend/backend.service';
import { Fare } from 'src/app/utils/model/fare.model';
import { Action } from 'src/app/utils/model/action.model';
import { combineLatest, Subscription } from 'rxjs';
import * as Chart from 'chart.js';

interface ChartConfiguration {
  kpis?: String[];
  mapping?: (object: Object) => Object;
  filter?: (object: Object) => Object;
  sort?: (a,b) => number;
  init?: string[];

  data?: SingleOrMultiDataSet;
  datasets?: ChartDataSets[];
  labels?: Label[];
  options?: ChartOptions;
  chartType?: ChartType;
  colors?: Color[];
  legend?: boolean;
  plugins?: PluginServiceGlobalRegistrationAndOptions[];
  chartClick?: (click: {event?: MouseEvent, active?: {}[]}) => void;
  chartHover?: (hover: {event?: MouseEvent, active?: {}[]}) => void;
}

@Component({
  selector: 'app-orders-charts',
  templateUrl: './orders-charts.component.html',
  styleUrls: ['./orders-charts.component.scss']
})
export class OrdersChartsComponent implements OnInit, OnDestroy {

  constructor(private backendService: BackendService) { }
  
  ngOnInit(): void {
    Chart.pluginService.register({
      beforeDraw: function(chart) {
        if (chart.config.options.elements['center']) {
          // Get ctx from string
          var ctx = chart.ctx;
    
          // Get options from the center object in options
          var centerConfig = chart.config.options.elements['center'];
          var fontStyle = centerConfig.font.style || 'normal';
          var fontVariant = centerConfig.font.variant || 'normal';
          var fontWeight = centerConfig.font.weight || 'normal';
          var fontSize = centerConfig.font.size;
          var fontFamily = centerConfig.font.family || 'Arial';
          var txt = centerConfig.text(chart);
          var color = centerConfig.color || '#000';
          var maxFontSize = centerConfig.maxFontSize || 45;
          var sidePadding = centerConfig.sidePadding || 20;
          var sidePaddingCalculated = (sidePadding / 100) * (chart['innerRadius'] * 2)
    
          // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
          var stringWidth = ctx.measureText(txt).width;
          var elementWidth = (chart['innerRadius'] * 2) - sidePaddingCalculated;
    
          // Find out how much the font can grow in width.
          var widthRatio = elementWidth / stringWidth;
          var newFontSize = Math.floor(30 * widthRatio);
          var elementHeight = (chart['innerRadius'] * 2);
    
          // Pick a new font size so it will not be larger than the height of label.
          var fontSizeToUse = Math.min(elementHeight, maxFontSize);
          var minFontSize = centerConfig.minFontSize;
          var lineHeight = centerConfig.lineHeight || 25;
          var wrapText = false;
    
          if (minFontSize === undefined) {
            minFontSize = 20;
          }
    
          if (minFontSize && fontSizeToUse < minFontSize) {
            fontSizeToUse = minFontSize;
            wrapText = true;
          }
    
          // Set font settings to draw it correctly.
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
          ctx.font = `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize || (fontSizeToUse+"px")} ${fontFamily}`
          ctx.fillStyle = color;

          ctx.clearRect(0, 0, chart.width, chart.height);
    
          if (!wrapText) {
            ctx.fillText(txt, centerX, centerY);
            return;
          }
    
          var words = txt.split(' ');
          var line = '';
          var lines = [];
    
          // Break words up into multiple lines if necessary
          for (var n = 0; n < words.length; n++) {
            var testLine = line + words[n] + ' ';
            var metrics = ctx.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > elementWidth && n > 0) {
              lines.push(line);
              line = words[n] + ' ';
            } else {
              line = testLine;
            }
          }
    
          // Move the center up depending on line height and number of lines
          centerY -= (lines.length / 2) * lineHeight;
    
          for (var n = 0; n < lines.length; n++) {
            ctx.fillText(lines[n], centerX, centerY);
            centerY += lineHeight;
          }
          //Draw text in center
          ctx.fillText(line, centerX, centerY);
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  @Input()
  get previous_params(): Object {
      return this._params;
  }
  set previous_params(previous_params: Object) {
      this._previous_params = previous_params;
      this.updateData();
  }
  _previous_params = {};

  @Input()
  get params(): Object {
      return this._params;
  }
  set params(params: Object) {
      this._params = params;
      this.updateData();
  }
  _params = {};

  subscription: Subscription;

  @Input()
  config: {[key:string]: ChartConfiguration} = {
    "État des commandes": {
      kpis: ["suborders.actionName"],
      mapping: kpi => Action.getGlobalActionLabel(Object.entries(kpi).reduce((acc,[k,v]) => ({...acc,[k.replace("suborders.", "")]: v}),{})),
      init: Object.keys(Action.getGlobalActionLabel()),

      data: [],
      datasets: null,
      labels: [],
      options: {
        elements: {
          center: {
            text: chart => chart.data.datasets.reduce((datasets, dataset, datasetIndex) => datasets+dataset.data.reduce((data, d, dataIndex) => data + d*Number(!chart.controller.getDatasetMeta(datasetIndex).data[dataIndex].hidden),0),0),
            color: '#838282',
            font: {
              weight: 'bold',
              family: 'BNPPSans'
            }
          }
        } as any,
        aspectRatio: 1,
        responsive: true,
        legend: {
          position: 'bottom',
          labels: {
            fontFamily: 'OpenSans'
          }
        },
        animation: {
          duration: 1000,
          easing: 'linear'
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 10,
            bottom: 10
          }
        },
        plugins: {
          datalabels: {
            color: 'rgba(0, 96, 114, 1)',
            font: {
              weight: 'bold',
              size: 14,
              family: 'BNPPSans',
            }
          },
        },
        cutoutPercentage: 80,
      },
      chartType: 'doughnut',
      colors: [
        {
          backgroundColor: Action.getActionsColors(),
        },
      ],
      legend: true,
      plugins: [ChartDataLabels],
      chartClick: (click: {event?: MouseEvent, active?: {}[]}) => {},
      chartHover: (hover: {event?: MouseEvent, active?: {}[]}) => {}
    },
    "Évolution des commandes": {
      kpis: ["suborders.actionName"],
      mapping: kpi => Action.getGlobalActionLabel(Object.entries(kpi).reduce((acc,[k,v]) => ({...acc,[k.replace("suborders.", "")]: v}),{})),
      filter: _ => _ == Action.getGlobalActionLabel({action_name: "order_end"}),

      datasets: [],
      colors: [{ backgroundColor:'#4fa6f1', pointStyle: 'url(#Dégradé_sans_nom_19)'}, { backgroundColor:'#838282', pointStyle: 'url(#Dégradé_sans_nom_27)'}],
      labels: ['graphics:total_commandes', 'graphics:evolution_commandes'],
    },
    "Nature des commandes": {
      kpis: ["suborders.contractType", "suborders.contractDuration", "suborders.additionalOrder"],
      mapping: kpi => Fare.getContractLabel(Object.entries(kpi).reduce((acc,[k,v]) => ({...acc,[k.replace("suborders.", "")]: v}),{})),
      
      data: null,
      datasets: [],
      labels: ['Nombre de commandes'],
      options: {
        aspectRatio: 1,
        responsive: true,
        legend: {
          position: 'bottom',
          labels: {
            fontFamily: 'OpenSans'
          }
        },
        layout: {
          padding: {
              left: 0,
              right: 0,
              top: 30,
              bottom: 10
          }
       },
       animation: {
         duration:1000,
         easing: 'linear'
       },
        scales: { 
          xAxes: [{
            gridLines: {
              display: false,
              lineWidth: 0,
              drawBorder: false
            },
            ticks: {
              display: false
            }
          }], 
          yAxes: [{
            gridLines: {
              display: false,
              lineWidth: 0,
              drawBorder: false,
            },
            ticks: {
              display: false,
              beginAtZero: true
            },
            
          }], 
        },
        plugins: {
          datalabels: {
            color: 'rgba(0, 96, 114, 1)',
            anchor: 'end',
            align: 'top',
            font: {
              weight: 'bold',
              size: 14,
              family: 'BNPPSans',
            }
          },
        }
      },
      chartType: 'bar',
      colors: [
        { backgroundColor: 'rgba(80, 80, 80, 1)', borderWidth: 0, hoverBackgroundColor: 'rgba(80, 80, 80, .7)' },
        { backgroundColor: 'rgba(0, 96, 114, 1)', borderWidth: 0 , hoverBackgroundColor: 'rgba(0, 96, 114, .7)' },
        { backgroundColor: 'rgba(0, 168, 130, 1)', borderWidth: 0 , hoverBackgroundColor: 'rgba(0, 168, 130, .7)' }
      ],
      legend: true,
      plugins: [ChartDataLabels],
      chartClick: (click: {event?: MouseEvent, active?: {}[]}) => {},
      chartHover: (hover: {event?: MouseEvent, active?: {}[]}) => {}
    }
  }

  updateData() {
    if(Object.values(this._params).length) {
      const url = `orders/kpis/${Object.values(this.config).reduce((acc: string[],val)=>[...acc,...val.kpis],[]).filter((v,i,a) => a.indexOf(v)===i).join(",")}`;
      this.subscription?.unsubscribe();
      this.subscription = combineLatest(
          [this._previous_params, this._params]
          .map(_ => JSON.stringify(_))
          .filter((v,i,a) => a.indexOf(v)===i)
          .map(_ => JSON.parse(_))
          .map(params => this.backendService.request('get',url,{params}))
        ).subscribe(([previous, current]) => {
        if(!current) current = previous;
        Object.entries(this.config).forEach(([key,value]) => {
          if(!value.mapping) {
            value.mapping = object => Object.values(object).join(" ");
          }
          if(!value.filter) {
            value.filter = _ => true;
          }
          if(!value.sort) {
            value.sort = (a,b) => 0;
          }
          let result = value.init ? value.init.map(_ => ({previous: 0, current: 0, label: _})) : [];
          result = previous.reduce((acc, val) => {
            const {data,...object} = val;
            const label = value.mapping(object);
            if(value.filter(label)) {
              let index = acc.findIndex(v => label == v.label);
              if(index == -1) acc.push({previous: data, current: 0, label});
              else acc[index].previous+=data;
            }
            return acc;
          },result);
          result = current.reduce((acc, val) => {
            const {data,...object} = val;
            const label = value.mapping(object);
            if(value.filter(label)) {
              let index = acc.findIndex(v => label == v.label);
              if(index == -1) acc.push({previous: 0, current: data, label});
              else acc[index].current+=data;
            }
            return acc;
          },result);

          result = result.sort(value.sort);
          
          if(!value.chartType) {
            if(!result.length) result = [{previous: 0, current: 0, label: Action.getGlobalActionLabel({action_name: "order_end"}).toString()}]
            this.config[key].datasets = result.reduce((acc, val) => 
              [...acc,
              ...[
                  {
                    data: [val.current], label: val.label
                  },
                  {
                    data: [(!Number.isFinite((val.current-val.previous)/val.previous)?null:(100*(val.current-val.previous)/val.previous).toFixed(2))], label: "% sur la période sélectionnée vs période précédente"
                  }
                ]
              ]
            ,[]);
          } else {
            if(value.datasets) {
              this.config[key].datasets = result.map(_ => ({ data:[_.current], label: _.label}));
            }
            if(value.data) {
              this.config[key].data = result.map(_ => _.current);
              this.config[key].labels = result.map(_ => _.label);
            }
          }
        });
      });
    }
  }

  nosort() {}

}
