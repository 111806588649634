import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AmgcaService } from 'src/app/services/amgca/amgca.service';
import { BackendService } from 'src/app/services/backend/backend.service';
import { DialogComponent } from 'src/app/utils/dialog/dialog.component';
import { Order } from 'src/app/utils/model/order.model';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent {

  constructor(private backendService: BackendService, private dialog: MatDialog, private amgcaService: AmgcaService) {
  }

  namingPage = "SUIVI DE COMMANDES";
  namingTable = `Commandes effectuées${this.getAmgca()?` à l’agence ${this.getAmgcaBranch()}`:''}`;

  getState() {
    const idOffer = sessionStorage.getItem('idOffer');
    return idOffer ? {'suborders.id_offer': idOffer} : {};
  }

  getAmgca() {
    return sessionStorage.getItem('amgca');
  }

  getAmgcaBranch() {
    return this.amgcaService.amgcaToOrder(JSON.parse(this.getAmgca())).suborders[0].branch_code;
  }

  backendRoute = 'orders';
  icon  = 'delete';
  displayedColumns = Order.getDisplayedColumns(this.icon);
  filteredColumns = Order.getFilteredColumns();
  iconDisplay = Order.canDelete;

  previous_params = {};
  params = {};

  routerLinkCallback(row) {
    return [row.id_order || row.id || row.id_ref];
  }

  iconClick(row) {
    this.dialog.open(DialogComponent, {
      data: {
        title: "Souhaitez-vous réellement<br>supprimer cette commande?",
        buttons: {
          'Oui': true,
          'Non': false
        }
      }
    }).afterClosed().subscribe(_ => {
      if(_) this.backendService.request('delete', `orders/${row.id_order || row.id || row.id_ref}`).subscribe(_ => window.location.reload());
    });
  }

}
