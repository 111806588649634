import { Builder } from "../builder.model";
import { BranchAddress } from "./branch-address.model";
import { ContractEMoney } from "./contract-e-money.model";
import { CustomerInformation } from "./customer-information.model";
import { Equipments } from "./equipments.model";
import { User } from "./user.model";

export class Amgca {
    constructor(values: Partial<Amgca> = {}) {
        Builder.build.bind(this)(values);
    }

    contractEmoney: ContractEMoney = new ContractEMoney();
    equipments: Equipments = new Equipments();
    customerInformation: CustomerInformation = new CustomerInformation();
    idBranchAdvisor: string = null;
    branchCode: string = null;
    bnppTerritoryCode: string = null;
    bnppTerritoryName: string = null;
    bnppAreaCode: string = null;
    bnppAreaName: string = null;
    branchAddress: BranchAddress = new BranchAddress();
    user: User = new User();
}
