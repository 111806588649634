import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {CustomerStore} from '../../utils/model/customer.model';

@Injectable()
export class ReferentialService {

    constructor(private http: HttpClient) {
    }
    private entrepriseUrl = 'https://recherche-entreprises.api.gouv.fr/';
    private adresseUrl = 'https://api-adresse.data.gouv.fr/';
    private calendrierUrl = 'https://calendrier.api.gouv.fr/jours-feries/';
    private laposteUrl = 'https://datanova.laposte.fr/data-fair/api/v1/datasets/laposte-hexasmal/lines/';

    private genericRequest<T>(method: string= 'get', url?: string, options?: Object): Observable<T> {
        return this.http.request<T>(method, url, options)
        .pipe(
            catchError((err: HttpErrorResponse) => {console.error(err);return EMPTY}),
        );
    }

    private entreprise(route: string, options?: Object) {
        return this.genericRequest<Object>('get', this.entrepriseUrl + route, options);
    }

    private addresse(route: string, options?: Object) {
        return this.genericRequest<Object[]>('get', this.adresseUrl + route, options);
    }

    private laposte(route: string, options?: Object) {
        return this.genericRequest<Object[]>('get', this.laposteUrl + route, options);
    }

    private calendrier(route: string, options?: Object) {
        return this.genericRequest<Object[]>('get', this.calendrierUrl + route, options);
    }

    entrepriseSearch(siret: string): Observable<CustomerStore> {
        return this.entreprise('search', {params: { q: siret}}).pipe(
            map(result => {
                result = result['results'][0];

                const address_full = result['siege']?.['adresse'];
                const store_address_match = address_full?.match(/(.*) ([0-9]{5}) (.*)/);

                const store_address = {
                    address: store_address_match?.[1],
                    zip_code: store_address_match?.[2],
                    city_name: store_address_match?.[3]
                };

                const naf_or_ape = result['activite_principale'].replace('.','');

                const president = result['dirigeants']?.[result['dirigeants']?.length-1];

                return {
                    company_name: result['nom_raison_sociale'],
                    store_name: result['nom_raison_sociale'],
                    store_registration_number: result['siege']['siret'],
                    company_registration_number: result['siren'],
                    french_ape_code: this.getFrenchApeCodes(naf_or_ape)[0] || naf_or_ape,
                    french_naf_code: this.getFrenchNafCodes(naf_or_ape)[0] || naf_or_ape,
                    store_address: {
                        ...store_address,
                        name: president ? (president['nom'] + ' ' + president['prenoms']).replace(/([A-zÀ-ÿ])([A-zÀ-ÿ]+)/g, (match, $1, $2) => $1.toUpperCase()+$2) : null,
                        address_full
                    }
                };
            })
        );
    }

    addresseSearch(address: string) {
        return this.addresse('search', { params: { q: address, type: 'housenumber', limit: '50' } }).pipe(
            map(result => result['features']
                .map(_ => _.properties)
                .map(_ => ReferentialService.filterObject({
                    label: _['label'],
                    address: _['name'],
                    zip_code: _['postcode'],
                    city_name: this.normalizeCityName(_['city'])
                }))
            )
        );
    }

    reverseAddresseSearch(lat: number, lon: number) {
        return this.addresse('reverse', { params: { lat, lon } }).pipe(
            map(result => result['features']
                .map(_ => _.properties)
                .map(_ => ReferentialService.filterObject({
                    label: _['label'],
                    address: _['name'],
                    zip_code: _['postcode'],
                    city_name: this.normalizeCityName(_['city'])
                }))
            )
        );
    }

    normalizeCityName(city_name: string) {
        return (city_name || '').toUpperCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/((?!\w).)/g, ' ').toUpperCase().replace(/SAINT(E?)([^A-Z])/, 'ST$1$2');
    }

    laposteSearch(code: string, api = true) {
        let observable;
        if(api) {
            observable = this.laposte('', {
                params: { q: code, q_mode: 'complete', size: '50' }
            }).pipe(map(result => result['results']));
        } else {
            const laposte_hexasmal = require('./laposte_hexasmal.json');
            observable = of(laposte_hexasmal.map(_ => _.fields).filter(field => Object.values(field).some(f => f==code)));
        }
        return observable.pipe(
            map((fields: Object[]) => fields
                .map(_ => ReferentialService.filterObject({
                    locality: _['ligne_5'],
                    zip_code: _['code_postal'],
                    city_name: _['nom_de_la_commune'],
                    city_name_valid: _['libelle_d_acheminement']
                }))
            )
        );
    }

    calendrierSearch(zone= 'metropole', annee= null) {
        return this.calendrier(zone + (annee ? '/' + annee : '') + '.json', {}).pipe(
            map(result => {
                return Object.keys(result).reduce((acc, val) => {
                    acc[this.formatDate(new Date(val))] = result[val];
                    return acc;
                }, {});
            })
        );
    }

    private formatDate(date: Date) {
        const pad = v => `0${v}`.slice(-2);

        const day = pad(date.getDate());
        const month = pad(date.getMonth() + 1);
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    getFrenchNafCodes(ape) {
        const naf_to_ape = require('./naf_to_ape.json');
        return naf_to_ape.filter(_ => _.ape === ape).map(_ => _.naf);
    }

    getFrenchNafCode(ape) {
        const naf_to_ape = require('./naf_to_ape.json');
        return of({ french_naf_code: this.getFrenchNafCodes(ape)[0] });
    }

    getFrenchApeCodes(naf) {
        const naf_to_ape = require('./naf_to_ape.json');
        return naf_to_ape.filter(_ => _.naf === naf).map(_ => _.ape);
    }

    getFrenchApeCode(naf) {
        const naf_to_ape = require('./naf_to_ape.json');
        return of({ french_ape_code: this.getFrenchApeCodes(naf)[0] });
    }

    getMcc(ape) {
        const ape_to_mcc = require('./ape_to_mcc.json');
        return of({ mcc: ape_to_mcc[ape] });
    }

    getFrenchNafLabel(naf) {
        const nafs = require('./naf.json');
        return of({ french_naf_label: nafs[naf] });
    }

    getFrenchApeLabel(ape) {
        const apes = require('./ape.json');
        return of({ french_ape_label: apes[ape] });
    }

    getMccLabel(mcc) {
        const mccs = require('./mcc.json');
        return of({ mcc_label: Object.keys(mccs).find(label => mccs[label] === mcc) });
    }

    getBIC(iban) {
        try {
            const country = iban.substring(0, 2).toUpperCase();
            const bankCode = Number(iban.substring(4, 9));
            const banks = require('./iban/' + country + '.json');

            const items = banks.list.filter(_ => _.id == bankCode).sort((a, b) => a.swift_code > b.swift_code ? 1 : -1);
            const item = items.length ? items[0] : null;

            return of({ bank_account: { bic: item?.swift_code.replace(/XXX$/, ''), bank: item?.bank } });
        } catch(e) {
            return of({ bank_account: { bic: null, bank: null } });
        }
    }

    getBank(bic) {
        try {
            const country = bic.substring(4, 6);
            const banks = require('./iban/' + country + '.json');

            const items = banks.list.filter(_ => bic.startsWith(_.swift_code.replace(/XXX$/, ''))).sort((a, b) => a.swift_code > b.swift_code ? 1 : -1);
            const item = items.length ? items[0] : null;

            return of({ bank_account: { bank: item?.bank } });
        } catch(e) {
            return of({ bank_account: { bank: null } });
        }
    }

    static filterObject(object) {
        return JSON.parse(JSON.stringify(object,(k,v) => v??undefined));
    }
}
