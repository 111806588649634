import { PortalModule } from '@angular/cdk/portal';
import { TextFieldModule } from '@angular/cdk/text-field';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import {APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { ChartsModule } from 'ng2-charts';
import { AppComponent } from './app.component';
import { RoutingModule } from './app.routing';
import { CustomerComponent } from './components/customers/customer/customer.component';
import { CustomersComponent } from './components/customers/customers.component';
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';
import { OfferComponent } from './components/offers/offer/offer.component';
import { OffersComponent } from './components/offers/offers.component';
import { OrderComponent } from './components/orders/order/order.component';
import { OrdersChartsComponent } from './components/orders/orders-charts/orders-charts.component';
import { OrdersComponent } from './components/orders/orders.component';
import { AuthGuard } from './guards/auth/auth.guard';
import { KeysPipe } from './pipes/keys/keys.pipe';
import { ValuesPipe } from './pipes/values/values.pipe';
import { ReferentialResolver } from './resolvers/referential/referential.resolver';
import { AmgcaService } from './services/amgca/amgca.service';
import { BackendService } from './services/backend/backend.service';
import { ConfigService } from './services/config/config.service';
import { FormUtilsService } from './services/form-utils/form-utils.service';
import { MatPaginatorIntlService } from './services/mat-paginator-intl/mat-paginator-intl.service';
import { ReferentialService } from './services/referential/referential.service';
import { CustomDateAdapter } from './utils/adapter/date.adapter';
import { DialogComponent } from './utils/dialog/dialog.component';
import { ActionsDisplayComponent } from './utils/display/actions-display/actions-display.component';
import { ConfirmDisplayComponent } from './utils/display/confirm-display/confirm-display.component';
import { ContractsDisplayComponent } from './utils/display/contracts-display/contracts-display.component';
import { CustomerDisplayComponent } from './utils/display/customer-display/customer-display.component';
import { CustomerEquipmentsDisplayComponent } from './utils/display/customer-equipments-display/customer-equipments-display.component';
import { EquipmentDisplayComponent } from './utils/display/equipment-display/equipment-display.component';
import { EquipmentsDisplayComponent } from './utils/display/equipments-display/equipments-display.component';
import { OrderDisplayComponent } from './utils/display/order-display/order-display.component';
import { AccessoriesFormComponent } from './utils/form/accessories-form/accessories-form.component';
import { CustomerFormComponent } from './utils/form/customer-form/customer-form.component';
import { EquipmentsFormComponent } from './utils/form/equipments-form/equipments-form.component';
import { FormFieldComponent } from './utils/form/form-field/form-field.component';
import { IbanFormComponent } from './utils/form/iban-form/iban-form.component';
import { NumberFormComponent } from './utils/form/number-form/number-form.component';
import { RadioFormComponent } from './utils/form/radio-form/radio-form.component';
import { SoftwaresFormComponent } from './utils/form/softwares-form/softwares-form.component';
import { TableComponent } from './utils/table/table.component';
import { SnackBarComponent } from './utils/snack-bar/snack-bar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MessagesComponent } from './components/messages/messages.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MessageComponent } from './components/messages/message/message.component';
import { MessagesService } from './services/messages/messages.service';
import { NeptingDisplayComponent } from './utils/display/nepting-display/nepting-display.component';
import { CerteuropeDisplayComponent } from './utils/display/certeurope-display/certeurope-display.component';
import {GlobalErrorHandlerService} from './services/error/GlobalErrorHandler.service';

registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppComponent,
    TableComponent,
    LoginComponent,
    LogoutComponent,
    OrdersComponent,
    OrderComponent,
    CustomersComponent,
    CustomerComponent,
    OffersComponent,
    CustomerFormComponent,
    IbanFormComponent,
    CustomerDisplayComponent,
    EquipmentsFormComponent,
    SoftwaresFormComponent,
    OfferComponent,
    AccessoriesFormComponent,
    NumberFormComponent,
    EquipmentsDisplayComponent,
    OrderDisplayComponent,
    KeysPipe,
    ValuesPipe,
    ActionsDisplayComponent,
    DialogComponent,
    ConfirmDisplayComponent,
    FormFieldComponent,
    EquipmentDisplayComponent,
    CustomerEquipmentsDisplayComponent,
    ContractsDisplayComponent,
    OrdersChartsComponent,
    RadioFormComponent,
    SnackBarComponent,
    MessagesComponent,
    MessageComponent,
    NeptingDisplayComponent,
    CerteuropeDisplayComponent
  ],

  imports: [
    AngularEditorModule,
    BrowserModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    KeycloakAngularModule,
    BrowserAnimationsModule,
    NgbModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    TextFieldModule,
    PortalModule,
    ChartsModule
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigService, keycloak: KeycloakService) => async () => {
        await config.init();
        await keycloak.init(config.config.keycloak);
      },
      multi: true,
      deps: [ConfigService, KeycloakService],
    },
    {
      provide: NgbPopoverConfig,
      useValue: {
        autoClose: 'outside',
        // container: 'body',
        triggers: 'click mouseenter'
      }
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter
    },
    {
      provide: LOCALE_ID,
      useValue: 'fr-FR'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR'
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlService,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService
    },
    Title,
    AuthGuard,
    ReferentialResolver,
    BackendService,
    FormUtilsService,
    ReferentialService,
    AmgcaService,
    MessagesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
