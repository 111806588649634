<div class="mx-5 px-5">
    <angular-editor *ngFor="let message of messages" [ngModel]="message.message" [config]="{editable: false, showToolbar: false}"></angular-editor>
</div>
<mat-grid-list cols="12" rowHeight="1:4" [gutterSize]="30" class="mx-5">
    <mat-grid-tile colspan="4" *ngFor="let offer of offers">
        <mat-card class="shadow bnpp-title-shadow p-0" (click)="navigate(offer)">
            <mat-card-header class="d-flex justify-content-between bnpp-title">
                <mat-card-title class="color-primary-700">{{offer.name}}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="d-flex flex-column align-items-center justify-content-center p-1">
                <img [src]="backendService.backendUrl+'img/offer/'+offer.id+'_'+router.routerState.snapshot.url.split('/')[1]+'.png'" (error)="onImgError($event, offer)" height="120">
            </mat-card-content>
            <mat-card-actions class="d-flex flex-column align-items-center pt-0">
            </mat-card-actions>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>