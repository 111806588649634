import { Base } from "../base.model";
import { Builder } from "../builder.model";
import { Customer } from "../customer.model";
import { User } from "./user.model";
import { Application } from "./application.model";
import { Store } from "./store.model";
import { Terminal } from "./terminal.model";

export class Merchant extends Base {
    constructor(values: Partial<Merchant> = {}) {
        super(values);
        Builder.build.bind(this)(values,{
            application: _ => new Application(_),
            store: _ => new Store(_),
            terminal: _ => new Terminal(_),
            user: _ => new User(_)
        });
    }

    merchant_id: string = null;
    application: Application[] = [];
    store: Store[] = [];
    terminal: Terminal[] = [];
    user: User[] = [];
    id_merchant: string = null;
    merchant_contract_id: string = null;
    bank_code: string = null;
    nlsa: string = null;
    bank_host_address: string = null;
    bank_host_port: string = null;
    terminal_count: number = null;
    description: string = null;
    detail: string = null;
    status: boolean = null;
    is_merchant_active: boolean = null;
	ref_customer: Customer = new Customer();
}
