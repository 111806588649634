import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AmgcaService} from 'src/app/services/amgca/amgca.service';
import {BackendService} from 'src/app/services/backend/backend.service';
import {DialogComponent} from 'src/app/utils/dialog/dialog.component';
import {Customer} from 'src/app/utils/model/customer.model';
import {Equipment} from 'src/app/utils/model/equipment.model';
import {Offer} from 'src/app/utils/model/offer.model';
import {Order} from 'src/app/utils/model/order.model';
import {Software} from 'src/app/utils/model/software.model';
import {TableComponent} from 'src/app/utils/table/table.component';
import {ConfigService} from '../../services/config/config.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent {

  offers: Offer[] = this.backendService.refToEntity(this.route.snapshot.data.resolve.refOffers, true, true).map(_ => new Offer(_));
  refSoftwares: Software[] = this.backendService.refToEntity(this.route.snapshot.data.resolve.refSoftwares, true, false)
    .map(_ => new Software(_));

  navigationState = this.router.getCurrentNavigation().extras.state;
  state: Order = this.getState();
  additionalOrder = true;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    public backendService: BackendService,
    private amgcaService: AmgcaService,
    private configService: ConfigService
  ) {
    if (!this.isOfferDisabled()) {
      if (this.getAmgca()) {
        const isMobileSoftware = this.getAmgcaSoftwares()
          .map(software => software?.name === this.configService.config.mobileSoftwareName)?.[0];
        // If no AmgcaSoftwares correspond, show only not mobile softwares (case Mid Unique is not a software)
        this.offers = this.offers.filter(offer =>
          (typeof isMobileSoftware === 'undefined' && offer.is_mobile_software === false) || offer.is_mobile_software === isMobileSoftware
        );
      }
      if (!this.getAmgca() && this.navigationState) {
        this.autoNavigate();
      } else if (this.getAmgca() && this.state.customer.id_ref) {
        backendService.request('get', 'installed/equipments', {params: this.getQueryParams()}).subscribe(_ => {
          this.additionalOrder = !!(_ && _.length);
          // if(!this.additionalOrder) this.autoNavigate();
        });
      }
    } else {
      const softwares = this.getAmgcaSoftwares();
      this.dialog.open(DialogComponent, {
        data: {
          title: `Vos logiciels sont : ${softwares.map(_ => _.name).join(', ')}<br>
          Le premier logiciel doit être ${this.refSoftwares.filter(_ => _.type === 'included')[0].name}`
        }
      });
    }
  }

  getIdOffer(): string {
    return sessionStorage.getItem('idOffer');
  }

  getAmgca(): string {
    return sessionStorage.getItem('amgca');
  }

  getContractReference(): string {
    return sessionStorage.getItem('contractReference');
  }

  getAmgcaOrder(refSoftwares = null): Order {
    return this.amgcaService.amgcaToOrder(JSON.parse(this.getAmgca()), refSoftwares, this.getIdOffer());
  }

  getAmgcaSoftwares(): Software[] {
    return this.getAmgcaOrder(this.refSoftwares).suborders[0].equipments[0].softwares;
  }

  isOfferDisabled(additionalOrder = false): boolean {
    return this.getAmgca() && !this.isFirstSoftwareIncluded() || (additionalOrder && !this.state.customer.id_ref);
  }

  // The first software must be a software which has a type 'included' or an amgca name Mid Unique
  isFirstSoftwareIncluded(): boolean {
    return this.amgcaService.amgcaContainsMidUnique(JSON.parse(this.getAmgca())) ||
      this.refSoftwares.filter(refSoftware => refSoftware.type === 'included')
        .map(refSoftware => refSoftware?.id_ref).includes(this.getAmgcaSoftwares()[0]?.id_ref);
  }

  getState(): Order {
    if (this.navigationState) {
      return new Order(this.navigationState);
    } else if (this.getAmgca()) {
      return this.getAmgcaOrder();
    } else {
      return new Order();
    }
  }

  autoNavigate(): void {
    if (this.offers.length === 1 && this.state.customer.store_registration_number
      && !this.isOfferDisabled(this.state.suborders[0].additional_order)) {
      this.navigate(this.offers[0]);
    }
  }

  navigate(offer): void {
    const date = (this.state?.suborders?.[0]?.equipments?.[0]?.created_date ?? new Date())?.toISOString().split('T')[0];
    if (date) {
      sessionStorage.setItem('date', date);
    } else {
      sessionStorage.removeItem('date');
    }
    this.router.navigate([offer.id || offer.id_ref], {relativeTo: this.route, state: this.state});
  }

  chooseOffer(offer, additionalOrder = false): void {
    this.state = this.getState();
    let customerState = this.state.customer;
    const store_registration_number = customerState.store_registration_number;
    customerState = store_registration_number ? customerState : null;
    this.state.suborders[0].additional_order = additionalOrder;
    if (!additionalOrder) {
      this.askDialog(false).subscribe(importCustomer => {
        if (importCustomer === true) {
          this.customerDialog(customerState).subscribe(customer => {
            if (customer) {
              this.navigate(offer);
            }
          });
        } else if (importCustomer === false) {
          this.navigate(offer);
        }
      });
    } else {
      this.customerDialog(customerState).subscribe(customer => {
        if (customer) {
          this.equipmentsDialog().subscribe(equipments => {
            if (this.state.suborders[0].equipments?.length) {
              this.navigate(offer);
            }
          });
        }
      });
    }
  }

  askDialog(value = null): Observable<any> {
    const callback = ask => {
    };
    if (value == null) {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          buttons: {
            'Déjà client': true,
            'Nouveau client': false
          }
        }
      });
      dialogRef.beforeClosed().subscribe(callback);
      return dialogRef.afterClosed();
    } else {
      return of(value).pipe(tap(callback));
    }
  }

  customerDialog(value = null): Observable<any> {
    const callback = customer => {
      if (customer) {
        this.state.customer = new Customer(this.backendService.refToEntity(customer, true, true));
      }
    };
    if (value == null) {
      const dialogRef = this.dialog.open(DialogComponent, {
        width: '100vw',
        data: {
          components: [
            {
              name: TableComponent,
              inputs: {
                backendRoute: 'all-inactive/refCustomers',
                displayedColumns: Customer.getDisplayedColumns(),
                filteredColumns: Customer.getFilteredColumns(),
                config: []
              },
              outputs: {
                rowClick: _ => dialogRef.close(_)
              }
            }
          ]
        }
      });
      dialogRef.beforeClosed().subscribe(callback);
      return dialogRef.afterClosed();
    } else {
      return of(value).pipe(tap(callback));
    }
  }

  equipmentsDialog(value = null): Observable<any> {
    const callback = equipments => {
      if (equipments) {
        this.state.suborders[0].equipments = equipments.map(
          (equipment: Equipment) => new Equipment(
            {...this.backendService.filterKey(equipment, 'installed', true), equipment_change_request: false}
          )
        );
        this.state.suborders[0].additional_order = true;
      }
    };
    if (value == null && this.state.customer.id_ref) {
      const dialogRef = this.displayEquipments(this.getQueryParams(), callback, true);
      dialogRef.beforeClosed().subscribe(callback);
      return dialogRef.afterClosed();
    } else {
      return of(value).pipe(tap(callback));
    }
  }

  getQueryParams(): { searchCriterias: string } {
    const idOffer = sessionStorage.getItem('idOffer');
    return {
      searchCriterias: JSON.stringify([
        {
          logical: 'and',
          path: 'suborders.order.customer.idRef',
          operation: 'equal',
          value: this.state.customer.id_ref
        },
        ...(idOffer ? [
          {
            logical: 'and',
            path: 'suborders.idOffer',
            operation: 'equal',
            value: idOffer
          }
        ] : [])
      ])
    };
  }

  displayEquipments(queryParams = {}, callback = _ => {
                    },
                    checkbox = false): MatDialogRef<DialogComponent, any> {
    return this.dialog.open(DialogComponent, {
      width: '100vw',
      data: {
        buttons: {
          [checkbox ? 'Valider' : 'Fermer']: null
        },
        components: [
          {
            name: TableComponent,
            inputs: {
              backendRoute: `installed/equipments`,
              backendParams: queryParams,
              displayedColumns: {
                'softwares.0.contract_reference': {label: 'Contrat monétique', width: 10},
                ...Equipment.getDisplayedColumns(null, checkbox)
              },
              filteredColumns: Equipment.getFilteredColumns(),
              pageSizeOptions: [5],
              config: []
            },
            outputs: {
              selectionChange: _ => callback(_.source.selected)
            }
          }
        ]
      }
    });
  }

  onImgError(event, offer): void {
    const src = this.backendService.backendUrl + 'img/offer/' + offer.id_ref + '.png';
    if (event.target.src !== src) {
      event.target.src = src;
    }
  }
}
