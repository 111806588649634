<div class="d-flex justify-content-center align-items-center" style="min-height: 50vh;" *ngIf="message" [innerHTML]="message"></div>
<div class="d-flex" *ngIf="!message && form && orderForm">
    <div class="col">
        <app-order-display [(formGroup)]="orderForm" class="space"></app-order-display>
    </div>
    <div class="col-3">
        <mat-card class="mt-5 shadow bnpp-title-shadow p-0">
            <mat-card-header class="d-flex align-items-center card-form-logiciel bnpp-title">
                <mat-card-title class="color-primary-700">Instructions</mat-card-title>
            </mat-card-header>
            <mat-card-content class="d-flex flex-column m-3" [formGroup]="form">
                <div class="cgu">
                    <u>Note importante</u> :
                    <br><br>
                    Veuillez vérifier toutes les données présentées ainsi que le contenu du/des document(s) contractuel(s) à signer électroniquement en cliquant sur le lien correspondant.
                    <br><br>
                    Si vous constatez une erreur, nous vous invitons à contacter votre conseiller pour la rectification.
                    <br><br>
                    Sinon, cochez la case ci-dessous et cliquez sur le bouton « Obtenir code SMS signature ».
                </div>
                <mat-checkbox formControlName="cgu">Je certifie avoir lu et pris connaissance des informations contenues dans mon/mes document(s) contractuel(s) avec leurs Conditions Particulières et Générales applicables.</mat-checkbox>
                <ng-container *ngIf="form.get('cgu').enabled">
                    <div class="mt-2">
                        Obtenir le code SMS unique de signature au <b class="pink">{{certeuropeOrder.otp_contact}}</b>
                    </div>
                    <div class="mt-2">
                        <b class="pink">Si le N° de téléphone n'est pas correct, veuillez contacter l'agent Mon TPE.</b>
                    </div>
                    <button mat-raised-button class="mx-0 my-2 background-color-primary-50 opposite-color-foreground-base" [disabled]="!form.get('cgu').value" (click)="validate()">Obtenir code SMS signature</button>
                </ng-container>
                <ng-container *ngIf="form.get('cgu').disabled">
                    <div class="mt-2">
                        Veuillez saisir le code SMS de signature reçu au <b class="pink">{{certeuropeOrder.otp_contact}}</b> et valable pendant 10 minutes.
                    </div>
                    <app-form-field class="px-5 pt-2" [form]="form" [config]="otpConfig"></app-form-field>
                    <div class="d-flex justify-content-center color-accent">{{error}}</div>
                    <button mat-raised-button class="mx-0 my-2 background-color-primary-50 opposite-color-foreground-base" [disabled]="form.get('otp').disabled || !form.get('otp').valid" (click)="sign()">Signer</button>
                    <a (click)="validate()">Obtenir un nouveau code SMS de signature</a>
                </ng-container>
                <div class="mb-3"></div>
            </mat-card-content>
        </mat-card>
    </div>
</div>