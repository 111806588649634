import {ErrorHandler, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor() {
  }

  handleError(error: any): void {
    if (error.code && error.code !== '100') {
      console.error(error);
    }
  }
}
