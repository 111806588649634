import { Builder } from "./builder.model";
import { formatDate } from '@angular/common';
import { Base } from "./base.model";

export class Message extends Base{
  constructor(values: Partial<Message>){
    super(values);
    Builder.build.bind(this)(values);
  }

  message: string = null;
  duration: number = null;
  start_date?: Date = null;
  end_date?: Date = null;
  ref_user?: any = null;

  static getDisplayedColumns(icon = null, checkbox = false) {
    let columns = {};

    columns = {
      ...columns,
      message: {label: 'Message', width: 50},
      // duration: {label: 'Durée (ms)', width: 10, class: 'font-weight-bold color-primary-200'},
      start_date: {label: `Date d'activation`, mapping: _ => _?formatDate(_, "dd-MM-yyyy HH:mm:ss", "fr-FR"):_, width: 10},
      end_date: {label: `Date de désactivation`, mapping: _ => _?formatDate(_, "dd-MM-yyyy HH:mm:ss", "fr-FR"):_, width: 10},
      updated_date: {label: 'Date dernière action', mapping: _ => _?formatDate(_, "dd-MM-yyyy HH:mm:ss", "fr-FR"):_, width: 10},
      'ref_user.firstname': {label: 'Prénom', width: 5},
      'ref_user.lastname': {label: 'Nom', width: 5}
    };

    if (icon !== null) {
      columns = {
        ...columns,
        [icon]: {type: 'icon', width: 5}
      };
    }
    return columns;
  }

}
