import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakTokenParsed } from 'keycloak-js';
import { BackendService } from 'src/app/services/backend/backend.service';
import { DialogComponent } from 'src/app/utils/dialog/dialog.component';
import { Message } from 'src/app/utils/model/message.model';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  constructor(private dialog: MatDialog, private backendService: BackendService, public keycloakService: KeycloakService) { }

  backendRoute = 'messages';
  icon = 'delete';
  displayedColumns = Message.getDisplayedColumns(this.icon);
  namingPage =  "MES MESSAGES";
  namingTable = "liste de mes messages";

  token: KeycloakTokenParsed;

  ngOnInit(): void {
    this.token = this.keycloakService.getKeycloakInstance()?.tokenParsed;
  }

  routerLinkCallback(row) {
    return [row.id || row.id_ref];
  }

  iconClick(row): void{
    this.dialog.open(DialogComponent, {
      data: {
        title: "Souhaitez-vous réellement<br>supprimer ce message?",
        buttons: {
          'Oui': true,
          'Non': false
        }
      }
    }).afterClosed().subscribe(_ => {
       if(_) this.backendService.request('delete', `${this.backendRoute}/${row.id || row.id_ref}`).subscribe(_ => window.location.reload());
    });
  }

  iconDisplay(message: Message): boolean{
    return true; //message?.ref_user?.['mail'] === this.token?.['email'];
  }
}
