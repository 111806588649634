import { Builder } from "../builder.model";
import { StoreAddress } from "./store-address.model";
import { BankAccount } from "./bank-account.model";

export class CustomerInformation {
    constructor(values: Partial<CustomerInformation> = {}) {
        Builder.build.bind(this)(values);
    }

    companyName: string = null;
    companyRegistrationNumber: string = null;
    storeName: string = null;
    frenchNafCode: string = null;
    deliveryAddress: StoreAddress = new StoreAddress();
    mcc: string = null;
    storeRegistrationNumber: string = null;
    bankAccount: BankAccount = new BankAccount();
}
