import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Equipment } from '../../model/equipment.model';
import { FormUtilsService } from 'src/app/services/form-utils/form-utils.service';
import { BackendService } from 'src/app/services/backend/backend.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Software } from '../../model/software.model';
import { Action } from "../../model/action.model";
import { Fare } from '../../model/fare.model';

@Component({
  selector: 'app-customer-equipments-display',
  templateUrl: './customer-equipments-display.component.html',
  styleUrls: ['./customer-equipments-display.component.scss']
})
export class CustomerEquipmentsDisplayComponent implements OnInit {

  constructor(private formUtilsService: FormUtilsService, public backendService: BackendService, private router: Router, private route: ActivatedRoute) { }

  @Input()
  get formArray(): FormArray {
      return this.form;
  }
  set formArray(formArray: FormArray) {
      this.form = formArray;
      this.initSubscriptions();
  }
  form = this.formUtilsService.buildForm([]) as FormArray;

  @Input()
  additional_order = false;

  @Input()
  empty_label = 'Aucune donnée disponible actuellement';

  @Input()
  actions: Action[] = [];

  @Output()
  equipmentClick = new EventEmitter<Object>();

  @Input()
  softwareConfig = [
    {
      externalLabel: 'Logiciel',
      formControlName: 'name',
      type: 'label'
    },
    {
      externalLabel: 'Numéro de contrat',
      formControlName: 'contract_reference',
    },
    {
      externalLabel: 'Emetteur',
      formControlName: 'bank_code',
      required: true,
      pattern: /[0-9]{5}/,
      minLength: 5,
      maxLength: 5,
    },
    {
      externalLabel: 'Tarif',
      formControlName: 'fare',
      type: 'label',
      class: 'd-flex justify-content-center',
      suffix: ' € HT'
    },
  ];

  @Input()
  accessoryConfig = [
    {
      label: 'Accessoire',
      formControlName: 'name',
    },
    {
      label: 'Quantité',
      formControlName: 'quantity',
      input: 'number'
    },
    {
      label: 'Tarif (€ HT)',
      formControlName: 'fare'
    },
  ];

  ngOnInit(): void {
    this.initSubscriptions();
  }

  initSubscriptions(): void {
    this.form.controls.forEach(order => {
        order.get('suborders')['controls'].forEach(suborder => {
            suborder.get('equipments')['controls'].forEach(equipment => {
                ['rental_equipment', 'purchase_equipment', 'yearly_maintenance', 'yearly_subscription3g', 'purchase_softwares'].forEach(_ => {
                    if (!equipment.get(_ + '_total')) {
                        (equipment as FormGroup).addControl(_ + '_total', new FormControl(equipment.get(_ + '_amount')?.value));
                    }
                });
                ['contract_duration', 'contract_type', 'installation_mode'].forEach(_ => {
                    if (!equipment.get(_)) {
                        (equipment as FormGroup).addControl(_, new FormControl(suborder.get(_).value));
                    }
                });
            });
        });
    });
  }

  getEquipmentResultCodeLabel(equipmentControl, id_order, action_names=[]) {
    const actions = this.getEquipmentActions(equipmentControl,id_order,action_names);
    if(actions.length > 0) {
      const action = actions[actions.length-1];
      const result_codes = [action?.maintainer_information?.result_code, ...action?.maintainer_information?.equipments?.filter(equipment => equipment.id == equipmentControl.get('id').value).reduce((result_codes, equipment) => [...result_codes, equipment['result_code'], ...(equipment.softwares?.map(software => software['result_code']) ?? [])], []) ?? []].filter((v,i,a) => v && a.indexOf(v) === i)
      if(result_codes?.length) {
        return result_codes.map(result_code => Action.getResultLabel(result_code) ?? 'Motif inconnu').filter((v,i,a) => a.indexOf(v) === i).join(" / ")
      } else {
        return 'Aucun motif';
      }
    }
    return null;
  }

  getEquipmentActions(equipmentControl, id_order, action_names=[]) {
    return Action.sort(this.actions.filter(action => {
        if(action.id_order != id_order) return false;
        const equipments = action?.maintainer_information?.equipments;
        return (!action_names.length || action_names.includes(action.name)) && (!equipments || equipments.some(equipment => equipment.id == equipmentControl.get('id').value))
    }));
  }

  getSoftwareActions(softwareControl, id_order, action_names=[]) {
    return this.getEquipmentActions(softwareControl.parent.parent, id_order, action_names).filter(action => {
        if(action.id_order != id_order) return false;
        const equipments = action?.maintainer_information?.equipments;
        return (!action_names.length || action_names.includes(action.name)) && (!equipments || equipments.some(equipment => !equipment.softwares || equipment.softwares.some(software => software.id == softwareControl.get('id').value)));
    });
  }

  getAccessoryActions(accessoryControl, id_order, action_names=[]) {
    return this.getEquipmentActions(accessoryControl.parent.parent, id_order, action_names).filter(action => {
        if(action.id_order != id_order) return false;  
        const equipments = action?.maintainer_information?.equipments;
        return (!action_names.length || action_names.includes(action.name)) && (!equipments || equipments.some(equipment => !equipment.accessories || equipment.accessories.some(accessory => accessory.id == accessoryControl.get('id').value)));
    });
  }

  allowNewOrder() {
    return !sessionStorage.getItem('amgca');
  }

  getName(equipment: Equipment) {
    return Equipment.getName(equipment);
  }

  getEquipmentTooltip(equipment: any): string {
    return `Numéro de série: ${equipment.get('serial_number').value} \n ${this.getPeripherals(equipment).map(p => `${p.get('name').value}: ${p.get('serial_number').value}`).join("\n")}`;
  }

  getContractLabel(equipment: Equipment) {
    return Equipment.getContractLabel(equipment);
  }

  getInstallationLabel(equipment: Equipment) {
    return Equipment.getInstallationLabel(equipment);
  }

  getTypeLabel(software: Software) {
    return Software.getTypeLabel(software);
  }

  getGlobalActionLabel(action: Action){
    return Action.getGlobalActionLabel(action);
  }

  getEquipmentActionLabel(action: Action){
    return Equipment.getEquipmentActionLabel(action);
  }
  getEquipmentActionColor(action: Action){
    return Equipment.getEquipmentActionColor(action);
  }

  isPostInstall(fare) {
    return Object.values(Action.getGlobalActionLabel())[1].slice(1).includes(fare.get('action_name').value);
  }

  equipmentFilter(equipment) {
    if(!Fare.isInstalled(equipment.getRawValue()) && !this.isPostInstall(equipment)) return false;
    const suborder = equipment.parent.parent;
    return this.additional_order ? (this.getSoftwares(equipment).length || this.getAccessories(equipment).length) : (equipment.get('id_order').value == suborder.get('id_order').value);
  }
  
  softwareFilter(software) {
    const equipment = software.parent.parent;
    if(!Fare.isInstalled({...software.getRawValue(), installation_mode: equipment.get('installation_mode').value}) && !this.isPostInstall(software)) return false;
    const suborder = equipment.parent.parent;
    return !this.additional_order || (equipment.get('id_order').value != suborder.get('id_order').value && software.get('id_order').value == suborder.get('id_order').value);
  }

  accessoryFilter(accessory) {
    const equipment = accessory.parent.parent;
    if(!Fare.isInstalled({...accessory.getRawValue(), installation_mode: equipment.get('installation_mode').value}) && !this.isPostInstall(accessory)) return false;
    const suborder = equipment.parent.parent;
    return this.additional_order && accessory.get('id_order').value == suborder.get('id_order').value
  }

  peripheralFilter(peripheral) {
    const equipment = peripheral.parent.parent;
    if(!Fare.isInstalled({...peripheral.getRawValue(), installation_mode: equipment.get('installation_mode').value}) && !this.isPostInstall(peripheral)) return false;
    const suborder = equipment.parent.parent;
    return true;
  }

  getOrders() {
    return this.form.controls.filter(order => this.getSuborders(order).length);
  }

  getSuborders(order) {
    return order.controls.suborders.controls.filter(suborder => this.getEquipments(suborder).length);
  }

  getEquipments(suborder) {
    return suborder.controls.equipments.controls.filter(equipment => this.equipmentFilter(equipment));
  }

  getSoftwares(equipment) {
    return equipment.controls.softwares.controls.filter(software => this.softwareFilter(software));
  }

  getAccessories(equipment) {
    return equipment.controls.accessories.controls.filter(accessory => this.accessoryFilter(accessory));
  }

  getPeripherals(equipment) {
    return equipment.controls.peripherals.controls.filter(peripheral => this.peripheralFilter(peripheral));
  }

}
