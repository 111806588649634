import { Product } from "./product.model";
import { Builder } from "./builder.model";

export class Software extends Product {
    constructor(values: Partial<Software> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    contract_reference: string = null;
    type: string = null;
    bank_code: string = null;
    contract_reference_pattern: string = null;
    contract_reference_max_length: number = null;
    default_contract_reference: string = null;
    default_bank_code: string = null;
    disabled: boolean = false;
    min_quantity: number = null;
    max_quantity: number = null;
    disable_validators: boolean = false;
    nepting_application_type: string = null;

    static getTypeLabel(software: Software) {
        return {
          included: 'Inclus',
          purchase: 'Payant'
        }[software.type];
    }

    getTypeLabel() {
      return Software.getTypeLabel(this);
    }
}
