import { Base } from "./base.model";
import { Builder } from "./builder.model";

export class Product extends Base {
    constructor(values: Partial<Product> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    name: string = null;
    fare: number = null;
    additional_order_fare: number = null;
    description: string = null;
    action_name: string = null;
    id_order: number = null;
}
