// Build information, automatically generated by `ng-info`
const build = {
    version: "1.7.4",
    timestamp: "Tue Oct 29 2024 10:33:04 GMT+0100 (GMT+01:00)",
    message: null,
    git: {
        user: null,
        branch: "hotfix/help-page",
        hash: "a0c417",
        fullHash: "a0c417440eda7b3ee919d4c0e49a6a67ca326a40"
    }
};

export default build;