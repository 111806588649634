import { Builder } from "../builder.model";

export class BranchAddress {
    constructor(values: Partial<BranchAddress> = {}) {
        Builder.build.bind(this)(values);
    }

    zipCodeBranch: string = null;
    cityNameBranch: string = null;
    countryCodeBranch: string = null;
    addressBranch: string = null;
    localityBranch: string = null;
}
