import { Base } from "./base.model";
import { Builder } from "./builder.model";

export class ContractEMoney extends Base {
    constructor(values: Partial<ContractEMoney> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    bank_code: string = null;
    contract_reference: string = null;
}
