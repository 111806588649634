import {Component, Input, OnInit} from '@angular/core';
import {Software} from 'src/app/utils/model/software.model';
import {AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {FormUtilsService} from 'src/app/services/form-utils/form-utils.service';
import {distinctUntilChanged} from 'rxjs/operators';
import {BackendService} from 'src/app/services/backend/backend.service';
import {AmgcaService} from 'src/app/services/amgca/amgca.service';
import {RefProductRequirement} from '../../model/ref_product_requirement.model';
import {RefProductIncompatibility} from '../../model/ref_product_incompatibility.model';
import {Equipment} from '../../model/equipment.model';
import {ConfigService} from '../../../services/config/config.service';

@Component({
  selector: 'app-softwares-form',
  templateUrl: './softwares-form.component.html',
  styleUrls: ['./softwares-form.component.scss']
})
export class SoftwaresFormComponent implements OnInit {
  constructor(
    private formUtilsService: FormUtilsService,
    private backendService: BackendService,
    private amgcaService: AmgcaService,
    private configService: ConfigService,
  ) {
  }

  @Input()
  get formArray(): FormArray {
    return this.form;
  }

  set formArray(formArray: FormArray) {
    this.form = formArray;
    this.form.controls.forEach((softwareControl: FormGroup) => {
      this.initSubscriptions(softwareControl);
    });
  }

  form = this.formUtilsService.buildForm([]) as FormArray;

  @Input()
  refOffer: AbstractControl;

  @Input()
  refSoftwares: Partial<Software>[] = [];

  @Input()
  refProductRequirements: RefProductRequirement[] = [];

  @Input()
  refProductIncompatibilities: RefProductIncompatibility[] = [];

  @Input()
  config = [
    {
      externalLabel: 'Logiciel',
      formControlName: 'name',
      type: 'label'
    },
    {
      externalLabel: 'Numéro de contrat',
      formControlName: 'contract_reference',
      required: true,
      error: errors => Object.entries(errors || {}).map(([k, v]) => {
        return {
          pattern: 'Le format du contrat est invalide',
          backend: 'Saisie incorrecte car déjà présent dans la base',
          contract_reference: 'Saisie incorrecte car déjà présent dans la base'
        }[k] || 'Numéro de contrat invalide';
      }).join('\n'),
    },
    {
      externalLabel: 'Emetteur',
      formControlName: 'bank_code',
      required: true,
      pattern: /[0-9]{5}/,
      minLength: 5,
      maxLength: 5,
    },
    {
      externalLabel: 'Tarif',
      formControlName: 'fare',
      type: 'label',
      class: 'd-flex justify-content-center',
      suffix: ' € HT'
    },
  ];

  asyncValidators = [this.backendService.backendValidator(
    _ => 'get',
    _ => 'all/softwares',
    control => (
      this.setSearchCriteriasJson(control)
    )
  ).bind(this.backendService)];

  softwareValidator(idRef: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || !control?.parent?.parent || control.parent.get('id_ref').value === idRef) {
        return null;
      }
      const key = this.formUtilsService.getFormControlName(control);
      return (control.parent.parent.controls as FormGroup[])
        .find(software => software.get('id_ref').value === idRef)
        ?.get(key).value !== control.value ? null : {[key]: true};
    };
  }

  setSearchCriteriasJson(control: AbstractControl): {params: {searchCriterias: string}} {
    return {
      params: {
        searchCriterias: JSON.stringify([
          {
            logical: 'and',
            modifier: null,
            path: 'contractReference',
            operation: 'equal',
            value: control?.value
          },
          {
            logical: 'or',
            modifier: null,
            path: 'actionName',
            operation: 'isNull',
            value: null
          },
          {
            logical: 'or',
            modifier: null,
            path: 'actionName',
            operation: 'notEqual',
            value: 'cancellation'
          },
          {
            logical: 'or',
            modifier: null,
            path: 'inactive',
            operation: 'equal',
            value: 'true'
          },
          ...(this._isApplicationOffer(this.refOffer.value) && control.parent.get('id_ref').value === 149 ? [
            {
              logical: 'and',
              modifier: null,
              path: 'idRef',
              operation: 'equal',
              value: 149
            }
          ] : []),
          ...(!this._isApplicationOffer(this.refOffer.value) || control.parent.get('id_ref').value !== 71 ? [
            {
              logical: 'and',
              modifier: null,
              path: 'idRef',
              operation: 'equal',
              value: 67
            }
          ] : [])
        ])
      }
    };
  }

  getConfig(index): any {
    const config = [...this.config];
    const softwareControl = this.form.get([index]) as FormGroup;
    if (softwareControl) {
      const conf = config.find(_ => _.formControlName === 'contract_reference');
      if (conf) {
        if (!softwareControl.get('disable_validators').value) {
          conf['validators'] = [this.softwareValidator(67).bind(this)];
          softwareControl.get('contract_reference').setAsyncValidators(this.asyncValidators);
        } else {
          conf['validators'] = [];
        }
        if (softwareControl.get('contract_reference_pattern').value || softwareControl.get('contract_reference_max_length').value) {
          conf.pattern = new RegExp(softwareControl.get('contract_reference_pattern').value);
          conf.maxLength = softwareControl.get('contract_reference_max_length').value;
        }
      }
    }
    config.forEach(conf => {
      delete conf['keypress'];
      delete conf['paste'];
    });
    return config;
  }

  ngOnInit(): void {
    this.initRef();
  }

  initRef(): void {
    if (!this.form.length) {
      this.refSoftwares.forEach((software, index) => {
        let type = this.refSoftwares[index].type;
        while (this.mustChooseSoftware(software)) {
          this.addSoftware(software);
          type = 'purchase';
        }
        this.refSoftwares[index].type = type;
      });
    }
    let contractReference: string = null;
    if (sessionStorage.getItem('amgca')) {
      const amgcaOrder = JSON.parse(sessionStorage.getItem('amgca'));
      if (amgcaOrder.equipments.softwares[0].name === this.configService.config.midUniqueProxy) {
        contractReference = amgcaOrder.equipments.softwares[0].contractReference;
      }
    }
    this.form.controls.forEach((softwareControl: FormGroup) => {
      if (contractReference) {
        softwareControl.patchValue({...softwareControl, contract_reference: contractReference});
      }
      this.initSubscriptions(softwareControl);
    });
  }

  initSubscriptions(softwareControl: FormGroup): void {
    this.config.forEach(conf => {
      const control = softwareControl.get(conf.formControlName);
      control.valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
        control.setValue(v, {onlySelf: true, emitEvent: false});
      });
    });
    if (softwareControl.get('disabled').value) {
      ['contract_reference', 'bank_code'].forEach(key => {
        if (softwareControl.get(`default_${key}`).value && softwareControl.get(key).value) {
          softwareControl.get(key).disable();
        }
      });
    }
    if (softwareControl.get('id_order').value) {
      this.formUtilsService.toggleForm(softwareControl, false);
    } else {
      if (softwareControl.get('contract_reference').value) {
        softwareControl.get('contract_reference').disable();
      }
    }
  }

  mustChooseSoftware(software: Partial<Software>): boolean {
    return software.min_quantity != null && this.form.getRawValue()
      .filter(_ => _.id_ref === software.id_ref).length < software.min_quantity;
  }

  canChooseSoftware(software: Partial<Software>): boolean {
    return (software.max_quantity == null || this.form.getRawValue()
        .filter(_ => _.id_ref === software.id_ref).length < software.max_quantity) &&
      !RefProductRequirement.getRequirements(new Software(software), this.refProductRequirements).length &&
      !RefProductIncompatibility.getIncompatibilities(
        new Software(software),
        new Equipment(this.form.parent.getRawValue()),
        this.refProductIncompatibilities
      ).length;
  }

  addSoftware(software: Partial<Software>): FormGroup {
    const contractEmoney = this.form.parent.parent.parent.get('contract_emoney').value;
    if (!this.form.length) {
      software.bank_code = software.bank_code || contractEmoney.bank_code || software.default_bank_code;
      software.contract_reference = contractEmoney.contract_reference || software.default_contract_reference;
    } else {
      software.bank_code = software.bank_code || software.default_bank_code;
      software.contract_reference = software.contract_reference || software.default_contract_reference;
    }
    software = new Software(software);
    const softwareForm = this.formUtilsService.buildForm(software) as FormGroup;
    this.form.push(softwareForm);
    this.initSubscriptions(softwareForm);
    RefProductRequirement.getRequired(software as Software, this.refProductRequirements)
      .map(requirement => this.refSoftwares.find(refSoftware => refSoftware.id_ref === requirement.product_id))
      .filter(_ => _).forEach(soft => {
        const form = this.addSoftware(soft);
        ['id_order', 'contract_reference', 'bank_code'].forEach(key => {
          form.get(key).disable();
          softwareForm.get(key).valueChanges.subscribe(_ => form.get(key).setValue(_, {onlySelf: true, emitEvent: false}));
        });
      });
    return softwareForm;
  }

  isSoftwareRemovable(software: AbstractControl): boolean {
    return (
      software.get('type').value === 'purchase' &&
      !software.get('id_order').value &&
      (!sessionStorage.getItem('amgca') ||
        this.amgcaService.amgcaToOrder(JSON.parse(sessionStorage.getItem('amgca')), this.refSoftwares).suborders[0].equipments[0].softwares
          .filter(amgcaSoftware => amgcaSoftware.id_ref === software.get('id_ref').value).length
        <= this.form.controls.filter(control => control.get('id_ref').value === software.get('id_ref').value).indexOf(software))
    ) && !this.getDependantSoftware(software);
  }

  getDependantSoftware(software): AbstractControl {
    return RefProductRequirement.getRequirements(new Software(software.getRawValue()), this.refProductRequirements).map(requirement => {
      return this.form.controls.find((softwar: FormGroup, i) => softwar.get('id_ref').value === requirement.product_id);
    })[0];
  }

  contractReferenceTooltip(software: AbstractControl): boolean {
    return (!software.get('description').value || software.get('contract_reference').disabled) && !this.getDependantSoftware(software);
  }

  getLinkedSoftwares(software): AbstractControl[] {
    return RefProductRequirement.getRequired(new Software(software.getRawValue()), this.refProductRequirements).map(requirement => {
      return this.form.controls.find((softwar: FormGroup, i) => softwar.get('id_ref').value === requirement.product_id);
    });
  }

  removeSoftware(index: number): void {
    const software = new Software((this.form.get([index]) as FormGroup).getRawValue());
    this.form.removeAt(index);
    RefProductRequirement.getRequired(software, this.refProductRequirements).forEach(requirement => {
      const requirementIndex = this.form.controls.findIndex(
        (softwar: FormGroup, i) => i >= index && softwar.get('id_ref').value === requirement.product_id
      );
      if (requirementIndex >= 0) {
        this.removeSoftware(requirementIndex);
      }
    });
  }

  private _isApplicationOffer(val: number): boolean {
    return val === 7 || val === 8;
  }
}
