<div class="p-3 opposite-background-color-foreground-base border-color-primary-50" [class.border]="title || content" [class.mb-3]="title || content" *ngIf="form">
    <h3 class="color-primary-200 pb-2 m-0" *ngIf="title">{{title}}</h3>
    <div class="mb-2" [innerHTML]="content" *ngIf="content"></div>
    <div class="d-flex justify-content-between">
        <ng-container *ngFor="let conf of radioConfig | keyvalue: nosort" >
            <div [ngClass]="{'d-none': !conf.value.display && ((conf.value.options || {}) | keys).length <= 1, 'd-flex': conf.value.display || ((conf.value.options || {}) | keys).length > 1 }" class="flex-column">
                <mat-label class="mat-body-strong ml-1">{{conf.key}}</mat-label>
                <mat-radio-group (change)="form.patchValue($event.value)" [disabled]="disabled">
                    <mat-radio-button class="warn ml-2" *ngFor="let option of (conf.value.options || {}) | keyvalue: nosort; let index=index" [value]="option.value" [checked]="objectIncludes(option.value,form.value)">{{option.key}}</mat-radio-button>
                </mat-radio-group>
            </div>
        </ng-container>
    </div>
</div>