import { Base } from "./base.model";
import { Builder } from "./builder.model";

export class Offer extends Base {
    constructor(values: Partial<Offer> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    name: string = null;
    additional_order: boolean = false;
    trigram: string = null;
    certeurope: boolean = null;
    nepting_host_merchant_name: boolean = null;
    nepting_user: boolean = null;
    is_mobile_software: boolean = false;
    manual_sign: boolean = null;
}
