import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent {


  constructor(private keycloakService: KeycloakService) {
    sessionStorage.clear();
    this.keycloakService.logout(window.location.origin);
  }

}
