import {Injectable} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {KeycloakService} from 'keycloak-angular';
import {combineLatest, from, Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {Amgca} from 'src/app/utils/model/amgca/amgca.model';
import {Software as AmgcaSoftware} from 'src/app/utils/model/amgca/software.model';
import {Customer} from 'src/app/utils/model/customer.model';
import {Equipment} from 'src/app/utils/model/equipment.model';
import {Order} from 'src/app/utils/model/order.model';
import {Software} from 'src/app/utils/model/software.model';
import {BackendService} from '../backend/backend.service';
import {FormUtilsService} from '../form-utils/form-utils.service';
import {ReferentialService} from '../referential/referential.service';
import {DialogComponent} from 'src/app/utils/dialog/dialog.component';
import {TableComponent} from 'src/app/utils/table/table.component';
import {ConfigService} from '../config/config.service';

@Injectable({
  providedIn: 'root'
})
export class AmgcaService {

  constructor(
    private formUtilsService: FormUtilsService,
    private backendService: BackendService,
    private keycloakService: KeycloakService,
    private dialog: MatDialog,
    private referentialService: ReferentialService,
    private configService: ConfigService,
  ) {
  }

  dictionary = {
    'contractEmoney.bankCode': 'suborders.0.contract_emoney.bank_code',
    'contractEmoney.contractReference': 'suborders.0.contract_emoney.contract_reference',
    'equipments.nbSoftwares': 'suborders.0.equipments.0.softwares_total',
    'equipments.softwares.*.name': 'suborders.0.equipments.0.softwares.*.name',
    'equipments.softwares.*.contractReference': 'suborders.0.equipments.0.softwares.*.contract_reference',
    'customerInformation.companyName': 'customer.company_name',
    'customerInformation.companyRegistrationNumber': 'customer.company_registration_number',
    'customerInformation.storeName': 'customer.store_name',
    'customerInformation.frenchNafCode': 'customer.french_naf_code',
    'customerInformation.deliveryAddress.zipCodeStore': 'customer.store_address.zip_code',
    'customerInformation.deliveryAddress.cityNameStore': 'customer.store_address.city_name',
    'customerInformation.deliveryAddress.countryCodeStore': 'customer.store_address.country_code',
    'customerInformation.deliveryAddress.addressStore': 'customer.store_address.address',
    'customerInformation.deliveryAddress.localityStore': 'customer.store_address.locality',
    'customerInformation.mcc': 'customer.mcc',
    'customerInformation.storeRegistrationNumber': 'customer.store_registration_number',
    'customerInformation.bankAccount.iban': 'customer.bank_account.iban',
    'customerInformation.bankAccount.bic': 'customer.bank_account.bic',
    // "customerInformation.bankAccount.bank": "customer.bank_account.bank",
    idBranchAdvisor: 'suborders.0.id_branch_advisor',
    branchCode: 'suborders.0.branch_code',
    bnppTerritoryCode: 'suborders.0.bnpp_territory_code',
    bnppTerritoryName: 'suborders.0.bnpp_territory_name',
    bnppAreaCode: 'suborders.0.bnpp_area_code',
    bnppAreaName: 'suborders.0.bnpp_area_name',
    'branchAddress.zipCodeBranch': 'suborders.0.branch_address.zip_code',
    'branchAddress.cityNameBranch': 'suborders.0.branch_address.city_name',
    'branchAddress.countryCodeBranch': 'suborders.0.branch_address.country_code',
    'branchAddress.addressBranch': 'suborders.0.branch_address.address',
    'branchAddress.localityBranch': 'suborders.0.branch_address.locality',
    'user.id': 'user.code',
    'user.lastname': 'user.lastname',
    'user.firstname': 'user.firstname',
    'user.userFunction': 'user.function'
  };

  orderToAmgca(order: Order): Amgca {
    order = new Order(order);
    const amgca = new Amgca();
    order.suborders[0].equipments[0].softwares.forEach((v, i) => {
      if (!amgca.equipments.softwares[i]) {
        amgca.equipments.softwares.push(new AmgcaSoftware());
      }
    });
    const orderForm = this.formUtilsService.buildForm(new Order(order)) as FormGroup;
    const amgcaForm = this.formUtilsService.buildForm(amgca) as FormGroup;
    Object.entries(this.dictionary).forEach(([amgcaKey, orderKey]) => {
      if (amgcaKey && amgcaKey.includes('*')) {
        const control = amgcaForm.get(amgcaKey.split('.*')[0]);
        if (control) {
          amgcaForm.get(amgcaKey.split('.*')[0])['controls'].forEach((v, i) => {
            try {
              amgcaForm.get(amgcaKey.replace('*', i)).setValue(orderForm.get(orderKey.replace('*', i))?.value ?? '');
            } catch (e) {
            }
          });
        }
      } else {
        try {
          amgcaForm.get(amgcaKey).setValue(orderForm.get(orderKey)?.value ?? '');
        } catch (e) {
        }
      }
    });
    return new Amgca(amgcaForm.value);
  }

  amgcaToOrder(amgca: Amgca, refSoftwares: Partial<Software>[] = null, idOffer: string = null): Order {
    amgca = new Amgca(amgca);
    const order = new Order();
    if (refSoftwares) {
      order.suborders[0].equipments = [new Equipment()];
      amgca.equipments.softwares.forEach((v, i) => {
        if (!order.suborders[0].equipments[0].softwares[i]) {
          order.suborders[0].equipments[0].softwares.push(new Software());
        }
      });
    }
    const amgcaForm = this.formUtilsService.buildForm(new Amgca(amgca)) as FormGroup;
    const orderForm = this.formUtilsService.buildForm(order) as FormGroup;
    Object.entries(this.dictionary).forEach(([amgcaKey, orderKey]) => {
      if (orderKey && orderKey.includes('*')) {
        const control = orderForm.get(orderKey.split('.*')[0]);
        if (control) {
          orderForm.get(orderKey.split('.*')[0])['controls'].forEach((v, i) => {
            try {
              orderForm.get(orderKey.replace('*', i)).setValue(amgcaForm.get(amgcaKey.replace('*', i))?.value ?? '');
            } catch (e) {
            }
          });
        }
      } else {
        try {
          orderForm.get(orderKey).setValue(amgcaForm.get(amgcaKey)?.value ?? '');
        } catch (e) {
        }
      }
    });
    if (refSoftwares?.length) {
      const removeIds = [];
      const softwaresForm = (orderForm.get('suborders.0.equipments.0.softwares') as FormArray);
      softwaresForm.controls.forEach((software, index) => {
        const refSoftware = refSoftwares.find(ref => ref.name.toLowerCase() === software.get('name').value.toLowerCase());
        if (refSoftware) {
          software.patchValue({
            ...software.value,
            ...refSoftware,
            bank_code: refSoftware.default_bank_code,
            contract_reference: software.value.contract_reference ?? refSoftware.default_contract_reference
          });
        } else {
          removeIds.push(index);
        }
      });
      removeIds.reverse();
      for (const id of removeIds) {
        softwaresForm.removeAt(id);
      }
    }
    let customer = JSON.parse(sessionStorage.getItem('customer'));
    if (customer) {
      customer = new Customer(this.backendService.refToEntity(customer, true, true));
      const customerForm = orderForm.get('customer');
      const amgcaCustomer = customerForm.value;
      customerForm.patchValue(customer);
      customerForm.patchValue(ReferentialService.filterObject(amgcaCustomer));
    }
    return new Order(orderForm.value);
  }

  refreshAmgca(): Observable<any> {
    const amgca = JSON.parse(sessionStorage.getItem('amgca'));
    if (['id', 'contractReference'].every(_ => sessionStorage.getItem(_))) {
      if (!amgca) {
        return this.request();
      } else {
        const order = this.amgcaToOrder(amgca, []);
        if (!Object.entries({
          id: order.user.code,
          contractReference: order.suborders[0].contract_emoney.contract_reference
        }).every(([k, v]) => sessionStorage.getItem(k) === v)) {
          return this.request();
        }
      }
    }
    return of(amgca);
    // .pipe(
    //   switchMap((amgca: Amgca) => {
    //     const customer = JSON.parse(sessionStorage.getItem("customer"));
    //     if(!customer) {
    //       return this.refreshAmgcaCustomer(amgca);
    //     }
    //     return of(customer);
    //   }, (amgca, customer) => amgca)
    // );
  }

  amgcaContainsMidUnique(amgca: Amgca): boolean {
    amgca = new Amgca(amgca);
    return amgca.equipments.softwares.some(software => software?.name === this.configService.config.midUniqueProxy);
  }

  refreshAmgcaCustomer(amgca = null): Observable<any> {
    if (!amgca) {
      amgca = JSON.parse(sessionStorage.getItem('amgca'));
    }
    if (amgca) {
      sessionStorage.removeItem('customer');
      const order = this.amgcaToOrder(amgca, []);
      const contract_reference = order?.suborders[0]?.equipments[0]?.softwares[0]?.contract_reference;
      const store_registration_number = order?.customer?.store_registration_number;
      const params = {
        searchCriterias: JSON.stringify([
          {
            logical: 'and',
            path: 'customers.orders.suborders.contractEmoney.contractReference',
            operation: 'equal',
            value: contract_reference
          }
        ])
      };
      const storeRegistrationNumberSearch = this.backendService.request(
        'get',
        'all-inactive/refCustomers',
        {params: {store_registration_number}}).pipe(map(customers => customers[0])
      );
      return this.backendService.request('get', 'all-inactive/refCustomers', {params}).pipe(
        switchMap(customers => {
          if (customers?.length) {
            const customer = customers.find(c => c.store_registration_number === store_registration_number);
            if (customer) {
              return of(customer);
            } else {
              return this.customerDialog(params, contract_reference, store_registration_number).afterClosed().pipe(
                switchMap((customer1: Customer) => {
                  return storeRegistrationNumberSearch.pipe(
                    map(foundCustomer => {
                      if (foundCustomer) {
                        ['id', 'id_ref', 'id_customer'].forEach(id => customer1[id] = foundCustomer[id]);
                      }
                      return customer1;
                    })
                  );
                })
              );
            }
          }
          return storeRegistrationNumberSearch;
        }),
        tap(customer => {
          if (customer) {
            sessionStorage.setItem('customer', JSON.stringify(customer));
          }
        })
      );
    }
    return of(JSON.parse(sessionStorage.getItem('customer')));
  }

  checkAndFixAddress(addr, check = true): Observable<any> {
    if (check) {
      const address = this.referentialService.normalizeCityName(addr.address);
      if (!address) {
        return this.dialog.open(DialogComponent, {
          disableClose: true,
          data: {
            title: `L'adresse est manquante'.<br>
            Merci d'apporter la correction nécessaire dans "Gérer contrat commerçant/AMGCA"`
          }
        }).afterClosed();
      }
    }
    const zipCode = this.referentialService.normalizeCityName(addr.zip_code);
    const cityName = this.referentialService.normalizeCityName(addr.city_name);
    const locality = this.referentialService.normalizeCityName(addr.locality);
    return combineLatest([
      this.referentialService.laposteSearch(zipCode, false),
      // this.referentialService.laposteSearch(city_name, false)
    ]).pipe(
      switchMap((result: any[]) => {
        const guessedAddresses = result.reduce((acc, val) => [...acc, ...val], []).filter(r =>
          this.referentialService.normalizeCityName(r.zip_code).startsWith(zipCode)
        );
        const matchingAddresses = guessedAddresses.filter(r =>
            (this.referentialService.normalizeCityName(r.city_name).startsWith(cityName)
              || this.referentialService.normalizeCityName(r.city_name_valid).startsWith(cityName))
          // && this.referentialService.normalizeCityName(r.locality).startsWith(locality)
        );
        if (check && !matchingAddresses.length) {
          let cityNames = guessedAddresses
            .reduce((acc, val) => [...acc, val.city_name, val.city_name_valid], [])
            .filter((v, i, a) => v && a.indexOf(v) === i)
            .join(' ou ');
          let localities = guessedAddresses
            .reduce((acc, val) => [...acc, val.locality], [])
            .filter((v, i, a) => v && a.indexOf(v) === i)
            .join(' ou ');
          if (cityNames) {
            cityNames = ` (voulez-vous dire ${cityNames}?)`;
          }
          if (localities) {
            localities = ` (voulez-vous dire ${localities}?)`;
          }
          return this.dialog.open(DialogComponent, {
            disableClose: true,
            data: {
              title: `Le code postal ${zipCode} ne correspond pas à la ville de ${cityName}${cityNames}${locality ? ` et au lieu-dit ${locality}${localities}` : ''}.<br>
              Merci d'apporter la correction nécessaire dans "Gérer contrat commerçant/AMGCA"`
            }
          }).afterClosed();
        } else {
          addr.zip_code = matchingAddresses[0]?.zip_code || zipCode;
          addr.city_name = matchingAddresses[0]?.city_name || cityName;
          addr.locality = matchingAddresses[0]?.locality || locality;
          return of(null);
        }
      })
    );
  }

  request(): Observable<Amgca> {
    const body = {
      contractReference: sessionStorage.getItem('contractReference'),
      id: sessionStorage.getItem('id')
    };
    sessionStorage.removeItem('amgca');
    sessionStorage.removeItem('customer');
    return this.backendService.request('post', 'amgca/tepeo/v1/contract', {body}).pipe(
      switchMap((amgca: Amgca) => {
        const order = this.amgcaToOrder(amgca, []);
        return combineLatest([
          this.checkAndFixAddress(order.customer.store_address),
          this.checkAndFixAddress(order.suborders[0].branch_address, false),
        ]).pipe(switchMap(_ => of(this.orderToAmgca(order))))
          .pipe(map((modal) =>  modal || amgca));
      }),
      switchMap((amgca: Amgca) => {
        return from(this.keycloakService.updateToken(-1)).pipe(map(_ => amgca));
      }),
      switchMap((amgca: Amgca) => {
        return this.refreshAmgcaCustomer(amgca).pipe(map(_ => amgca));
      }),
      tap((amgca: Amgca) => {
        if (amgca) {
          sessionStorage.setItem('amgca', JSON.stringify(new Amgca(amgca)));
        }
      })
    );
  }

  customerDialog(queryParams = {}, contractReference = null, storeRegistrationNumber = null): MatDialogRef<DialogComponent, any> {
    let customer = null;
    return this.dialog.open(DialogComponent, {
      disableClose: true,
      width: '100vw',
      data: {
        title: `
        Votre contrat monétique ${contractReference} est déjà attribué à un SIRET différent.<br>
        Nous vous recommandons de sélectionner un client dans la liste pour le mettre à jour avec ce nouveau SIRET ${storeRegistrationNumber}.<br><br>
        Dans le cas contraire, merci de fermer votre onglet du navigateur internet et<br>
        de revenir sur l'outil après modification du SIRET dans "Gérer contrat commerçant-AMGCA"
        `,
        buttons: {
          'Choisir ce client': () => customer
        },
        components: [
          {
            name: TableComponent,
            inputs: {
              backendRoute: `all-inactive/refCustomers`,
              backendParams: queryParams,
              displayedColumns: Customer.getDisplayedColumns(null, true),
              filteredColumns: Customer.getFilteredColumns(),
              pageSizeOptions: [5],
              config: [],
              multiple: false
            },
            outputs: {
              selectionChange: (_: { source: { selected: any[]; }; }) => customer = _.source.selected[0]
            }
          }
        ]
      }
    });
  }

}
