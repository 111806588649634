<div class="mt-1 mb-5 d-flex justify-content-between">
    <mat-card class="shadow bnpp-title-shadow p-0" *ngFor="let conf of config | keyvalue:nosort">
        <mat-card-header class="d-flex justify-content-between bnpp-title">
            <mat-card-title class="color-primary-700">{{conf.key}}</mat-card-title>
        </mat-card-header>
        <mat-card-content class="p-4 d-flex flex-column align-items-center">
            <canvas baseChart *ngIf="conf.value.chartType"
                [data]="conf.value.data"
                [datasets]="conf.value.datasets"
                [labels]="conf.value.labels"
                [options]="conf.value.options"
                [chartType]="conf.value.chartType"
                [colors]="conf.value.colors"
                [legend]="conf.value.legend"
                [plugins]="conf.value.plugins"
                (chartClick)="conf.value.chartClick($event)"
                (chartHover)="conf.value.chartHover($event)"
            >
            </canvas>
            <ng-container *ngIf="!conf.value.chartType">
                <div class="chart-card d-flex justify-content-center align-items-center w-100" *ngFor="let dataset of conf.value.datasets; let index=index">
                    <mat-icon [svgIcon]="conf.value.labels[index] || conf.value.labels[index%2]" [style.fill]="(conf.value.colors[index] || conf.value.colors[index%2]).pointStyle"></mat-icon>
                    <div class="d-flex flex-column w-100">
                        <span class="chart-kpi" [style.color]="(conf.value.colors[index] || conf.value.colors[index%2]).backgroundColor">{{dataset.data && dataset.data[0]!=null ? dataset.data[0] : '-'}}</span>
                        <span class="chart-label">{{dataset.label}}</span>
                    </div>
                </div>
            </ng-container>
        </mat-card-content>
    </mat-card>
</div>