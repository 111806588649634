import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {KeycloakService} from 'keycloak-angular';
import {BackendService} from 'src/app/services/backend/backend.service';
import {AmgcaService} from '../../services/amgca/amgca.service';
import {Offer} from '../../utils/model/offer.model';
import {ConfigService} from '../../services/config/config.service';
import {Software} from '../../utils/model/software.model';
import {Order} from '../../utils/model/order.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    protected readonly keycloak: KeycloakService,
    private router: Router,
    private route: ActivatedRoute,
    private backendService: BackendService,
    private amgcaService: AmgcaService,
    private configService: ConfigService,
  ) {
    if (this.getAmgca()) {
      const isMobileSoftware = this.getAmgcaSoftwares()
        .map(software => software?.name === this.configService.config.mobileSoftwareName)?.[0];
      this.offers = this.offers.filter(offer =>
        (typeof isMobileSoftware === 'undefined' && offer.is_mobile_software === false) || offer.is_mobile_software === isMobileSoftware
      );    }
    this.navigate();
  }

  messages = this.route.snapshot.data.resolve.messages;
  offers: Offer[] = this.route.snapshot.data.resolve.refOffers;
  refSoftwares: Software[] = this.route.snapshot.data.resolve.refSoftwares;

  navigate(offer = null): void {
    if (offer) {
      sessionStorage.setItem('idOffer', offer?.id);
    } else {
      sessionStorage.removeItem('idOffer');
    }
    if (sessionStorage.getItem('idOffer')) {
      this.router.navigate(['/']);
    }
  }

  getIdOffer(): string {
    return sessionStorage.getItem('idOffer');
  }

  getAmgca(): string {
    return sessionStorage.getItem('amgca');
  }

  getAmgcaSoftwares(): Software[] {
    return this.getAmgcaOrder(this.refSoftwares).suborders[0].equipments[0].softwares;
  }

  getAmgcaOrder(refSoftwares = null): Order {
    return this.amgcaService.amgcaToOrder(JSON.parse(this.getAmgca()), refSoftwares, this.getIdOffer());
  }

  onImgError(event, offer): void {
    const src = this.backendService.backendUrl + 'img/offer/' + offer.id + '.png';
    if (event.target.src !== src) {
      event.target.src = src;
    }
  }

}
