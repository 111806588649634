import { Builder } from "../builder.model";

export class User {
    constructor(values: Partial<User> = {}) {
        Builder.build.bind(this)(values);
    }

    id: string = null;
    lastname: string = null;
    firstname: string = null;
    userFunction: string = null;
}
