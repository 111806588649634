import { Base } from './base.model';
import { Builder } from "./builder.model";

export class Contract extends Base {
    constructor(values: Partial<Contract> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    type: string = null;
    label: string = null;
    path: string = null;
    signed_path: string = null;
    version: string = null;
    url: string = null;
    signed_url: string = null;
    signed: boolean = false;
    downloaded: boolean = false;
}
