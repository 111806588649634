import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-radio-form',
  templateUrl: './radio-form.component.html',
  styleUrls: ['./radio-form.component.scss']
})
export class RadioFormComponent implements OnInit {

  constructor() { }

  @Input() form: AbstractControl;

  @Input()
  radioConfig: Object;

  @Input()
  title: string;

  @Input()
  content: string;

  @Input()
  disabled: boolean;

  ngOnInit(): void {
  }

  objectIncludes(small, big, allowSmallNull = false, allowBigNull = false) {
    return Object.keys(small).every(k => small[k]==big[k] || (allowSmallNull && !small[k]) || (allowBigNull && !big[k]));
  }

  nosort() {}

}
