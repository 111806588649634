<mat-card>
    <mat-card-header>
        <mat-card-title><h3 class="color-primary-200">Documents contractuel(s) en attente de signature</h3></mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ng-container *ngIf="sign==null">
            <div class="text-center my-4">Merci de sélectionner le type de signature du contrat</div>
            <div class="w-100 text-center mt-2 d-flex justify-content-around">
                <button *ngIf="offer?.certeurope" mat-raised-button class="background-color-primary-50 opposite-color-foreground-base" [class.disabled]="loading" [class.background-color-background-status-bar]="loading" [class.border-color-background-status-bar]="loading" (click)="certeurope()">Signature électronique</button>
                <button *ngIf="offer?.manual_sign" mat-raised-button class="background-color-primary-50 opposite-color-foreground-base" [class.disabled]="loading" [class.background-color-background-status-bar]="loading" [class.border-color-background-status-bar]="loading" (click)="sign='manual'">Signature manuelle</button>
            </div>
        </ng-container>
        <ng-container *ngIf="sign=='certeurope'">
            <div>Un courriel valable pendant 24h, contenant le lien pour la signature électronique du/des document(s) contractuel(s) vient d'être envoyé à l'adresse email <b>{{certeuropeOrder?.holder?.email}}</b> du client le {{certeuropeOrder?.created_date | date:'dd/MM/yyyy à HH:mm'}}.</div>
            <div class="d-flex align-items-center flex-column mt-2 mb-2" *ngFor="let suborder of form.controls.suborders['controls']; let s=index">
                <app-contracts-display class="w-100" [(formArray)]="suborder.controls.contracts" [types]="['display']"></app-contracts-display>
            </div>
            <div>Le client devra suivre les instructions indiquées pour accéder à la page de signature et signer électroniquement le(s) document(s) contractuel(s).</div>
            <div style="color: red" class="mt-3"><b>Note</b> :Tant que la signature électronique n'est pas réalisée par le client, la commande n'est pas exécutée et peut être supprimée.</div>
            <div class="w-100 text-center mt-2 d-flex justify-content-around">
                <button mat-raised-button class="background-color-primary-50 opposite-color-foreground-base" [routerLink]="'/orders/'+form.get('id').value">Suivre l'avancement</button>
            </div>
        </ng-container>
        <ng-container *ngIf="sign=='manual'">
            <div>ATTENTION : Avant de valider votre commande, le contrat de location ou de vente doit être impérativement paraphé et signé. Vous devez en conserver une copie.<br>
            Après la validation de votre commande, vous ne pourrez plus la modifier.</div>
            <div class="d-flex align-items-center flex-column mt-2 mb-2" *ngFor="let suborder of form.controls.suborders['controls']; let s=index">
                <app-contracts-display class="w-100" [(formArray)]="suborder.controls.contracts" [types]="['print']"></app-contracts-display>
            </div>
            <div style="color: red" class="mt-3 mb-2 d-none"><b>Note</b> :Une fois les contrat(s) signé(s), il faut le(s) déclarer dans le suivi de commande.</div>
            <div class="w-100 text-center mt-2 d-flex justify-content-around" *ngIf="contractsDownloaded()">
                <button mat-raised-button class="background-color-primary-50 opposite-color-foreground-base" [routerLink]="'/orders/'+form.get('id').value">Suivre l'avancement</button>
            </div>
        </ng-container>
    </mat-card-content>
</mat-card>
<div class="overlay d-flex justify-content-center" *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>
