import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from '../config/config.service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EMPTY, Observable, combineLatest, of } from 'rxjs';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/utils/dialog/dialog.component';

type Options = {
    body?: any;
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: "body" | "events" | "response";
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: "arraybuffer" | "blob" | "text" | "json";
    withCredentials?: boolean;
};

@Injectable()
export class BackendService {
    backendUrl = '';
    dialogRef = null;

    constructor(private configService: ConfigService, private http: HttpClient, private dialog: MatDialog) {
        this.backendUrl = configService?.config?.backend.url + '/';
    }

    isObject(object) {
        return object && typeof object === 'object' && object.toString() === '[object Object]';
    }

    objectDiff(objecta, objectb, recursive = true) {
        let def = undefined;
        if (Array.isArray(objecta) && Array.isArray(objectb)) {
            def = [];
        } else if (this.isObject(objecta) && this.isObject(objectb)) {
            def = {};
        }
        if(def!==undefined) {
            const diff = [...Object.keys(objecta ?? def),...Object.keys(objectb ?? def)].filter((v,i,a) => a.indexOf(v)===i).reduce((acc, val) => {
                const diff = this.objectDiff(objecta[val],objectb[val],recursive);
                if(diff!==undefined) acc[val] = diff;
                return acc;
            }, def);
            if(Object.values(diff).length) {
                ['id', 'id_ref'].forEach(key => {
                    if(objectb[key]) diff[key] = objectb[key];
                })
                if(recursive || Object.values(diff).some(_ => Array.isArray(_) || this.isObject(_))) return diff;
                else return objectb;
            }
            return undefined;
        } else {
            return (objecta !== objectb) ? objectb : undefined;
        }
    }

    additionalOrderFare(value, additional_order = true) {
        if (Array.isArray(value)) {
            return value.map(_ => this.additionalOrderFare(_,additional_order)).filter(_ => _);
        } else if (this.isObject(value)) {
            let v = Object.keys(value).reduce((acc, val) => ({...acc, [val]: this.additionalOrderFare(value[val],additional_order)}), {});
            if(additional_order) {
                v['fare'] = v['additional_order_fare'] ?? v['fare'];
            }
            return v;
        } else {
            return value;
        }
    }

    filterKey(value, key, bool, filter = _ => true) {
        if (Array.isArray(value)) {
            return value.map(_ => this.filterKey(_,key,bool,filter)).filter(_ => _);
        } else if (this.isObject(value)) {
            let v = (bool == null || !filter(value) || !!value[key] == !!bool) ? value : null;
            if(v) {
                v = Object.keys(v).reduce((acc, val) => {
                    acc[val] = this.filterKey(value[val],key,bool,filter);
                    return acc;
                }, {});
            }
            return v;
        } else {
            return value;
        }
    }

    refToEntity(value, replaceValues = true, replaceKeys = true) {
        if (Array.isArray(value)) {
            return value.map(_ => this.refToEntity(_,replaceValues,replaceKeys));
        } else if (this.isObject(value)) {
            let v = Object.keys(value).reduce((acc, val) => {
                let key = val;
                if(replaceKeys) key = val.replace(/^ref_/,'');
                acc[key] = this.refToEntity(value[val],replaceValues,replaceKeys);
                return acc;
            }, {});
            if(replaceValues) {
                v['id_ref'] = v['id'] || v['id_ref'];
                v['id'] = null;
            }
            return v;
        } else {
            return value;
        }
    }

    entityToRef(value, replaceValues = true, replaceKeys = true) {
        if (Array.isArray(value)) {
            return value.map(_ => this.entityToRef(_,replaceValues,replaceKeys));
        } else if (this.isObject(value)) {
            let v = Object.keys(value).reduce((acc, val) => {
                let key = val;
                if(replaceKeys && (Array.isArray(value[val]) || this.isObject(value[val]) )) key = `ref_${val}`;
                acc[key] = this.entityToRef(value[val],replaceValues,replaceKeys);
                return acc;
            }, {});
            if(replaceValues) {
                v['id'] = v['id_ref'] || v['id'];
                v['id_ref'] = null;
            }
            return v;
        } else {
            return value;
        }
    }

    hateoasToJson(value) {
        return (<any>Object.values(value._embedded)[0]).map(val => {
            val.id = val.id || val._links.self.href.split("/").pop();
            return val;
          },[])
    }

    requestRef(method: string = 'get', url: string = '', options: Options = {}) {
        return this.request(method, url, options).pipe(map(_ => this.refToEntity(_,true,true)));
    }

    request(method: string = 'get', url: string = '', options: Options = {}) {
        return this.requestWithPaginator(method, url, options).pipe(map((_:any) => {
            if(!_) return _;
            if(_.content) return _.content;
            if(_._embedded) return this.hateoasToJson(_);
            return _;
        }));
    }

    requestWithPaginator(method: string = 'get', url: string = '', options: Options = {}): Observable<any> {
        return this.requestWithoutCatchError(method, url, options)
        .pipe(
            catchError((err: HttpErrorResponse) => {
                if (err.statusText !== 'OK' && err.status !== 200) {
                  console.error(err);
                }
                const errorMessage = (this.configService.config.errorMessages || {})[err.status];
                if(errorMessage) {
                    this.dialogRef?.close();
                    this.dialogRef = this.dialog.open(DialogComponent, {
                        disableClose: true,
                        data: {
                            title: errorMessage,
                        },
                    });
                }
                if(options?.params?.['sort']) {
                    delete options.params['sort'];
                    return this.requestWithPaginator(method, url, options);
                }
                return EMPTY;
            })
        );
    }

    requestWithoutCatchError(method: string = 'get', url: string = '', options: Options = {}) {
        return this.http.request(method, `${this.backendUrl}${url}`, options)
        .pipe(
            map(response => JSON.parse(JSON.stringify(response), (key: string, value: any) => {
                if(["date", "sent", "created_date", "updated_date", "start_date", "end_date", "sign_date"].includes(key)) {
                    try {
                        const timestamp = new Date(value).getTime();
                        const currentTimestamp = new Date().getTime();
                        value = new Date(Math.round(timestamp*Math.pow(10, currentTimestamp.toString().length - timestamp.toString().length)));
                    } catch(e) {}
                }
                return value;
            }))
        );
    }

    mail(Variables: any, Subject= '[{{var:offer_name}}] : Commande [{{var:bnppsm_order_number}}] / {{var:customer.delivery_address.name}}') {
        Variables = JSON.parse(JSON.stringify(Variables, (k, v) => v == null ? '' : v ));
        return combineLatest([
            this.http.get('assets/mails/mail.html', {responseType: 'text'}),
            this.getAttachments(),
            this.getAttachments(['assets/icons/BNPP.png', 'assets/icons/TEPEO.png'], true)
        ]).pipe(switchMap(([HTMLPart, Attachments, InlinedAttachments]) => {
            return this.request('post', 'mailjet/send', {
                body: {
                    Messages: [{
                        From: {
                            Email: 'youness.attigui@gmail.com',
                            Name: 'AXEPTA'
                        },
                        To: [
                            {
                                Email: Variables.customer.delivery_address.email,
                                Name: Variables.customer.delivery_address.name
                            },
                        ],
                        TemplateErrorReporting: {
                            Email: 'youness.attigui@gmail.com',
                            Name: 'AXEPTA'
                        },
                        Subject,
                        HTMLPart,
                        // TemplateID: templateId,
                        TemplateLanguage: true,
                        Attachments,
                        InlinedAttachments,
                        Variables
                    }]
                }
            });
        }));
    }

    getAttachments(urls: string[] = [], inlined= false) {
        if (!urls.length) { return new Observable(subscriber => subscriber.next([])); }
        return combineLatest(urls.map(url => {
            let blob = null;
            return this.http.get(url, {responseType: 'blob'})
                .pipe(
                    switchMap(_ => {blob = _; return this.blobToBase64(_); }),
                    map(base64 => {
                        const attachment: any = {
                            Filename: url.split('/').pop(),
                            ContentType: blob.type,
                            Base64Content: base64
                        };
                        if (inlined) {
                            attachment.ContentId = attachment.Filename;
                        }
                        return attachment;
                    })
                );
        }));
    }

    blobToBase64(blob: Blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Observable(subscriber => {
            reader.onloadend = () => {
                subscriber.next((reader.result as string).split(',')[1]);
            };
        });
    }

    backendValidator(method?: (control: AbstractControl) => string, url?: (control: AbstractControl) => string, options?: (control: AbstractControl) => Object): ValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors|null> => {
            if (!control.value) {
                return of(null);
            }
            return this.request(method(control), url(control), options(control)).pipe(map(_ => !_ ? null : { backend: true }));
        };
    }

}
