import { Builder } from "../builder.model";
import { Software } from "./software.model";

export class Equipments {
    constructor(values: Partial<Equipments> = {}) {
        Builder.build.bind(this)(values);
    }

    nbSoftwares: number = null;
    softwares: Software[] = [];
}
