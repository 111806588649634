import { Base } from "../base.model";
import { Builder } from "../builder.model";
import { Equipment } from "../equipment.model";
import { Merchant } from "./merchant.model";
import { Store } from "./store.model";

export class Terminal extends Base {
    constructor(values: Partial<Terminal> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    serial_number: string = null;
    terminal_serial_number: string = null;
    merchant: Merchant = new Merchant();
    merchant_id: string = null;
    store: Store = new Store();
    store_id: string = null;
    // constructor: string = null;
    model: string = null;
    action_type: "ACTIVATE" | "DEACTIVATE" | "MAINTENANCE" = null;
    reason: string = null;
    is_terminal_active: boolean = null;
    pos_number: number = null;
    terminal_manufacturer_id: string = null;
    alias: string = null;
    terminal_status: string = null;
    detailed_status: string = null;
    nlpa: string = null;
    first_gmttime: string = null;
    last_gmttime: string = null;
    status_modification_gmttime: string = null;
    id_terminal_config: string = null;
    app_handler_terminal_version: string = null;
    bios_version: string = null;
    app_cbterminal_version: string = null;
	equipment: Equipment = new Equipment();
}
