<ng-container *ngIf="getOrders().length; else empty">
    <div class="equipments-header d-flex p-1">
        <div class="col p-1">Matériel</div>
        <div class="col-2 p-1" *ngIf="!additional_order">N° de série</div>
        <div class="col-1 p-1" *ngIf="!additional_order">Contrat monétique</div>
        <div class="col-1 p-1" *ngIf="!additional_order">Nature du contrat</div>
        <div class="col-1 p-1">Etat</div>
        <div class="col-1 p-1">Date d'installation</div>
        <div class="col-1 p-1">Date de retour</div>
        <div class="col-1 p-1">N° de commande</div>
        <div class="col-1 p-1">Logiciels installés</div>
    </div>
    <ng-container *ngFor="let order of form.controls; let o=index">
        <ng-container *ngFor="let suborder of order.controls.suborders['controls']; let s=index">
            <ng-container *ngIf="getEquipments(suborder).length">
                <ng-container *ngFor="let equipment of suborder.controls.equipments['controls']; let e=index">
                    <mat-expansion-panel [expanded]="true" *ngIf="equipmentFilter(equipment)" class="no-shadow">
                        <mat-expansion-panel-header class="mt-2 equipments-detail-header">
                            <div class="equipments-detail w-100 p-1 pr-3 d-flex align-items-center"
                            [attr.data-id-order]="order.get('id').value"
                            [attr.data-id-suborder]="suborder.get('id').value"
                            [attr.data-id-equipment]="equipment.get('id').value"
                            >
                                <div class="col p-1 d-flex align-items-center"
                                    [matTooltip]="getEquipmentTooltip(equipment)">
                                    <img [src]="backendService.backendUrl+'img/equipment/'+equipment.get('id_ref').value+'.png'"
                                        onerror="this.src='../../../assets/icons/TPE.png'"
                                        height="25">
                                    <span class="px-1">{{getName(equipment.getRawValue())}} - {{equipment.get('communication_type').value}} {{!suborder.get('additional_order').value && equipment.get('equipment_change_request').value?'[Changement de gamme]':''}}</span>
                                    <mat-icon class="pt-1" style="font-size: 16px;">info</mat-icon>
                                </div>
                                <div *ngIf="!additional_order" class="col-2 p-1 text-overflow-ellipsis" [matTooltip]="equipment.get('serial_number').value">{{equipment.get('serial_number').value}}</div>
                                <div *ngIf="!additional_order" class="col-1 p-1">{{suborder.get('contract_emoney').get('contract_reference').value}}</div>
                                <div *ngIf="!additional_order" class="col-1 p-1">{{getContractLabel(equipment.getRawValue())}} / {{getInstallationLabel(equipment.getRawValue())}}</div>
                                <div class="col-1 p-1" 
                                    [matTooltip]="getEquipmentResultCodeLabel(equipment,equipment.get('id_order').value,['equipment_return','equipment_no_return'])"
                                    [style]="'color: ' + getEquipmentActionColor({action_name: equipment.get('action_name').value})">{{getEquipmentActionLabel({action_name: equipment.get('action_name').value})}}</div>
                                <div class="col-1 p-1">{{(getEquipmentActions(equipment,equipment.get('id_order').value,['delivery','installation_done','order_end'])[0] || {})['date'] | date:'dd-MM-yyyy'}}</div>
                                <div class="col-1 p-1">{{(getEquipmentActions(equipment,equipment.get('id_order').value,['equipment_return','equipment_no_return'])[0] || {})['date'] | date:'dd-MM-yyyy'}}</div>
                                <div class="col-1 p-1">
                                    <a class="return-link" routerLink="/orders/{{suborder.get('id').value}}">
                                        {{suborder.get('bnppsm_order_number').value}}
                                    </a>
                                    <span class="text-overflow-ellipsis" [matTooltip]="suborder.get('offer_name').value">{{suborder.get('offer_name').value.replace('AXEPTA IN-STORE', 'AIS')}}</span>
                                </div>
                                <div class="col-1">{{getSoftwares(equipment).length}}</div>
                            </div>
                        </mat-expansion-panel-header>
                        <ng-container *ngIf="getSoftwares(equipment).length">
                            <div class="softwares-header d-flex p-1 align-items-end">
                                <div class="col-3 p-1">Désignation</div>
                                <div class="col-2 p-1">Contrat monétique</div>
                                <div class="col-2 p-1">Nature du logiciel</div>
                                <div class="col-1 p-1">Quantité</div>
                                <div class="col-1 p-1">Tarif € HT</div>
                                <div class="col-1 p-1">Date d'installation</div>
                                <div class="col-2 p-1"></div>
                            </div>
                            <div class="softwares-detail w-100 px-1 d-flex align-items-center" *ngFor="let software of equipment.controls.softwares['controls']; let s=index"
                            [attr.data-id_order]="order.get('id').value"
                            [attr.data-id_suborder]="suborder.get('id').value"
                            [attr.data-id_equipment]="equipment.get('id').value"
                            [attr.data-id_software]="software.get('id').value"
                            >
                                <ng-container *ngIf="softwareFilter(software)">
                                    <div class="col-3 p-1">{{software.get('name').value}}</div>
                                    <div class="col-2 p-1">{{software.get('contract_reference').value}}</div>
                                    <div class="col-2 p-1">{{getTypeLabel(software.getRawValue())}}</div>
                                    <div class="col-1 p-1">1</div>
                                    <div class="col-1 p-1">{{software.get('fare').value}}</div>
                                    <div class="col-1 p-1">{{(getSoftwareActions(software,software.get('id_order').value,['delivery','installation_done','order_end'])[0] || {})['date'] | date:'dd-MM-yyyy'}}</div>
                                    <div class="col-2 p-1"></div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="getAccessories(equipment).length">
                            <div class="accessories-header d-flex p-1 align-items-end">
                                <div class="col-3">Désignation</div>
                                <div class="col-2">Quantité</div>
                                <div class="col-2">Tarif € HT</div>
                                <div class="col-3">Date de livraison</div>
                                <div class="col-2"></div>
                            </div>
                            <div class="accessories-detail w-100 p-1 d-flex align-items-center" *ngFor="let accessory of equipment.controls.accessories['controls']; let a=index"
                            [attr.data-id_order]="order.get('id').value"
                            [attr.data-id_suborder]="suborder.get('id').value"
                            [attr.data-id_equipment]="equipment.get('id').value"
                            [attr.data-id_accessory]="accessory.get('id').value"
                            >
                                <ng-container *ngIf="accessoryFilter(accessory)">
                                    <div class="col-3">{{accessory.get('name').value}}</div>
                                    <div class="col-2">{{accessory.get('quantity').value}}</div>
                                    <div class="col-2">{{accessory.get('fare').value}}</div>
                                    <div class="col-3">{{(getAccessoryActions(accessory,accessory.get('id_order').value,['delivery','installation_done','order_end'])[0] || {})['date'] | date:'dd-MM-yyyy'}}</div>
                                    <div class="col-2"></div>
                                </ng-container>
                            </div>
                        </ng-container>
                        <div class="d-flex justify-content-center mt-2" *ngIf="!additional_order">
                            <button *ngIf="!isPostInstall(equipment) && allowNewOrder() && equipment.get('id_ref').value" mat-raised-button class="background-color-primary-50 opposite-color-foreground-base" (click)="equipmentClick.emit({order: order.getRawValue(),equipment: equipment.getRawValue()})">
                            Nouveau service
                            </button>
                        </div>
                    </mat-expansion-panel>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
<ng-template #empty>
    <div class="py-4 my-4 d-flex justify-content-center align-items-center">{{empty_label}}</div>
</ng-template>