import { Builder } from "./builder.model";

export class Base {
    constructor(values: Partial<Base> = {}) {
        Builder.build.bind(this)(values);
    }

    id: number = null;
    id_ref: number = null;
    created_date: Date = null;
    updated_date: Date = null;
    inactive: boolean = null;
}
