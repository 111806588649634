import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakTokenParsed } from 'keycloak-js';
import { BackendService } from './services/backend/backend.service';
import { DialogComponent } from './utils/dialog/dialog.component';
import build from "../build";
import { AuthGuard } from './guards/auth/auth.guard';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  build: any = build;
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer, public backendService: BackendService, public keycloakService: KeycloakService, private dialog: MatDialog, public router: Router) {
    this.updateToken();
    this.keycloakService.keycloakEvents$.subscribe(e => {
      this.updateToken();
    });
    matIconRegistry.addSvgIconResolver((name: string, namespace: string) => domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${namespace}/${name}.svg`));
    this.router.events.subscribe(event => {
      if(event instanceof NavigationStart) this.loading = true;
      else if(event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) this.loading = false;
    })
  }
  loading = false;

  sessionStorage = sessionStorage;

  token: KeycloakTokenParsed;

  getIdOffer() {
    return sessionStorage.getItem("idOffer");
  }

  updateToken() {
    this.token = this.keycloakService.getKeycloakInstance()?.tokenParsed;
  }

  isRouteActive(path) {
    return window.location.hash.startsWith(`#/${path}`);
  }

  getDate(timestamp) {
    return new Date(timestamp).toLocaleString().replace(',', ' à');
  }

  displayHelp() {
    this.dialog.open(DialogComponent, {
      data: {
        content: `
        <div class="pb-4 d-flex flex-column align-items-center justify-content-between help">
          <div>
            PROCESS ESCALADE :
            <a href="../../../assets/img/process_escalade.png" target="_blank"><u>Visualiser</u></a>
            <a href="../../../assets/img/process_escalade.png" download><u>Télecharger</u></a>
          </div>
          <div>
            FAQ de l’outil de commande de matériels MON TPE : 
            <a href="../../../assets/pdf/FAQ MON TPE.pdf" target="_blank"><u>Visualiser</u></a>
            <a href="../../../assets/pdf/FAQ MON TPE.pdf" download><u>Télecharger</u></a>
          </div>
          <div>
            PAS-À-PAS de l’outil de commande de matériels MON TPE : 
            <a href="../../../assets/pdf/insertion nouvel outil commande.pdf" target="_blank"><u>Visualiser</u></a>
            <a href="../../../assets/pdf/insertion nouvel outil commande.pdf" download><u>Télecharger</u></a>
          </div>
        </div>
        <img src="../../../assets/icons/help.png" width="100%">
        `
      }
    });
  }

  check(route: ActivatedRouteSnapshot): boolean{
    return AuthGuard.checkRolesAndGroups(this.token['groups'], this.token.realm_access.roles, route);
  }

}
