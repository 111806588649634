import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { auditTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { BackendService } from 'src/app/services/backend/backend.service';
import { FormUtilsService } from 'src/app/services/form-utils/form-utils.service';
import { Action } from '../model/action.model';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements AfterViewInit, OnDestroy {

  constructor(private backendService: BackendService, private router: Router, private formUtilsService : FormUtilsService) {}

  @Input()
  footer = false;
  @Input()
  stringFilter = true;
  @Input()
  pageSelection = true;
  @Input()
  pageSizeOptions = [12, 8, 15, 30, 50];
  @Input()
  displayedColumns = {};
  @Input()
  filteredColumns = {};
  @Input()
  backendRoute: string;
  @Input()
  backendParams = {};
  @Input()
  routerLinkCallback: (element, index) => string | any[];
  @Output()
  rowClick = new EventEmitter<Object>();

  @Input()
  icon: string;
  @Input()
  svgIcon: string;
  @Input()
  iconDisplay = _ => false;
  @Output()
  iconClick = new EventEmitter<Object>();
  @Output()
  params = new EventEmitter<Object>();
  @Output()
  previous_params = new EventEmitter<Object>();

  @Input()
  namingPage: string;
  @Input()
  namingTable: string;

  @Input()
  active: string = "updated_date";
  @Input()
  direction: 'asc' | 'desc' = "desc";

  loading = true;
  paginated = true;
  dataSource = new MatTableDataSource();
  behaviorSubject = this.dataSource.connect();
  subscription: Subscription;
  filterEvent = new EventEmitter<String[]>();
  pageEvent = new EventEmitter<number>();

  @Input()
  multiple = true;
  selection: SelectionModel<unknown>;
  @Output()
  selectionChange = new EventEmitter<SelectionChange<unknown>>();

  @Input()
  state = {};

  objectFilter = {...this.router.getCurrentNavigation()?.extras?.state};
  searchCriterias = Object.keys(this.objectFilter).map(_ => ({
    logical: "and",
    modifier: null, //"lower",
    path: this.snakeToCamel(_),
    operation: "equal", //"like",
    value: this.objectFilter[_] //`%${this.objectFilter[_]}%`.toLowerCase()
  }));

  @Input()
  config = [
    {
        formControlName: 'action_label',
        externalLabel: 'Etat de la commande',
        floatLabel: 'never',
        icon: 'filter_list',
        color: 'accent',
        class: 'accent',
        maxLength: 50,
        input: 'select',
        options: {
            'Tous': "",
            ...Object.keys(Action.getGlobalActionLabel()).reverse().reduce((acc,val)=>({...acc,[val]:val}),{})
        }
    },
    {
        formControlName: 'created_date',
        floatLabel: 'never',
        input: 'date',
        color: 'accent',
        class: 'accent',
        max: this.offsetDate()
    }
  ];
  form = this.formUtilsService.buildForm(this.getFormObject()) as FormGroup;
  filter = new FormControl(JSON.parse(sessionStorage.getItem(this.router.url))?.filter)

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.dataSource.disconnect();
  }

  getPlaceholder() {
      return Object.values(this.filteredColumns).filter(_ => _).join(', ')
  }

  getFormObject() {
    if(!this.config.length) {
        this.objectFilter = {};
        this.searchCriterias = [];
    }
    return this.config.reduce((acc,val) => {
        const arr = val.formControlName.replace(/\[([0-9]+)\]/g,".$1").split('.');
        const last = arr.pop();
        let current = acc;
        arr.forEach(_ => {if(!current[_]) current[_] = {};current = current[_]});
        if(val.input=='date') {
          if(!current[last]) {
            current[last] = {};
          }
          if(!current[last]?.start) {
            current[last].start = this.offsetDate(new Date(),0,-10);
          } else {
            current[last].start = this.offsetDate(new Date(current[last].start));
          }
          if(!current[last]?.end) {
            current[last].end = this.offsetDate();
          } else {
            current[last].end = this.offsetDate(new Date(current[last].end));
          }
          val['comparisonStart'] = this.getComparisonStart(current[last]);
          val['comparisonEnd'] = this.getComparisonEnd(current[last]);
          this.applyFilter(this.dateString(current[last].start), val.formControlName, 'greaterThanOrEqualTo', null);
          this.applyFilter(this.dateString(this.offsetDate(current[last].end,1)), val.formControlName, 'lessThan', null);
        } else {
          if(val.input=='select' && current[last]==null) {
            current[last] = Object.values(val.options || {})[0];
          }
          this.applyFilter(current[last], val.formControlName);
        }
        return acc;
    },JSON.parse(sessionStorage.getItem(this.router.url)) || {})
  }

  ngAfterViewInit() {
    if(Object.entries(this.state).length) {
      this.objectFilter = {...this.state, ...this.objectFilter};
      this.searchCriterias = Object.keys(this.objectFilter).map(_ => ({
        logical: "and",
        modifier: null, //"lower",
        path: this.snakeToCamel(_),
        operation: "equal", //"like",
        value: this.objectFilter[_] //`%${this.objectFilter[_]}%`.toLowerCase()
      }));
    }
    if(JSON.stringify(this.getFormObject()) != JSON.stringify(this.form.getRawValue())) this.form = this.formUtilsService.buildForm(this.getFormObject()) as FormGroup;
    this.selection = new SelectionModel(this.multiple);
    this.selection.changed.subscribe(_ => this.selectionChange.emit(_));
    this.updateDisplayedColumns([]);
    if(this.backendRoute.startsWith("ref")) {
        this.config = this.config.filter(_ => _.formControlName != "action_name");
        const found = this.config.find(_ => _.formControlName.startsWith("user"));
        if(found) found.formControlName = found.formControlName.replace("user","ref_user");
        this.form = this.formUtilsService.buildForm(this.getFormObject()) as FormGroup;
    }
    this.getAutocomplete();
    this.config.forEach((config, index) => {
        this.form.get(config.formControlName).valueChanges.pipe(filter(_ => {
          if(config.input == 'date') {
            return ["start", "end"].every(key => !document.querySelector(`[formControlName='${key}']`)["value"] || this.form.get(config.formControlName).value[key]);
          }
          return this.form.get(config.formControlName).valid
        }), distinctUntilChanged((x, y) => JSON.stringify(x) === JSON.stringify(y)), auditTime(1000)).subscribe(value => {
            if(config.input == 'date') {
                if(this.isDateValid(config["min"])) {
                  if(this.isDateValid(value.start) && config["min"] > value.start) value.start = config["min"];
                  if(this.isDateValid(value.end) && config["min"] > value.end) value.end = config["min"];
                }
                if(this.isDateValid(config["max"])) {
                  if(this.isDateValid(value.start) && config["max"] < value.start) value.start = config["max"];
                  if(this.isDateValid(value.end) && config["max"] < value.end) value.end = config["max"];
                }
                if(this.isDateValid(value.start) && this.isDateValid(value.end) && value.start > value.end) {
                  value.end = value.start;
                }
                this.config[index]['comparisonStart'] = this.getComparisonStart(value);
                this.config[index]['comparisonEnd'] = this.getComparisonEnd(value);
                this.applyFilter(this.dateString(value.start), config.formControlName, 'greaterThanOrEqualTo', null);
                this.filterEvent.emit([this.dateString(this.offsetDate(value.end,1)), config.formControlName, 'lessThan', null]);
            } else if(config.input == 'select') {
                this.filterEvent.emit([value, config.formControlName]);
            } else {
                config["autocomplete"] = Object.entries(config['original_autocomplete']).filter(([k,v]) => k.toLowerCase().includes(value.toLowerCase()) || (v as any).toLowerCase().includes(value.toLowerCase())).reduce((acc,[k,v]) => ({...acc,[k]:v}),{});
                const matchingAutocompletes = Object.entries(config["autocomplete"]).filter(([k,v]) => k.toLowerCase() == value.toLowerCase() || (v as any).toLowerCase() == value.toLowerCase()).reduce((acc,[k,v]) => ({...acc,[k]:v}),{});
                if(Object.values(matchingAutocompletes).length == 1) this.form.get(config.formControlName).setValue(Object.values(matchingAutocompletes)[0]);
                this.filterEvent.emit([value, config.formControlName]);
            }
            this.form.get(config.formControlName).setValue(value, {onlySelf: true, emitEvent: false});
        });
    });
    // this.form.valueChanges.subscribe(_ => {
    //   sessionStorage.setItem(this.router.url,JSON.stringify({...JSON.parse(sessionStorage.getItem(this.router.url)),..._}))
    // });
    this.filter.valueChanges.subscribe(_ => {
      this.filterEvent.emit([_]);
      // sessionStorage.setItem(this.router.url,JSON.stringify({...JSON.parse(sessionStorage.getItem(this.router.url)), filter: _}))
    });
    this.applyFilter(this.filter.value);

    this.filterEvent.pipe(distinctUntilChanged(),auditTime(1000)).subscribe(([value,key,operation,modifier]) => {this.applyFilter.bind(this)(value,key,operation,modifier);this.updateData()});
    this.pageEvent.pipe(distinctUntilChanged(),auditTime(1000)).subscribe(pageIndex => {
      this.paginator.pageIndex = Math.min(pageIndex,this.getNumberOfPages());
      this.updateData();
    });
    this.dataSource.filterPredicate = this.filterPredicate;
    if(this.active) {
      this.sort.sort({
        id: this.active,
        start: this.direction || 'asc',
        disableClear: false
      });
    }
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.sort.sortChange.subscribe(this.updateData.bind(this));
    this.paginator.page.subscribe(this.updateData.bind(this));
    this.behaviorSubject.subscribe(this.updateDisplayedColumns.bind(this));
    this.updateData();
  }

  snakeToCamel(value: string) {
    return value.replace(/(_[a-z])/ig, ($1) => {
      return $1.toUpperCase().replace('_', '');
    }).replace(/\.-?[0-9]+/g,"");
  }

  getParams() {
      const params: any = {
        ...this.backendParams,
        size: this.paginator.pageSize,
        page: this.paginator.pageIndex,
        // ...Object.keys(this.objectFilter).reduce((acc, val) => {
        //   acc[this.snakeToCamel(val)] = this.objectFilter[val];
        //   return acc;
        // }, {}),
        searchCriterias: JSON.stringify([...(JSON.parse(this.backendParams['searchCriterias']||null)||[]), ...this.searchCriterias])
      };
      if (this.sort.direction) {
        params.sort = `${this.snakeToCamel(this.sort.active)},${this.sort.direction}${this.displayedColumns?.[this.sort.active]?.sort_ignorecase?',ignorecase':''}`;
      }
      return params;
  }

  updateData() {
    if (this.paginated) {
      const params = this.getParams();
      const datePicker = this.form.get(this.config.find(_ => _.input == 'date')?.formControlName)?.value;
      this.params.emit(params);
      if(datePicker) {
          let previousSearchCriterias = params.searchCriterias;
          try {
            previousSearchCriterias = previousSearchCriterias.replace(
                this.dateString(datePicker.start).toLowerCase(),
                this.dateString(this.getComparisonStart(datePicker)).toLowerCase()
            );
          } catch(e) {}
          try {
            previousSearchCriterias = previousSearchCriterias.replace(
                this.dateString(this.offsetDate(datePicker.end,1)).toLowerCase(),
                this.dateString(this.offsetDate(this.getComparisonEnd(datePicker),1)).toLowerCase()
              );
          } catch(e) {}
          this.previous_params.emit({...params, searchCriterias: previousSearchCriterias})
      }
      this.subscription?.unsubscribe();
      this.loading = true;
      this.subscription = this.backendService.requestWithPaginator('get', this.backendRoute, { params }).subscribe({
        next: (_:any) => {
          if (_.content) {
            this.paginated = true;
            this.behaviorSubject.next(_.content);
            this.paginator.pageIndex = _.number;
            this.paginator.pageSize = _.size;
            this.paginator.length = _.total_elements;
          } else if (_._embedded) {
            this.paginated = true;
            this.behaviorSubject.next(
              (<any>Object.values(_._embedded)[0]).map(val => {
                val.id = val.id || val._links.self.href.split("/").pop();
                return val;
              },[])
            );
            this.paginator.pageIndex = _.page.number;
            this.paginator.pageSize = _.page.size;
            this.paginator.length = _.page.total_elements;
          } else {
            this.paginated = false;
            this.dataSource.data = _;
          }
        },
        complete: () => this.loading = false
      });
    }
  }

  updateDisplayedColumns(data) {
    if (data && data.length) {
      if (!Object.keys(this.displayedColumns).length) {
        this.displayedColumns = Object.entries(data[0]).reduce((acc, [k, v]) => {if(typeof v !== 'object') acc[k] = {label: k, width: 5}; return acc; }, {});
      }
      if (this.icon || this.svgIcon) { this.displayedColumns[this.icon || this.svgIcon] = {type: 'icon', width: 5}; }
    }
    if(!Object.keys(this.filteredColumns).length) {
      this.filteredColumns = Object.entries(this.displayedColumns).reduce((acc,[k,v]) => ({...acc, [k]:v['label']}),{});
    }
  }

  filterPredicate(data: any, filter: string): boolean {
    try {
      const json = JSON.parse(filter);
      return Object.keys(json).every(k => {
        return data[k].toString().toLowerCase().indexOf(json[k].toString().trim().toLowerCase()) != -1;
      });
    } catch (e) {
      const dataStr = Object.keys(data).reduce((currentTerm: string, key: string) => {
        return currentTerm + (data as {[key: string]: any})[key] + '◬';
      }, '').toLowerCase();

      const transformedFilter = filter.trim().toLowerCase();

      return dataStr.indexOf(transformedFilter) != -1;
    }
  }

  applyFilter(value: string, key: string = null, operation: string = null, modifier = "lower") {
    if(!operation) operation = "like";
    if (key) {
      let index = this.searchCriterias.findIndex(_ => _?.path == this.snakeToCamel(key) && _?.operation == operation);
      if(index == -1) index = this.searchCriterias.length;
      const mapping = this.config.find(_ => _.formControlName == key)['mapping'];
      if(mapping) value = mapping(value);
      if (value) {
        if(operation == "like") this.objectFilter[key] = value;
        this.searchCriterias[index] = {
          logical: "and",
          modifier: modifier,
          path: this.snakeToCamel(key),
          operation: operation,
          value: (operation == "like"?`%${value}%`:value).toLowerCase()
        };
      } else {
        delete this.objectFilter[key];
        delete this.searchCriterias[index];
      }
    } else {
      Object.keys(this.filteredColumns).forEach(key => {
        let index = this.searchCriterias.findIndex(_ => _?.path == this.snakeToCamel(key) && _?.operation == operation);
        if(index == -1) index = this.searchCriterias.length;
        if (value) {
          this.objectFilter[key] = value;
          this.searchCriterias[index] = {
            logical: "or",
            modifier: modifier,
            path: this.snakeToCamel(key),
            operation: operation,
            value: (operation == "like"?`%${value}%`:value).toLowerCase()
          };
        } else {
          delete this.objectFilter[key];
          delete this.searchCriterias[index];
        }
      });
      this.dataSource.filter = value;
    }
    this.searchCriterias = this.searchCriterias.filter(_ => _).sort((a,b) => a.logical.localeCompare(b.logical));
    this.dataSource.filter = JSON.stringify(this.objectFilter);
  }

  getStyle(conf, value=null) {
    const width = (100*conf.width/Object.values(this.displayedColumns).map(_ => _["width"]).reduce((acc,val) => acc + val,0))+"%";
    return {
      ...!value?null:(typeof conf.style === 'function'?conf.style(value):conf.style),
      "width": width,
      "min-width": width,
      "max-width": width
    };
  }

  getValue(object, key) {
    return key.replace(/\[([0-9]+)\]/g,".$1").split('.').filter(_=>_).reduce((o,i)=> o!=null?o[Number(i)?((Number(i)+o.length)%o.length):i]:o, object) || object[key];
  }

  getNumberOfPages() {
    return this.paginator && Math.max(this.paginator.getNumberOfPages()-1,0);
  }

  nosort() {}

  getComparisonStart(value) {
    return new Date(this.getComparisonEnd(value)?.getTime() - value?.end?.getTime() + value?.start?.getTime());
  }

  getComparisonEnd(value) {
    return this.offsetDate(value?.start,-1);
  }

  isDateValid(date: Date) {
    return date instanceof Date && !isNaN(date.getTime())
  }

  dateString(date: Date = new Date()): string {
    if(!this.isDateValid(date)) return null;
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours()-date.getTimezoneOffset()/60
    )?.toISOString().replace(/[A-Za-z]/g," ").trim();
  }

  offsetDate(date: Date = new Date(), day: number = 0, month: number = 0, year: number = 0) {
    if(!this.isDateValid(date)) return null;
    return new Date(
      date.getFullYear()+year,
      date.getMonth()+month,
      date.getDate()+day
    );
  }

  getAutocomplete() {
    if(this.config.length) {
        this.config.forEach(config => {
            config["autocomplete"] = config['original_autocomplete'];
        });
        const url = `${this.backendRoute}/kpis/${this.config.filter(conf => conf.input!='date').reduce((acc,val)=>[...acc,this.snakeToCamel(val.formControlName)],[]).filter((v,i,a) => a.indexOf(v)===i).join(",")}`;
        this.backendService.request('get',url,{params: this.getParams()}).subscribe(value => {
            this.config.forEach(config => {
                if(!config['original_autocomplete']) {
                    config['original_autocomplete'] = value.reduce((acc,val)=>{
                        const v = val[config.formControlName];
                        if(v) acc[v] = v;
                        return acc;
                    } ,{});
                }
                config["autocomplete"] = config['original_autocomplete'];
            });
        });
    }
  }

  getTableData() {
    return this.paginated ? this.behaviorSubject.value : this.dataSource.data;
  }

  isSelected(element) {
    return this.selection.selected.map(_ => JSON.stringify(_)).includes(JSON.stringify(element));
  }

  isAllSelected() {
    return this.getTableData().every(element => this.isSelected(element));
  }

  toggleAll() {
    this.isAllSelected() ? this.selection.deselect(...this.getTableData()) : this.selection.select(...this.getTableData());
  }

}
