import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import {
  ActivatedRouteSnapshot,
  Params,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import { AmgcaService } from 'src/app/services/amgca/amgca.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { DialogComponent } from 'src/app/utils/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected readonly router: Router,
    protected readonly keycloak: KeycloakService,
    protected readonly title: Title,
    protected readonly amgcaService: AmgcaService,
    protected readonly dialog: MatDialog,
    protected readonly configService: ConfigService
  ) {
    super(router, keycloak);
  }

  public static checkRolesAndGroups(userGroups: Array<string>, userRoles: Array<string>, route: ActivatedRouteSnapshot): boolean{
    let validRoles = true;
    let validGroups = true;

    // Allow the user to to proceed if no additional roles are required to access the route.
    if ((route.data.roles instanceof Array) && route.data.roles.length > 0) {
      validRoles = route.data.roles.every((role) => userRoles.includes(role));
    }

    if ((route.data.groups instanceof Array) && route.data.groups.length > 0) {
      validGroups = route.data.groups.every((group) => userGroups.includes(group));
    }

    // Allow the user to proceed if all the required roles and groups are present.
    return validRoles && validGroups;
  }

  public static getParams(treeNode) {
    return {...treeNode.children?.map(AuthGuard.getParams).reduce((acc,val) => ({...acc,...val}),{}), ...treeNode.params};
  }

  private getTitle(treeNode) {
    return [treeNode.data.title, ...treeNode.children?.map(this?.getTitle.bind(this)).reduce((acc,val) => ([...acc,...val]),[])];
  }

  public async isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const params = AuthGuard.getParams(state.root) as Params;
    const queryParams = {...route.queryParams};
    (new URLSearchParams(window.location.search)).forEach((v,k) => queryParams[k]=v);

    let id = queryParams["id"];
    const contractReference = queryParams["contractReference"] || queryParams["contract_reference"];
    if(id && contractReference && !params["idpHint"]) params["idpHint"] = this.configService.config.idpHint;

    // Force the user to log in if currently unauthenticated.
    if (!this.authenticated) {
      await this.keycloak.login({
        ...JSON.parse(JSON.stringify(sessionStorage)),
        ...params,
        redirectUri: window.location.href.replace(new RegExp(`#${route.fragment}$`),'')
      });
    }

    const tokenParsed = this.keycloak.getKeycloakInstance()?.tokenParsed;

    const idpHint = tokenParsed["identity_provider"] || params["idpHint"];
    if(idpHint) sessionStorage.setItem("idpHint", idpHint);

    id = tokenParsed["identity_provider_identity"] || id || tokenParsed["preferred_username"];
    if(id) sessionStorage.setItem("id", id);

    if(contractReference) sessionStorage.setItem("contractReference", contractReference);

    const amgca = await this.amgcaService.refreshAmgca().toPromise();
    if(id && contractReference && !amgca) this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        content: "Une erreur s'est produite lors de l'acquisition des informations du client.",
      }
    });

    if(["/login", "/logout"].every(url => !state.url.startsWith(url)) && !sessionStorage.getItem("idOffer") && !tokenParsed['groups'].includes('admin')) {
      this.router.navigate([`/login/${idpHint}`]);
    }

    let title = this.getTitle(state.root).filter((v,i,a) => v && a.indexOf(v)===i).join(" | ") || this.title.getTitle();
    Object.entries(params).forEach(([k,v]) => title = title.replace(`:${k}`,v));
    this.title.setTitle(title);

    // Get the roles required from the route.
    return AuthGuard.checkRolesAndGroups(tokenParsed['groups'], this.roles, route);
  }
}
