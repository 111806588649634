import { Injectable } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormArray, FormBuilder, FormGroup } from '@angular/forms';

@Injectable()
export class FormUtilsService {

  constructor(private formBuilder: FormBuilder) { }

  buildForm(controlsConfig: any, validatorOrOpts?: AbstractControlOptions): AbstractControl {
    if (Array.isArray(controlsConfig)) {
      return this.formBuilder.array(controlsConfig.map(_ => this.buildForm(_)),validatorOrOpts);
    } else if (controlsConfig && typeof controlsConfig === 'object' && controlsConfig.toString() === '[object Object]') {
      return this.formBuilder.group(Object.keys(controlsConfig).reduce((acc, val) => { acc[val] = this.buildForm(controlsConfig[val]); return acc; }, {}),validatorOrOpts);
    } else {
      return this.formBuilder.control(controlsConfig, validatorOrOpts);
    }
  }

  patchForm(control: AbstractControl, value: any, patchArray = true, patchGroup = false, patchControl = false): void {
    if (control instanceof FormArray && Array.isArray(value)) {
      if (patchArray) {
        while (control.length > value.length) {
          control.removeAt(control.length - 1);
        }
        while (control.length < value.length) {
          control.push(this.buildForm(value[control.length]));
        }
      }
      control.controls.forEach((v, i) => this.patchForm(v, value[i], patchArray, patchGroup, patchControl));
    } else if (control instanceof FormGroup && value && typeof value === 'object') {
      if (patchGroup) {
        Object.entries(value).forEach(([k, v]) => {
          if (!control.get(k)) {
            control.addControl(k, this.buildForm(v));
          }
        });
        Object.entries(control.controls).forEach(([k, v]) => {
          if (value[k] === undefined) {
            control.removeControl(k);
          }
        });
      }
      Object.entries(control.controls).forEach(([k, v]) => this.patchForm(v, value[k], patchArray, patchGroup, patchControl));
    } else if(patchControl) {
      control.patchValue(value);
    }
  }

  toggleForm(
      control: AbstractControl,
      toggle = null,
      value = (control instanceof FormArray || control instanceof FormGroup) ? control.getRawValue() : control.value,
      opts = {},
      condition = (control: AbstractControl) => control.value != null
    ): void {
    if (control instanceof FormArray && Array.isArray(value)) {
      control.controls.forEach((v, i) => this.toggleForm(v, toggle, value[i], opts, condition));
    } else if (control instanceof FormGroup && value && typeof value === 'object') {
      Object.entries(control.controls).forEach(([k, v]) => this.toggleForm(v, toggle, value[k], opts, condition));
    } else {
        if(condition(control)) {
            if(toggle===true) control.enable(opts);
            else if(toggle===false) control.disable(opts);
            else {
                if(control.disabled) control.enable(opts);
                else control.disable(opts);
            }
        }
    }
  }

  getFormControlName(control: AbstractControl) {
      if(!control.parent) return null;
      return Object.entries(control.parent.controls).find(([k,v]) => v === control)[0];
  }

}
