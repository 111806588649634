import { Component, Input, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { ActionEnum } from '../../model/action.enum';
import { Action } from '../../model/action.model';


@Component({
  selector: 'app-actions-display',
  templateUrl: './actions-display.component.html',
  styleUrls: ['./actions-display.component.scss']
})
export class ActionsDisplayComponent implements OnInit {

  constructor(public keycloakService: KeycloakService) { }

  value = 100;
  loading = false;

  @Input()
  icons = ['thumb_down', 'thumbs_up_down', 'thumb_up']

  @Input()
  colors = ['primary-A100', 'accent', 'primary-50'];

  @Input()
  actions: Action[] = [];

  @Input()
  config: any[] = [];

  @Input()
  title: string = null;

  ngOnInit(): void {
    const actionNames = Object.keys(ActionEnum);
    this.actions = Action.sort(this.actions).filter(action => ![ "contract_update", "customer_update","customer_update_done","equipment_update","equipment_update_done"].includes(action.name));
    this.actions.forEach(action => {
      const configIndex = this.config.length - 1 - [...this.config].reverse().findIndex(_ => _.name && (actionNames.indexOf(_.name) <= actionNames.indexOf(action.name)));

      const config = {...this.config[configIndex], ...action};
      const maintainer_information = action.maintainer_information;
      config.date = new Date(action.date).toLocaleString();
      config.status = 2;
      const action_label = ActionEnum[action.name];
      const result_codes = [maintainer_information.result_code, ...maintainer_information.equipments?.reduce((result_codes, equipment) => [...result_codes, equipment['result_code'], ...(equipment.softwares?.map(software => software['result_code']) ?? [])], []) ?? []].filter((v,i,a) => v && !["F1", "F2"].includes(v) && a.indexOf(v) === i)
      let result_labels = "";
      if(result_codes?.length) {
        result_labels = ` (${result_codes.map(result_code => Action.getResultLabel(result_code)).join(" / ")})`;
      }
      const prefix = `${action_label}${result_labels}`;
      config.comment = {
        shipping: `Transporteur : ${maintainer_information?.['carrier_name']} - N° de suivi de colis ${maintainer_information?.['tracking_number']}`
      }[action.name] || maintainer_information.comment || '';
      if(config.comment && prefix) config.comment = ` / ${config.comment}`;
      if(!config.tooltip) config.tooltip = '';
      config.tooltip += `${config.date} : ${prefix}${config.comment}\n\n`;
      if(action.name == "nepting") {
        config.status = 1;
      }
      if(action.name == "cancellation") {
        config.label = Object.keys(Action.getGlobalActionLabel())[0];
        config.status = 0;
        config.color = (config.colors || this.colors)[1];
      }
      this.config[configIndex] = config;
    });

    let nextStatus = null;
    (this.config as any[]).forEach((v, i, a) => {
      if (nextStatus > (v.status ?? 0)) {
        v.status = v.status ?? nextStatus;
        nextStatus = null;
      }
      if (v.status) nextStatus = v.status - 1;
      // if(v.status == 2 && a[i-1]?.status == nextStatus) {
      //   a[i-1].status = v.status;
      //   a[i-1].date = v.date;
      // }
    });
    (this.config as any[]).forEach((v, i, a) => {
      v.status = v.status ?? 0;
      v.color = v.color ?? (v.colors || this.colors)[v.status];
      v.icon = v.icon ?? (v.icons || this.icons)[v.status];
      if (v.status == 1){
        this.value = 100 * i / (a.length - 1);
      }
    });
  }

  canUseAction(config) {
    if(!config.actions) return false;

    if (!(config.roles instanceof Array) || config.roles.length === 0) {
      return true;
    }

    return config.roles.every((role) => this.keycloakService.getUserRoles(true).includes(role));
  }

  getClass(conf) {
    return {['color-' + conf.color]: conf.color};
  }

  getPassedButtonClass(conf) {
    return this.getButtonClass({...conf, status: 1});
  }

  getButtonClass(conf) {
    return [
      [`color-background-card`, `border-color-${conf.color}`, `background-color-${conf.color}`],
      [`color-${conf.color}`, `border-color-${conf.color}`, `background-color-background-card`],
      [`color-background-card`, `border-color-${conf.color}`, `background-color-${conf.color}`]
    ][conf.loading?2:conf.status];
  }

  buttonClick(conf, action) {
    if(action) {
      conf.loading = true;
      this.loading = true;
      action().subscribe({
        next: value => {
          if(conf.status<2) conf.status++;
          setTimeout(_ => window.location.reload(),2000);
        },
        complete: () => {
          this.loading = false;
          conf.loading = false;
        }
      });
    }
  }

  nosort() {}

}
