<mat-card class="main-card mt-3 shadow bnpp-title-shadow p-0" *ngIf="form.controls.length">
    <mat-card-header class="d-flex align-items-center card-form-logiciel bnpp-title">
        <mat-card-title class="color-primary-700">Accessoires recommandés</mat-card-title>
    </mat-card-header>
        <form *ngIf="refAccessories.length">
            <mat-grid-list cols="12" rowHeight="100px" gutterSize="10px">
                <mat-grid-tile colspan="6" *ngFor="let accessory of form.controls; let s=index" [formGroup]="form.controls[s]">
                    <mat-card class="card-list">
                        <img [src]="backendService.backendUrl+'img/accessory/'+accessory.get('id_ref').value+'.png'" onerror="this.src='../../../assets/icons/Accessories.png'" width="50" height="50" width="50" height="50">
                        <ng-container *ngFor="let conf of config">
                            <app-form-field [form]="form.controls[s]" [config]="conf"></app-form-field>
                        </ng-container>
                    </mat-card>
                </mat-grid-tile>
            </mat-grid-list>
        </form>
</mat-card>
