import { Component, Input, OnInit} from '@angular/core';
import { FormUtilsService } from 'src/app/services/form-utils/form-utils.service';
import { Accessory } from 'src/app/utils/model/accessory.model';
import { FormArray, FormGroup } from '@angular/forms';
import { BackendService } from 'src/app/services/backend/backend.service';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-accessories-form',
  templateUrl: './accessories-form.component.html',
  styleUrls: ['./accessories-form.component.scss']
})
export class AccessoriesFormComponent implements OnInit {
  constructor(private formUtilsService: FormUtilsService, private backendService: BackendService) {
  }

  @Input()
  get formArray(): FormArray {
      return this.form;
  }
  set formArray(formArray: FormArray) {
      this.form = formArray;
  }
  form = this.formUtilsService.buildForm([]) as FormArray;

  @Input()
  refAccessories: Partial<Accessory>[] = [];

  @Input()
  config = [
    {
      externalLabel: 'Accessoire',
      formControlName: 'name',
      type: 'label'
    },
    {
      externalLabel: 'Quantité',
      formControlName: 'quantity',
      input: 'number'
    },
    {
      externalLabel: 'Tarif',
      formControlName: 'fare',
      type: 'label',
      class: 'd-flex justify-content-center',
      suffix: ' € HT'
    },
  ];

  ngOnInit(): void {
    this.initRef();
  }

  initRef() {
    this.refAccessories.forEach(accessory => {
      accessory = new Accessory(accessory);
      const accessoryForm = this.formUtilsService.buildForm(accessory) as FormGroup;
      this.config.forEach(conf => {
        const control = accessoryForm.get(conf.formControlName);
        if (accessory[conf.formControlName]) {
          control.disable();
        } else {
          control.valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
            control.setValue(v, {onlySelf: true, emitEvent: false});
          });
        }
      });
      this.form.push(accessoryForm);
    });
    this.form.controls.forEach(accessoryControl => {
        if(accessoryControl.get("id_order").value) this.formUtilsService.toggleForm(accessoryControl, false);
    });
  }
}
