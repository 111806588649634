import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BackendService } from 'src/app/services/backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class ReferentialResolver implements Resolve<any> {
  constructor(private backendService: BackendService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return combineLatest(Object.entries(route.data.resolve).map(([key, json]) => {
      let jsonString = JSON.stringify(json);
      Object.entries(route.params).forEach(([k,v]) => jsonString = jsonString.replace(`:${k}`,v));
      // Object.entries(sessionStorage).forEach(([k,v]) => jsonString = jsonString.replace(`sessionStorage.${k}`,v));
      jsonString = jsonString.replace(/(\\*")sessionStorage\.(.*?)\1/g, (match, $1, $2) => JSON.stringify(sessionStorage.getItem($2)).replace(/^"/, $1).replace(/"$/, $1))
      json = JSON.parse(jsonString, (key: string, value: any) => value ?? undefined);
      return this.backendService.request(json["method"] || 'get', json["url"] || key, json["options"] || {});
    }))
    .pipe(map(values => Object.keys(route.data.resolve).reduce((o, k, i) => ({...o, [k]: values[i]}),{})));
  }

}
