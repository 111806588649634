import { Base } from "../base.model";
import { Builder } from "../builder.model";
import { Customer } from "../customer.model";
import { Merchant } from "./merchant.model";
import { Store } from "./store.model";

export class User extends Base {
    constructor(values: Partial<User> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    user_id: string = null;
    old_user_id: string = null;
    new_user_id: string = null;
    merchant_user_id: string = null;
    merchant: Merchant = new Merchant();
    merchant_id: string = null;
    store: Store = new Store();
    store_id: string = null;
    is_merchant_user_active: boolean = null;
    login: string = null;
    email_address: string = null;
    mail: string = null;
	ref_customer: Customer = new Customer();
}
