import { ComponentPortal, CdkPortalOutletAttachedRef } from '@angular/cdk/portal';
import { AfterViewInit, Component, ComponentRef, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { AmgcaService } from 'src/app/services/amgca/amgca.service';
import { BackendService } from 'src/app/services/backend/backend.service';
import { FormUtilsService } from 'src/app/services/form-utils/form-utils.service';
import { DialogComponent } from 'src/app/utils/dialog/dialog.component';
import { ConfirmDisplayComponent } from 'src/app/utils/display/confirm-display/confirm-display.component';
import { OrderDisplayComponent } from 'src/app/utils/display/order-display/order-display.component';
import { CustomerFormComponent } from 'src/app/utils/form/customer-form/customer-form.component';
import { EquipmentsFormComponent } from 'src/app/utils/form/equipments-form/equipments-form.component';
import { RadioFormComponent } from 'src/app/utils/form/radio-form/radio-form.component';
import { CommunicationType } from 'src/app/utils/model/communication-type.model';
import { Equipment } from 'src/app/utils/model/equipment.model';
import { Order } from 'src/app/utils/model/order.model';
import { Software } from 'src/app/utils/model/software.model';
import {Offer} from '../../../utils/model/offer.model';
@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit, AfterViewInit {

  constructor(private formUtilsService: FormUtilsService, private backendService: BackendService, private router: Router, private route: ActivatedRoute, private amgcaService: AmgcaService, private dialog: MatDialog) {
  }

  @ViewChild(MatStepper) stepper: MatStepper;
  id = this.route.snapshot.params.id;
  navigation_state = this.router.getCurrentNavigation().extras.state;
  offer: Offer = this.backendService.refToEntity(this.route.snapshot.data.resolve.refOffer,true,true);
  refProductRequirements = this.route.snapshot.data.resolve.refProductRequirements;
  refProductIncompatibilities = this.route.snapshot.data.resolve.refProductIncompatibilities;
  inactiveRefEquipments = this.getRefEquipments(this.route.snapshot.data.resolve.inactiveRefEquipments);
  additionalOrder = this.getOrder()?.suborders?.[0]?.additional_order;
  refEquipments = this.backendService.filterKey(this.getRefEquipments(this.route.snapshot.data.resolve.refEquipments), "inactive", false, _ => !this.additionalOrder || !_.ref_softwares);
  form = this.formUtilsService.buildForm(this.buildOrder()) as FormGroup;

  @Input()
  config = {
    Coordonnées: {
      stepControl: this.form.controls.customer,
      button: true,
      component: {
        name: CustomerFormComponent,
        inputs: {
          formGroup: this.form.controls.customer,
          offer: this.offer,
          additionalOrders: this.additionalOrder,
          idMaintainer: this.refEquipments?.[0].ref_fares?.[0].id_maintainer,
        },
        outputs: {
          formGroup: this.form.controls.customer
        }
      }
    },
    // Conseiller: {
    //     completed: this.isFormValid.bind(this),
    //     button: true,
    //     component: {
    //       name: CustomerFormComponent,
    //       inputs: {
    //         formGroup: this.form,
    //         config: {
    //             AGENCE: [
    //                 {
    //                     formControlName: 'suborders.0.id_branch_advisor',
    //                     label: 'Nom de l\'agence',
    //                     required: true,
    //                     maxLength: 50
    //                 },
    //                 {
    //                     formControlName: 'suborders.0.branch_code',
    //                     label: 'Code de l\'agence',
    //                     required: true,
    //                     maxLength: 50
    //                 },
    //                 {
    //                     formControlName: 'user.code',
    //                     label: 'Code de l\'agent',
    //                     required: true,
    //                     maxLength: 50
    //                 },
    //                 {
    //                     formControlName: 'suborders.0.branch_address.address',
    //                     label: 'Adresse de l\'agence',
    //                     required: true,
    //                     maxLength: 64,
    //                     autocomplete: {}
    //                 },
    //                 {
    //                     formControlName: 'suborders.0.branch_address.zip_code',
    //                     label: 'Code Postal',
    //                     required: true,
    //                     pattern: /[0-9]{5}/,
    //                     minLength: 5,
    //                     maxLength: 5,
    //                     autocomplete: {}
    //                 },
    //                 {
    //                     formControlName: 'suborders.0.branch_address.city_name',
    //                     label: 'Ville',
    //                     required: true,
    //                     maxLength: 50,
    //                     autocomplete: {}
    //                 },
    //                 {
    //                     formControlName: 'suborders.0.branch_address.phone',
    //                     label: 'Téléphone',
    //                     type: 'tel',
    //                     required: true,
    //                     pattern: /[0-9]{10}/,
    //                     minLength: 10,
    //                     maxLength: 10,
    //                     validators: [phoneValidator]
    //                 },
    //                 {
    //                     formControlName: 'suborders.0.branch_address.secondary_phone',
    //                     label: 'Fax',
    //                     type: 'fax',
    //                     required: true,
    //                     pattern: /[0-9]{10}/,
    //                     minLength: 10,
    //                     maxLength: 10,
    //                     validators: [phoneValidator]
    //                 },
    //                 {
    //                     formControlName: 'suborders.0.branch_address.email',
    //                     label: 'Email',
    //                     type: 'email',
    //                     required: true,
    //                     pattern: /\S*/,
    //                     maxLength: 80,
    //                 },
    //             ]
    //         }
    //       },
    //       outputs: {
    //         formGroup: this.form
    //       }
    //     }
    // },
    Choix: {
      stepControl: this.form.controls.suborders['controls'][0].controls.equipments,
      component: {
        name: EquipmentsFormComponent,
        inputs: {
          formArray: this.form.controls.suborders['controls'][0].controls.equipments,
          refEquipments: this.refEquipments,
          inactiveRefEquipments: this.inactiveRefEquipments,
          refProductRequirements: this.refProductRequirements,
          refProductIncompatibilities: this.refProductIncompatibilities
        },
        outputs: {
          formArray: this.form.controls.suborders['controls'][0].controls.equipments
        }
      }
    },
    Récapitulatif: {
      stepControl: this.form,
      component: {
        name: OrderDisplayComponent,
        inputs: {
          formGroup: this.form,
          refProductRequirements: this.refProductRequirements,
          refProductIncompatibilities: this.refProductIncompatibilities
        },
        outputs: {
          formGroup: this.form
        }
      }
    },
    Signature: {
      stepControl: this.form,
      component: {
        name: ConfirmDisplayComponent,
        inputs: {
          formGroup: this.form,
          offer: this.offer
        },
        outputs: {
          formGroup: this.form
        }
      }
    }
  };

  getRefEquipments(refEquipments) {
    return this.backendService.additionalOrderFare(this.backendService.refToEntity(refEquipments,true,false), this.getOrder().suborders[0].additional_order)
    .reduce((acc, val) => {
      if (!val.communication_type) {
        val.ref_communication_types = val.ref_communication_types.sort((a,b) => a.communication_type.localeCompare(b.communication_type));
        val.ref_communication_type = new CommunicationType(val.ref_communication_types[0]);
        val.ref_communication_type.id = val.ref_communication_type?.id_ref;
        val.communication_type = val.ref_communication_type?.communication_type;
      }
      acc.push(val);
      return acc;
    }, []);
  }

  isFormValid(index) {
    const config = Object.values(this.config)[index];
    const form = config.component.inputs['formGroup'];
    return (Object.values(config.component.inputs['config']) as any[]).reduce((acc, val) => acc.concat(val), []).every(_ => form.get(_["formControlName"]).valid);
  }

  getOrder() {
    let order: Order;
    if(this.navigation_state) order = new Order(this.navigation_state);
    else if(sessionStorage.getItem('order')) order = new Order(JSON.parse(sessionStorage.getItem('order')));
    else if(sessionStorage.getItem('amgca')) order = this.amgcaService.amgcaToOrder(JSON.parse(sessionStorage.getItem('amgca')));
    else order = new Order();
    return order;
  }

  buildOrder() {
      let order: Order = this.getOrder();
      sessionStorage.setItem('order', JSON.stringify(order));
      order.suborders = order.suborders.reduce((suborders, suborder) => {
            suborder.equipments = suborder.equipments.reduce((equipments, equipment) => {
                const refEquipment = {...this.refEquipments.find(ref => ref.id_ref == equipment.id_ref)};
                Object.keys(refEquipment).forEach(key => {if(key.startsWith("ref_")) equipment[key] = refEquipment[key]});
                Equipment.updateEquipment(equipment);
                equipments.push(equipment);
                return equipments;
            }, []);
            suborders.push(suborder);
            return suborders;
      }, []);
      return order;
  }

  ngOnInit(): void {
    if(sessionStorage.getItem('amgca')) {
        const refSoftwares = this.refEquipments.reduce((acc,val) => [...acc,...val.ref_softwares],[]);
        const amgcaOrder = this.amgcaService.amgcaToOrder(JSON.parse(sessionStorage.getItem('amgca')), refSoftwares);
        const amgcaSoftware = amgcaOrder.suborders[0].equipments[0].softwares[0];
        if(amgcaSoftware && amgcaSoftware.id_ref != refSoftwares.filter(_ => _.type=='included')[0].id_ref) {
            this.router.navigate([".."], {relativeTo: this.route});
        }
        const radioForm = this.formUtilsService.buildForm({
          bool: false
        }) as FormArray;
        this.dialog.open(DialogComponent, {
          disableClose: true,
          data: {
            components: [
              {
                name: RadioFormComponent,
                inputs: {
                  form: radioForm,
                  radioConfig: {
                    [`Si votre contrat monétique ${amgcaSoftware?.contract_reference ?? this.getContractReference()} a été créé : `]: {
                      options: {
                        "aujourd'hui : merci d’effectuer votre commande demain car le contrat monétique ne sera valide que demain": {bool: false},
                        "il y a plus de 24h : pas de risque de rejet de votre commande, merci de poursuivre": {bool: true}
                      }
                    }
                  }
                }
              }
            ],
            buttons: {
              'Poursuivre': true
            }
          }
        }).afterClosed().subscribe(() => {
          if(!radioForm.get("bool").value) this.router.navigate([".."], {relativeTo: this.route});
        })
    }
    this.form.get('customer.contact_comment').valueChanges.subscribe(v => {
      (this.form.get('suborders') as FormArray).controls.forEach(suborder => {
        suborder.get('contact_comment').setValue(v);
      });
    });
    (this.form.get('suborders') as FormArray).controls.forEach(suborder => {
      const equipments = suborder.get('equipments') as FormArray;
      equipments.setValidators([Validators.required]);
      equipments.updateValueAndValidity();
      this.setSubscriptions(equipments);
      equipments.valueChanges.subscribe(_ => {
        this.setSubscriptions(equipments);
      });
    });
    this.form.get('customer.contact_comment').setValue(null);
  }

  setSubscriptions(control: FormArray) {
    const equipments = control.getRawValue();
    const suborder = control.parent;
    ['rental_equipment', 'purchase_equipment'].forEach(k => {
      suborder.get(k + 's_total').setValue(equipments.map(_ => _[k + '_total']).reduce((acc, val) => acc + val, 0));
    });
    ['yearly_maintenance', 'yearly_subscription3g', 'purchase_softwares', 'purchase_services'].forEach(k => {
      suborder.get(k + '_total').setValue(equipments.map(_ => _[k + '_total']).reduce((acc, val) => acc + val, 0));
    });
    suborder.get('equipments_total').setValue(equipments.reduce((acc, val) => acc + val.quantity ?? 1, 0));

    if (equipments && equipments[0]?.softwares?.length) {
      const {id, id_ref, ...software} = ((control.get('0.softwares') as FormArray).getRawValue() as Software[])
      .sort((a,b) => a.id_ref - b.id_ref)
      .sort((a,b) => {const types = ['included','purchase'];return types.indexOf(a.type) - types.indexOf(b.type)})[0];
      suborder.get('contract_emoney').patchValue(software);
    }
  }

  ngAfterViewInit() {
    this.stepper.selectionChange.subscribe(event => {
      if(event.selectedIndex==2 || event.previouslySelectedIndex==2) {
        this.stepper.steps.get(1).editable = false;
        this.stepper.steps.get(3).editable = false;
      }
      if(event.selectedIndex==3) {
        if(this.form.get('id').value) this.stepper.steps.forEach(step => step.editable = false);
        else this.stepper.previous();
      }
    });
    Object.keys(this.config).forEach(k => {
      if (this.config[k].component) {
        this.config[k].portal = new ComponentPortal(this.config[k].component.name);
      }
    });
    (this.form.get('suborders') as FormArray).controls.forEach(suborder => {
      suborder.patchValue({ id_offer: this.offer.id || this.offer.id_ref, offer_name: this.offer.name });
    });
  }

  attached(ref: CdkPortalOutletAttachedRef, index) {
    if (ref instanceof ComponentRef) {
      Object.keys(this.config[index].component.inputs || {}).forEach(k => {
        ref.instance[k] = this.config[index].component.inputs[k];
      });
      Object.keys(this.config[index].component.outputs || {}).forEach(k => {
        if (ref.instance[k] instanceof EventEmitter) {
          ref.instance[k].subscribe(this.config[index].component.outputs[k]);
        } else {
          this.config[index].component.outputs[k] = ref.instance[k];
        }
      });
      ref.instance.stepper = this.stepper;
    }
  }

  getStepColor(index) {
    if (this.stepper) {
      if (this.stepper.selectedIndex == index) {
        return 'accent';
      }
      const step = this.stepper.steps.get(index);
      if (step.hasError) {
        return 'warn';
      }
      if (step.completed) {
        return 'primary';
      }
    }
    return null;
  }

  getContractReference(): string {
    return sessionStorage.getItem('contractReference');
  }

  nosort() { }

}
