import { Base } from "../base.model";
import { Builder } from "../builder.model";
import { Customer } from "../customer.model";
import { User } from "./user.model";
import { Application } from "./application.model";
import { Merchant } from "./merchant.model";
import { Terminal } from "./terminal.model";

export class Store extends Base {
    constructor(values: Partial<Store> = {}) {
        super(values);
        Builder.build.bind(this)(values,{
            user: _ => new User(_),
            terminal: _ => new Terminal(_),
            application: _ => new Application(_)
        });
    }

    store_id: string = null;
    merchant: Merchant = new Merchant();
    merchant_id: string = null;
    user: User[] = [];
    terminal: Terminal[] = [];
    application: Application[] = [];
    store_label: string = null;
    store_title: string = null;
    store_category_code: string = null;
    store_siret: string = null;
    store_country_code: string = null;
    store_address: string = null;
    store_city: string = null;
    store_zip_code: string = null;
    offline_mode: number = null;
    offline_max_trs: number = null;
    offline_max_amount: number = null;
    iso_id: string = null;
    facilitator_id: string = null;
    facilitator_alias: string = null;
    sub_merchant_id: string = null;
    detail: string = null;
    status: boolean = null;
	ref_customer: Customer = new Customer();
}
