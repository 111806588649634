import { CdkPortalOutletAttachedRef, ComponentPortal, Portal } from '@angular/cdk/portal';
import { AfterViewInit, Component, ComponentRef, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements AfterViewInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
  }

  portals: Portal<any>[] = [];

  ngAfterViewInit() {
    (this.data.components || []).forEach(component => {
      this.portals.push(new ComponentPortal(component.name));
    });
  }

  attached(ref: CdkPortalOutletAttachedRef, index) {
    if (ref instanceof ComponentRef) {
      Object.keys(this.data.components[index].inputs || {}).forEach(k => {
        ref.instance[k] = this.data.components[index].inputs[k];
      });
      Object.keys(this.data.components[index].outputs || {}).forEach(k => {
        if (ref.instance[k] instanceof EventEmitter) {
          ref.instance[k].subscribe(this.data.components[index].outputs[k]);
        } else {
          this.data.components[index].outputs[k] = ref.instance[k];
        }
      });
    }
  }

  close(value) {
    if (typeof value === "function") return value();
    return value;
  }

  nosort() {}

}
