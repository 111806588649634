import { Base } from "../base.model";
import { Builder } from "../builder.model";
import { Software } from "../software.model";
import { Merchant } from "./merchant.model";
import { Store } from "./store.model";

export class Application extends Base {
    constructor(values: Partial<Application> = {}) {
        super(values);
        Builder.build.bind(this)(values,{
            softwares: _ => new Software(_)
        });
    }

    application_id: string = null;
    merchant: Merchant = new Merchant();
    merchant_id: string = null;
    store: Store = new Store();
    store_id: string = null;
    is_merchant_active: boolean = null;
    host_merchant_name: string = null;
    reason: string = null;
    status: boolean = null;
    detail: string = null;
    application_type: "CB EMV" | "CB NFC" | "CB EMV ANDROID" | "CB NFC ANDROID" | "CB PLBS" | "CONECS" | "CONECS NFC" | "AMEX" | "UPI" | "NxCB CA" | "CB VAD" | "CB VADS" | "DCC" | "CB NFC FRV6" | "TRDB EDENRED" | "TRDB MONIZZE" | "TRDB SODEXO" | "CB SOFTPOS FRV6" = null;
    merchant_contract_id: string = null;
    parameter: {name: string, value: string}[] = [];
    softwares: Software[] = [];
}
