<mat-toolbar *ngIf="getAmgca() && !isOfferDisabled()" class="d-flex justify-content-center align-items-center">
    Vous pouvez ajouter un matériel {{!isOfferDisabled(true)?'ou autre service':''}} uniquement pour le contrat {{getContractReference()}}
</mat-toolbar>
<mat-grid-list cols="12" rowHeight="1:4" [gutterSize]="30" class="ml-5 mr-5" *ngFor="let offer of offers">
    <mat-grid-tile colspan="2"></mat-grid-tile>
    <mat-grid-tile colspan="4">
        <mat-card class="shadow bnpp-title-shadow p-0">
            <mat-card-header class="d-flex justify-content-between bnpp-title">
                <mat-card-title class="color-primary-700">Matériels {{!getIdOffer()?offer.name:''}}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="d-flex flex-column align-items-center justify-content-center p-1">
                <img [ngClass]="{'w-50 h-50 pb-2': offer.id_ref==3, 'w-75': offer.id_ref!=3}" [src]="backendService.backendUrl+'img/offer/'+offer.id_ref+'_'+router.routerState.snapshot.url.split('/')[1]+'.png'" (error)="onImgError($event, offer)">
                <img *ngIf="offer.id_ref==3" src="../../../assets/icons/materiels.png" height="120">
            </mat-card-content>
            <mat-card-actions class="d-flex flex-column align-items-center pt-0">
                <button class="w-50 mb-4 background-color-primary-50 opposite-color-foreground-base" mat-raised-button color="primary" (click)="chooseOffer(offer,false)" [disabled]="isOfferDisabled(false)">
                    <mat-icon>shopping_cart</mat-icon>
                    Nouvelle commande
                </button>
            </mat-card-actions>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile colspan="4" *ngIf="offer.additional_order">
        <mat-card class="shadow bnpp-title-shadow p-0">
            <mat-card-header class="d-flex justify-content-between bnpp-title">
                <mat-card-title class="color-primary-700">Autres services {{!getIdOffer()?offer.name:''}}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="d-flex flex-column align-items-center justify-content-center p-1">
                <img class="w-50 h-50 pb-2" [src]="backendService.backendUrl+'img/offer/'+offer.id_ref+'_'+router.routerState.snapshot.url.split('/')[1]+'.png'" (error)="onImgError($event, offer)">
                <img src="../../../assets/icons/services.png" height="120">
            </mat-card-content>
            <mat-card-actions class="d-flex flex-column align-items-center pt-0">
                <button class="w-50 mb-4 background-color-primary-50 opposite-color-foreground-base" mat-raised-button color="primary" (click)="chooseOffer(offer,true)" [disabled]="isOfferDisabled(true)" *ngIf="additionalOrder">
                    <mat-icon>shopping_cart</mat-icon>
                    Ajouter
                </button>
            </mat-card-actions>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile colspan="2"></mat-grid-tile>
</mat-grid-list>
<div class="d-flex justify-content-center" *ngIf="getAmgca() && state.customer.id_ref"><a class="return-link color-primary" (click)="displayEquipments(state.customer.id_ref?getQueryParams():{})">Voir le parc actif du commerçant : {{getAmgcaOrder().customer.store_name || getAmgcaOrder().customer.company_name}}</a></div>
