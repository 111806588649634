<div class="equipment d-flex">
  <div class="row w-100 d-flex justify-content-between p-0">
    <div class="col-auto d-flex flex-column">
      <h3 class="color-primary-700 m-0 mb-3">{{getName(equipment)}}</h3>
      <div class="row w-100 d-flex justify-content-between">
        <div class="col-5">
          <div class="">{{equipment.installation_type}}</div>
          <div class="p-3">
            <img [src]="backendService.backendUrl+'img/equipment/'+equipment.id_ref+'.png'"
                 onerror="this.src='../../../assets/icons/TPE.png'"
                 height="100">
          </div>
        </div>
        <div class="col-7">
          <div class="d-flex">
            <div *ngFor="let ref of equipment.ref_communication_types" class="p-2">
              <mat-icon class="color-warn-A100">{{iconMapping[ref.communication_type]}}</mat-icon>
              {{ref.communication_type}}
            </div>
          </div>
          <div class="description p-3" [innerHTML]="equipment.description"></div>
        </div>
      </div>
    </div>
    <div class="col" *ngIf="false">
      <div class="d-flex col-12 p-3">
        <div *ngFor="let installation_mode_label of map | keys;let index=index" class="col box">
          <mat-card class="d-flex flex-column p-0 ">
            <mat-card-header
              class="d-flex align-items-center card-form-logiciel bnpp-title">
              <mat-card-title class="m-0 ml-2"><h3 class="m-0">
                {{installation_mode_label}}</h3></mat-card-title>
            </mat-card-header>
            <mat-card-content class="d-flex flex-column justify-content-between">
              <div class="p-2 d-flex justify-content-between"
                   *ngFor="let contract_type_label of map[installation_mode_label] | keys">
                <div><b>{{contract_type_label}} </b></div>
                <div>{{map[installation_mode_label][contract_type_label] | currency}}</div>
              </div>
            </mat-card-content>
          </mat-card>
  
        </div>
      </div>
      <mat-card class="d-flex flex-column p-3 col-12">
        <mat-card-header
          class="d-flex align-items-center card-form-logiciel bnpp-title">
          <mat-card-title class="m-0 ml-2"><h3 class="m-0">Accessoires recommandés</h3></mat-card-title>
        </mat-card-header>
        <mat-card-content class="d-flex flex-column justify-content-between">
          <div class="p-2 d-flex justify-content-between" *ngFor="let ref_accessory of equipment.ref_accessories">
            <div>
              <img [src]="backendService.backendUrl+'img/accessory/'+ref_accessory.id+'.png'"
                   onerror="this.src='../../../assets/icons/Accessories.png'" height="25">
              <b>{{ref_accessory.name}} </b>
            </div>
            <div>{{ref_accessory.fare  | currency}}</div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
