<div class="d-flex row justify-content-between align-items-center py-4 title-box">
    <h2 class="col-auto m-0 color-primary-700">FICHE POINT DE VENTE</h2>
    <a class="return-link color-accent" routerLink="/orders" [state]="getOrdersNavigationState()">Accès aux commandes de mon client</a>
    <a class="return-link color-accent" routerLink="..">&lt; Retour à la liste des clients</a>
</div>

<div class="d-flex row justify-content-between align-items-center justify-content-between mt-4 subtitle-box">
    <span class="color-primary-700">{{customer.store_name}}</span>
    <button *ngIf="allowNewOrder() && form.get('id_ref').value" mat-raised-button class="background-color-primary-50 opposite-color-foreground-base" (click)="navigate()">Nouvelle commande</button>
</div>
<app-customer-display [(formGroup)]="form" [userInfo]="true"></app-customer-display>
<mat-tab-group class="mt-5 mb-5" [(selectedIndex)]="selectedIndex" animationDuration="0">
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="pr-2">point_of_sale</mat-icon>
            Parc & logiciels installés
        </ng-template>   
        <div class="p-1">
            <app-customer-equipments-display [(formArray)]="form.controls.orders" [actions]="actions" [additional_order]="false" (equipmentClick)="navigate($event.order, $event.equipment)" empty_label="Aucune donnée disponible, merci de vérifier l'avancement de votre commande"></app-customer-equipments-display>
        </div>      
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="pr-2">euro</mat-icon>
            Règlements
        </ng-template>
        <div class="p-1">
            <app-customer-equipments-display></app-customer-equipments-display>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="pr-2">shopping_cart</mat-icon>
            Autre(s) service(s)
        </ng-template>
        <div class="p-1">
            <app-customer-equipments-display [(formArray)]="form.controls.orders" [actions]="actions" [additional_order]="true" (equipmentClick)="navigate($event.order, $event.equipment)" ></app-customer-equipments-display>
        </div> 
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label>
            <mat-icon class="pr-2">account_circle</mat-icon>
            Informations du commerçant
        </ng-template>
        <div class="p-4">
            <app-customer-form [(formGroup)]="form" [toggles]="toggles"></app-customer-form>
            <div class="d-flex justify-content-center">
                <button mat-raised-button color="primary" type="submit" (click)="postCustomerUpdate()" >Sauvegarder</button>
            </div>
        </div>
    </mat-tab>
    <mat-tab *ngIf="displayNepting()">
        <ng-template mat-tab-label>
            <mat-icon class="pr-2">account_circle</mat-icon>
            Gestion Nepting
        </ng-template>
        <div class="p-1">
            <app-nepting-display [id]="id" [refOffers]="refOffers"></app-nepting-display>
        </div>
    </mat-tab>
</mat-tab-group>
