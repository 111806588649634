import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BackendService } from 'src/app/services/backend/backend.service';
import { FormUtilsService } from 'src/app/services/form-utils/form-utils.service';
import { CerteuropeOrder } from '../../model/certeurope/certeurope-order.model';

@Component({
  selector: 'app-certeurope-display',
  templateUrl: './certeurope-display.component.html',
  styleUrls: ['./certeurope-display.component.scss']
})
export class CerteuropeDisplayComponent implements OnInit {

  constructor(private formUtilsService: FormUtilsService, private route: ActivatedRoute, private backendService: BackendService) { }
  idOrder = this.route.snapshot.params?.idOrder;
  hash = this.route.snapshot.params?.hash;
  certeuropeOrder: CerteuropeOrder = this.route.snapshot.data.resolve?.order;
  order = this.certeuropeOrder?.order;
  orderForm = !this.order ? null : this.formUtilsService.buildForm(this.order) as FormGroup;
  form = this.formUtilsService.buildForm({
    cgu: false,
    otp: null
  }) as FormGroup;

  error = null;
  message = null;

  otpConfig = {
    formControlName: 'otp',
    required: true,
    pattern: /[0-9]{6}/,
    minLength: 6,
    maxLength: 6,
    error: () => 'Le code contient 6 chiffres'
  }

  ngOnInit(): void {
    const offer_name = this.order?.suborders?.[0]?.offer_name || 'Mon TPE';
    if(this.order) {
      this.orderForm.disable();
      if(this.order.inactive) this.message = this.message || `La commande a été supprimée! Merci de recontacter l’équipe ${offer_name} si vous souhaitez passer une nouvelle commande.`;
    }
    if(this.certeuropeOrder){
      if(this.certeuropeOrder.signatures?.every(signature => signature.status=="SIGNED")) this.message = this.message || `<div>Signature éléctronique de votre/vos document(s) contractuel(s) réalisée avec succès.<br>
      Le(s) document(s) signé(s) vous a/ont été adressé(s) en pièce(s)-jointe(s) par email à <b>${this.certeuropeOrder.holder['email']}</b>.</div>`;
      if(this.certeuropeOrder.inactive) this.message = this.message || `Ce lien n'est plus valide, merci d'utiliser le lien dans le dernier courriel que vous avez reçu ou de vous rapprocher de l'équipe ${offer_name} afin de recevoir un nouveau courriel.`;
      if((new Date().getTime() - this.certeuropeOrder.created_date.getTime()) > 24*60*60*1000) this.message = this.message || `Ce lien n'est plus valide (dépassement de 24h), merci de vous rapprocher de l'équipe ${offer_name} afin de recevoir un nouveau courriel.`;
    }
    if(!this.certeuropeOrder) this.message = this.message || `Ce lien n'est plus valide, merci de vous rapprocher de l'équipe ${offer_name}.`;
  }

  validate() {
    if(!this.form.get('cgu').value) return;
    this.form.get('cgu').disable();
    this.error = null;
    return this.backendService.requestWithoutCatchError('get', `inactive/certeurope/validate/${this.idOrder}/${this.hash}`).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error(err);
        this.error = JSON.parse(err.error['detail'])?.errorMsg || err.error['detail'];
        this.form.get('cgu').enable();
        return EMPTY;
      })
    ).subscribe(order => {this.certeuropeOrder = order;this.ngOnInit();});
  }

  sign() {
    if(!this.form.get('otp').valid) return;
    this.form.get('otp').disable();
    this.error = null;
    return this.backendService.requestWithoutCatchError('get', `inactive/certeurope/sign/${this.idOrder}/${this.hash}/${this.form.get('otp').value}`).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error(err);
        this.error = JSON.parse(err.error['detail'])?.errorMsg || err.error['detail'];
        this.form.get('otp').enable();
        return EMPTY;
      })
    ).subscribe(order => {this.certeuropeOrder = order;this.ngOnInit();});
  }

}
