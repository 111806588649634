<ng-container *ngFor="let conf of config">
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <ng-container *ngFor="let bool of [true, false]">
        <button *ngIf="conf.actions[bool] && filter(conf.key, conf.bool, bool).length" mat-raised-button
                class="background-color-primary-50 opposite-color-foreground-base"
                (click)="batchAction(conf.actions[bool], filter(conf.key, conf.bool, bool))"
                [attr.data-action]="conf.actions[bool]">
          {{ getOppositeLabel(bool) }} tous les {{ conf.label.toLowerCase() }}
        </button>
      </ng-container>
    </div>
    <div class="d-flex">
      <ng-container *ngFor="let merchant of merchants">
        <button *ngIf="conf.create && conf.create.activated(merchant)" mat-raised-button
                class="background-color-primary-50 opposite-color-foreground-base"
                (click)="create(conf.create, merchant)" [attr.data-action]="conf.create.action">
          Ajouter un {{ conf.label.toLowerCase().slice(0, -1) }} pour le marchand {{ merchant.merchant_id }}
        </button>
      </ng-container>
    </div>
  </div>
  <mat-expansion-panel class="my-3 shadow bnpp-title-shadow" [expanded]="true">
    <mat-expansion-panel-header class="bnpp-title">
      <mat-panel-title class="color-primary-700">{{ conf.label }}</mat-panel-title>
      <mat-panel-description class="justify-content-end">
      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-container *ngFor="let merchant of merchants">
      <div class="d-flex flex-column pb-3" *ngIf="merchant[conf.key].length">
        <div *ngIf="conf.headers" class="d-flex col justify-content-between">
          <div *ngFor="let v of conf.headers; let i=index" class="col p-0"
               [class.text-center]="i>0 && i<conf.values.length-1"
               [class.text-right]="i!==0 && i===conf.values.length-1">
            <p class="header">{{ v }}</p>
          </div>
          <div class="d-flex col-2 justify-content-between flex-row-reverse"><p class="header">Statut</p></div>
        </div>
        <div class="d-flex py-1" *ngFor="let value of merchant[conf.key]">
          <div class="d-flex col justify-content-between">
            <div *ngFor="let v of conf.values; let i=index" class="col p-0"
                 [class.text-center]="i>0 && i<conf.values.length-1"
                 [class.text-right]="i!==0 && i===conf.values.length-1">{{ getValue(value, v) }}
            </div>
          </div>
          <div class="d-flex col-2 justify-content-between flex-row-reverse">
            <ng-container *ngFor="let bool of [null, !!value[conf.bool]]">
              <div *ngIf="conf.actions[bool]" class="clickable"
                   (click)="action(conf.actions[bool], merge(merchant, value))" [attr.data-action]="conf.actions[bool]"
                   [class.color-warn]="bool===false">{{ bool === false ? 'Désactivé' : getOppositeLabel(bool) }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <div *ngIf="noData(conf.key)" class="pb-3 d-flex justify-content-center align-items-center">
      Aucune donnée disponible actuellement
    </div>
  </mat-expansion-panel>
</ng-container>
<div class="overlay d-flex justify-content-center" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
