import { Product } from "./product.model";
import { Builder } from "./builder.model";

export class Intervention extends Product {
    constructor(values: Partial<Intervention> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

}
