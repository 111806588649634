import { formatDate } from '@angular/common';
import { Action } from './action.model';
import { Address } from './address.model';
import { Base } from './base.model';
import { Builder } from "./builder.model";
import { ContractEMoney } from './contract-e-money.model';
import { Contract } from './contract.model';
import { Customer } from './customer.model';
import { Equipment } from './equipment.model';


export class Suborder extends Base {
    constructor(values: Partial<Suborder> = {}) {
        super(values);
        Builder.build.bind(this)(values,{
            equipments: _ => new Equipment(_),
            contracts: _ => new Contract(_)
        });
    }

    id_order: number = null;
    id_offer: number = null;
    offer_name: string = null;
    contact_comment: string = null;
    bnppsm_order_number: string = null;
    contract_duration: number = null;
    contract_type: string = null;
    installation_mode: string = null;
    additional_order: boolean = false;
    contract_emoney: ContractEMoney = new ContractEMoney();
    id_maintainer: string = null;
    bnppsm_intracommunity_vat = 'FR31906050299';
    equipments_total: number = null;
    rental_equipments_total: number = null;
    purchase_equipments_total: number = null;
    purchase_services_total: number = null;
    purchase_softwares_total: number = null;
    yearly_maintenance_total: number = null;
    yearly_subscription3g_total: number = null;
    applicable_vat = 20;
    equipments: Equipment[] = [];
    customer: Customer = new Customer();
    id_branch_advisor: string = null;
    branch_code: string = null;
    bnpp_territory_code: string = null;
    bnpp_territory_name: string = null;
    bnpp_group_code: string = null;
    bnpp_group_name: string = null;
    bnpp_area_code: string = null;
    bnpp_area_name: string = null;
    branch_address: Address = new Address();
    action_name: string = null;
    action_label: string = null;

    contracts: Contract[] = [];
}
