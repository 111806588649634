import { Builder } from "../builder.model";

export class BankAccount {
    constructor(values: Partial<BankAccount> = {}) {
        Builder.build.bind(this)(values);
    }

    bic: string = null;
    iban: string = null;
    bank: string = null;
}
