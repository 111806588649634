<form [formGroup]="form">
  <ng-container *ngFor="let conf of config | keyvalue :nosort">
    <ng-container *ngIf="conf.value[0]?.display !== false">
      <mat-expansion-panel class="my-3 shadow bnpp-title-shadow" [expanded]="!conf.value[0].form">
        <mat-expansion-panel-header class="bnpp-title">
          <mat-panel-title class="color-primary-700">{{conf.key}}</mat-panel-title>
          <mat-panel-description class="justify-content-end">
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-grid-list cols="12" rowHeight="80px" gutterSize="0px" class="ml-4 mr-4">
          <ng-container *ngFor="let control of conf.value">
            <mat-grid-tile [colspan]="control.colspan || 4" [rowspan]="control.rowspan || 1">
              <app-form-field [form]="control.form || form" [config]="control" class="mx-1"></app-form-field>
            </mat-grid-tile>
          </ng-container>
        </mat-grid-list>
      </mat-expansion-panel>
    </ng-container>
  </ng-container>
</form>
