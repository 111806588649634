import { ActionEnum } from "./action.enum";
import { Base } from "./base.model";
import { Builder } from "./builder.model";
import { Equipment } from "./equipment.model";

export type MaintainerInformation = {
    result?: boolean,
    result_code?: string,
    comment?: string,
    equipments?: Equipment[]
};

export class Action extends Base {
    constructor(values: Partial<Action> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    id: number = null;
    name: string = null;
    date: Date = null;
    sent: Date = null;
    id_offer: number = null;
    id_order: number = null;
    id_maintainer: string = null;
    maintainer_information: MaintainerInformation = {};

    static sort(actions: Action[],asc=true) {
        const actionNames = Object.keys(ActionEnum);
        return actions.sort((a, b) => (asc?1:-1)*((actionNames.indexOf(a.name) - actionNames.indexOf(b.name)) || (a.date.toString().localeCompare(b.date.toString()))));
    }

    static getResultLabel(resultCode: string = null) {
        const results = {
            "F4": 'Location',
            "BT": 'Vente',
            "AT": 'Maintenance',
            "2X": 'Prestation',
            "AX": 'Accessoires',
            "63": 'Logiciels',
            "4E": 'Indemnités',
            "4L": 'Administratif',
            "LB": 'Abonnement(3G)',

            "F1": 'Terminée (complète)',
            "F2": 'Terminée (partielle)',

            "C1": 'A la demande du client (Appel qualité)',
            "C2": 'Mainteneur pendant la préparation / Initialisation du matériel',
            "C3": 'A la demande de l\'émetteur (Avant et pendant la préparation)',
            "C4": 'A la demande de BNPP (Avant et pendant la préparation)',
            "C5": 'Mainteneur sur site à la prise de RDV',
            "C6": 'A l\'installation sur site (refus du client)',
            "C7": 'Suite à 3 appels en échec (1 mois sans réponse)',

            "R1": 'Résiliation anticipée',
            "R2": 'Résiliation à échéance',
            "R3": 'Changement de gamme',
            "R4": 'Résiliation suite impayés',
            "R5": 'Avarie',
            "R6": 'Vandalisme',
            "R7": 'Sinistre',
            "R8": 'Rétractation',

            "1R": 'Résiliation suite impayés',
            "2R": 'Vol',
            "3R": 'Perte',
            "4R": 'Avarie',
            "5R": 'Vandalisme',
            "6R": 'Sinistre',
        }
        if(resultCode) return results[resultCode];
        return results;
    }

    static getActionsColors() {
        return ['#B36CBD', '#2491ee', '#ff9000', '#ff9000', '#ff9000', '#ee5842', '#ee5842'];
    }

    static getActionLabel(action) {
        return ActionEnum[action.action_name] || 'Inconnu';
    }
    
    static getGlobalActionLabel(action = null) {
        const global_action_labels = {
            'Annulée': ["cancellation"],
            'Terminée': ["order_end","purchase_maintenance_end","purchase_sim3g_end","equipment_no_return","equipment_return"],
            'Livrée': ["delivery","execution"],
            'Installée': ["installation_done"],
            'En cours de traitement': ["nepting","order_ack","handling","quality_call","preparation_start","preparation_end","shipping","installation_appointment"],
            'Signé': ["sign"],
            'En attente de signature': [null, "order"],
        }
        if(!action) return global_action_labels;
        if(action instanceof String) action = {action_name: action};
        const found = Object.entries(global_action_labels).find(([k,v])=>v.includes(action.action_name ?? null));
        return found ? found[0] : null;
    }

    getActionLabel() {
        return Action.getActionLabel(this);
    }

    getGlobalActionLabel() {
        return Action.getGlobalActionLabel(this);
    }

}
