import { Base } from "./base.model";
import { Builder } from "./builder.model";
import { Equipment } from "./equipment.model";
import { Product } from "./product.model";

export class RefProductRequirement extends Base {
    constructor(values: Partial<RefProductRequirement> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    product_id: number = null;
	product_type: string = null;
	equipment_id: number = null;
	accessory_id: number = null;
	intervention_id: number = null;
	peripheral_id: number = null;
	service_id: number = null;
	software_id: number = null;

    static matchesRequirement(product: Product, equipments: Equipment[], requirements: RefProductRequirement[]) {
        return RefProductRequirement.getRequirements(product, requirements)
        .map(requirement => {
            const {product_id, equipment_id, ...products_ids} = requirement;
            return {
                id_ref: equipment_id,
                ...Object.entries(products_ids).filter(([k,v]) => k.endsWith('_id'))
                .reduce((acc,[k,v]) => ({...acc, [(k.replace(/_id$/,'')+"s").replace("ys","ies")]: [{id_ref: v}]}),{})
            };
        }).filter(required_equipment => {
            return !equipments.some(equipment => {
                const {id_ref, ...required_products_list} = required_equipment;
                return (!id_ref || id_ref==equipment.id_ref) &&
                Object.entries(required_products_list).every(([key,required_products]) => {
                    return required_products.every(required_product => !required_product.id_ref || equipment[key].some(p => p.id_ref == required_product.id_ref));
                });
            });
        });
    }

    static getRequirements(product: Product, requirements: RefProductRequirement[]) {
        const product_type = product.constructor.name.toLowerCase();
        return requirements.filter(requirement => 
            requirement.product_id == product.id_ref && 
            requirement.product_type == product_type
        );
    }

    static getRequired(product: Product, requirements: RefProductRequirement[]) {
        const product_type = product.constructor.name.toLowerCase();
        return requirements.filter(requirement => 
            requirement[`${product_type}_id`] == product.id_ref && 
            requirement.product_type == product_type
        );
    }
}
