import {Component, Inject, Input, LOCALE_ID, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Customer} from 'src/app/utils/model/customer.model';
import {FormUtilsService} from 'src/app/services/form-utils/form-utils.service';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-customer-display',
  templateUrl: './customer-display.component.html',
  styleUrls: ['./customer-display.component.scss']
})
export class CustomerDisplayComponent implements OnInit {

  constructor(@Inject(LOCALE_ID) public locale: string, private formUtilsService: FormUtilsService) {
  }

  @Input()
  get formGroup(): FormGroup {
    return this.form;
  }

  set formGroup(formGroup: FormGroup) {
    this.form = formGroup;
  }

  form = this.formUtilsService.buildForm(new Customer()) as FormGroup;

  @Input()
  userInfo: boolean;

  @Input()
  config = [
    {
      'POINT DE VENTE': [
        {
          key: 'SIRET',
          value: ['store_registration_number']
        },
        {
          key: 'Nom de l\'enseigne',
          value: ['store_name']
        },
        {
          key: 'Adresse',
          value: ['store_address.address']
        },
        {
          key: 'Code postal',
          value: ['store_address.zip_code']
        },
        {
          key: 'Ville',
          value: ['store_address.city_name']
        }
      ],
      SOCIÉTÉ: [
        {
          key: 'SIREN',
          value: ['company_registration_number']
        },
        {
          key: 'Dénomination sociale',
          value: ['company_name']
        },
        {
          key: 'Code NAF',
          value: ['french_naf_code', 'french_naf_label'],
          separator: ' - '
        },
        {
          key: 'Code MCC',
          value: ['mcc']
        },
      ],
      'CONTACT SOCIETE': [
        {
          key: 'Nom',
          value: ['fiscal_address.name']
        },
        {
          key: 'Email',
          value: ['fiscal_address.email']
        },
        {
          key: 'Téléphone portable',
          value: ['billing_address.phone']
        },
        {
          key: 'Téléphone fixe',
          value: ['fiscal_address.phone']
        },
      ]
    },
    {
      'ADRESSE DE FACTURATION': [
        {
          value: ['billing_address.address']
        },
        {
          value: ['billing_address.zip_code', 'billing_address.city_name']
        }
      ],
      'ADRESSE DE LIVRAISON': [
        {
          value: ['delivery_address.address']
        },
        {
          value: ['delivery_address.zip_code', 'delivery_address.city_name']
        }
      ],
      'CONTACT LIVRAISON & INSTALLATION': [
        {
          key: 'Nom',
          value: ['delivery_address.name']
        },
        {
          key: 'Email',
          value: ['delivery_address.email']
        },
        {
          key: 'Téléphone',
          value: ['delivery_address.phone']
        },
      ],
    //   'ADRESSE FISCALE': [
    //     {
    //       value: ['fiscal_address.address']
    //     },
    //     {
    //       value: ['fiscal_address.zip_code', 'fiscal_address.city_name']
    //     }
    //   ],
      'AUTRES INFORMATIONS': [
        {
          key: 'Matériel(s) en service',
          value: ['equipments_total']
        },
        {
          key: 'Signature du contrat',
          value: [],
          mapping: _ => _?formatDate(_, "dd-MM-yyyy", this.locale):_
        },
        {
          key: 'Signature du mandat SEPA',
          value: [],
          mapping: _ => _?formatDate(_, "dd-MM-yyyy", this.locale):_
        }
      ]
    }
  ];

  iconMapping = {
    'POINT DE VENTE': 'suivi-commande:societe-vert-picto',
    SOCIÉTÉ: 'suivi-commande:societe-vert-picto',
    'CONTACT LIVRAISON & INSTALLATION': 'suivi-commande:contact-vert-picto',
    'CONTACT SOCIETE': 'suivi-commande:contact-vert-picto',
    'ADRESSE DE FACTURATION': 'suivi-commande:adresse-facturation-vert-picto',
    'ADRESSE DE LIVRAISON': 'suivi-commande:adresse-livraison-vert-picto',
    'ADRESSE FISCALE': 'suivi-commande:adresse-facturation-vert-picto',
    'AUTRES INFORMATIONS': 'suivi-commande:societe-vert-picto'
  };

  nosort() {
  }

  ngOnInit(): void {
    if (!this.userInfo) {
      delete this.config[1]['AUTRES INFORMATIONS'];
    } else {
      const dateKey = 'updated_date';
      this.form.get('orders').value.forEach((order, o) => {
        order.suborders.forEach((suborder, s) => {
          const contractConf = this.config[1]['AUTRES INFORMATIONS'].find(_ => _.key == 'Signature du contrat');
          const sepaConf = this.config[1]['AUTRES INFORMATIONS'].find(_ => _.key == 'Signature du mandat SEPA');
          suborder.contracts.forEach((contract, c) => {
            const conf = contract.type == 'sepa' ? sepaConf : contractConf;
            if (!conf.value[0] || this.form.get(conf.value[0]) < contract[dateKey]) {
              conf.value[0] = ['orders', o, 'suborders', s, 'contracts', c, dateKey].join('.');
            }
          });
          if(!sepaConf.value[0]) sepaConf.value = contractConf.value;
        })
      })
    }

  }

  displayValue(values: string[], separator = ' ') {
    return values.map(_ => this.form.get(_)?.value ?? '').join(separator);
  }

}
