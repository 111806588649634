import { Builder } from "../builder.model";

export class Software {
    constructor(values: Partial<Software> = {}) {
        Builder.build.bind(this)(values);
    }

    name: string = null;
    contractReference: string = null;
}
