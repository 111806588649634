export class Builder {
    static build(values: any = {}, object: {[k:string]:(v: any) => any} = {}) {
        Object.entries(values).forEach(([k, v]) => {
            if (v != null && (this[k] !== undefined || this.constructor.name == 'Object')) {
                if(Array.isArray(v) && Array.isArray(this[k])) {
                    v.forEach((value, index) => {
                        if(object && typeof object[k] === 'function') {
                            value = object[k](value);
                        }
                        if(!this[k][index]) {
                            this[k][index] = value;
                        } else {
                            Builder.build.bind(this[k][index])(value)
                        }
                    });
                } else if(typeof v === 'object' && v.toString() === '[object Object]' && typeof this[k] === 'object' && this[k]?.toString() === '[object Object]') {
                    Builder.build.bind(this[k])(v);
                } else {
                    this[k] = v;
                }
            }
        });
    }
}
