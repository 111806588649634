import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {EMPTY, Subscription} from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BackendService } from 'src/app/services/backend/backend.service';
import { FormUtilsService } from 'src/app/services/form-utils/form-utils.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { CerteuropeOrder } from '../../model/certeurope/certeurope-order.model';
import { Order } from '../../model/order.model';

@Component({
  selector: 'app-confirm-display',
  templateUrl: './confirm-display.component.html',
  styleUrls: ['./confirm-display.component.scss']
})
export class ConfirmDisplayComponent implements OnInit {

  constructor(private backendService: BackendService, private formUtilsService: FormUtilsService, private dialog: MatDialog) { }

  @Input()
  get formGroup(): FormGroup {
    return this.form;
  }
  set formGroup(formGroup: FormGroup) {
    this.form = formGroup;
  }
  @Input()
  get offer() {
    return this._offer;
  }
  set offer(offer) {
    this._offer = offer;
    if (offer) { this.sign = offer?.certeurope ? null : 'manual'; }
  }
  _offer = null;

  form = this.formUtilsService.buildForm(new Order()) as FormGroup;
  loading = false;
  certeuropeOrder: CerteuropeOrder = null;
  sign = null; // null = default & certeurope, manual = manual only
  date: Date = new Date();

  ngOnInit(): void {
    this.form.valueChanges.subscribe(_ => this.date = new Date());
  }

  certeurope(): Subscription{
    this.loading = true;
    return this.backendService.requestWithoutCatchError('get', `inactive/certeurope/create/${this.form.get('id').value}`).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error(err);
        this.loading = false;
        this.dialog.open(DialogComponent, {
          data: {
            buttons: {
              OK: null
            },
            title: `Une erreur s'est produite lors de la création de la signature électronique`,
          },
        });
        return EMPTY;
      })
    ).subscribe(order => {this.loading = false; this.certeuropeOrder = order; this.sign = 'certeurope'; });
  }

  contractsDownloaded(): boolean {
    return (this.form.get('suborders') as FormArray).getRawValue().every(
      suborder => suborder.contracts.length && suborder.contracts.every(contract => contract.downloaded)
    );
  }

}
