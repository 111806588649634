import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-number-form',
  templateUrl: './number-form.component.html',
  styleUrls: ['./number-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFormComponent),
      multi: true
    }
  ]
})
export class NumberFormComponent implements ControlValueAccessor {

  @Input() label = null;
  @Input()
  get min(): number {
      return this._min;
  }
  set min(min: number) {
      this._min = min;
      this.update(this.control.value);
  }
  _min = 0;
  @Input()
  get max(): number {
      return this._max;
  }
  set max(max: number) {
      this._max = max;
      this.update(this.control.value);
  }
  _max = null;
  @Input() step = 1;
  control: FormControl = new FormControl(0);

  writeValue(obj: any): void {
    this.control.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  decreaseQuantity(): void {
    this.update(this.control.value - this.step);
  }

  increaseQuantity(): void {
    this.update(this.control.value + this.step);
  }

  update(newVal: number): void {
    newVal = Math.floor(newVal / this.step) * this.step;
    if (this._max != null) {
      newVal = Math.min(this._max, newVal);
    }
    if (this._min != null) {
      newVal = Math.max(this._min, newVal);
    }
    this.writeValue(newVal);
  }

}
