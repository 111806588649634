import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AmgcaService } from 'src/app/services/amgca/amgca.service';
import { BackendService } from 'src/app/services/backend/backend.service';
import { DialogComponent } from 'src/app/utils/dialog/dialog.component';
import { Customer } from 'src/app/utils/model/customer.model';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent {

  constructor(private backendService: BackendService, private dialog: MatDialog, private amgcaService: AmgcaService) {
  }

  backendRoute = 'refCustomers';
  icon = 'delete';
  displayedColumns = Customer.getDisplayedColumns();
  filteredColumns = Customer.getFilteredColumns();
  iconDisplay = Customer.canDelete;

  namingPage =  "MES CLIENTS - TOUTES OFFRES";
  namingTable = `Points de vente des commandes effectuées${this.getAmgca()?` à l’agence ${this.getAmgcaBranch()}`:''}`;

  getAmgca() {
    return sessionStorage.getItem('amgca');
  }

  getAmgcaBranch() {
    return this.amgcaService.amgcaToOrder(JSON.parse(this.getAmgca())).suborders[0].branch_code;
  }

  routerLinkCallback(row) {
    return [row.id || row.id_ref];
  }

  iconClick(row) {
    this.dialog.open(DialogComponent, {
      data: {
        title: "Souhaitez-vous réellement<br>supprimer ce client?",
        buttons: {
          'Oui': true,
          'Non': false
        }
      }
    }).afterClosed().subscribe(_ => {
      if(_) this.backendService.request('delete', `${this.backendRoute}/${row.id || row.id_ref}`).subscribe(_ => window.location.reload());
    });
  }

}
