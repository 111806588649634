import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { concatMap, switchMap, tap } from 'rxjs/operators';
import { AuthGuard } from 'src/app/guards/auth/auth.guard';
import { BackendService } from 'src/app/services/backend/backend.service';
import { FormUtilsService } from 'src/app/services/form-utils/form-utils.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { Contract } from '../../model/contract.model';

type Type = 'display' | 'download' | 'print';

@Component({
  selector: 'app-contracts-display',
  templateUrl: './contracts-display.component.html',
  styleUrls: ['./contracts-display.component.scss']
})
export class ContractsDisplayComponent implements OnInit {

    constructor(public backendService: BackendService, private dialog: MatDialog, private formUtilsService: FormUtilsService, private router: Router) { }

    @Input()
    get formArray(): FormArray {
        return this.form;
    }
    set formArray(formArray: FormArray) {
        this.form = formArray;
    }
    form = this.formUtilsService.buildForm([]) as FormArray;

    @Input()
    types: Type[] = ['print']; // ['display', 'download', 'print'];
    loading = false;

    ngOnInit(): void {
    }

    displayContract(contractForm: FormGroup, type: Type) {
        const contract: Contract = contractForm.getRawValue();
        if(false && contract.id) {
            this.dialog.open(DialogComponent, {
                disableClose: true,
                data: {
                    content: 'Attention l\'impression est obligatoire',
                    buttons: {
                        'OK': true
                    }
                }
            }).beforeClosed().subscribe(printed => {
                if (printed) {
                    this.getFile(contract).subscribe(file => {
                        this.downloadBlob(file,type);
                        contractForm.get('downloaded').setValue(true);
                    });
                }
            });
        } else {
            this.getFile(contract).subscribe(file => {
                this.downloadBlob(file,type);
                contractForm.get('downloaded').setValue(true);
            });
        }
    }

    refreshContracts() {
        return this.backendService.request('get',`contracts/${this.form.parent.get("id_order").value}`)
        .pipe(tap(contracts => {
            const order = this.form.root;
            Object.entries((order.get("suborders") as FormArray).controls).forEach(([s,suborder]) => {
                this.formUtilsService.patchForm(suborder.get("contracts"),contracts[s],true,false,true);
            })
        }))
    }

    getContractNature(contract: Contract) {
        if(!contract?.id) return 'temporary';
        if(contract?.signed_url) return 'signed';
        if(contract?.url) return 'regular';
    }

    getBase64(contract: Contract) {
        const type = 'application/pdf';
        const filename = contract.signed_url || contract.url;
        const params = AuthGuard.getParams(this.router.routerState.snapshot.root);
        let route = `base64/${this.getContractNature(contract)}/${filename}`;
        if(params.idOrder && params.hash) route = `inactive/certeurope/base64/${params.idOrder}/${params.hash}/${filename}`;
        return this.backendService.request('get',route,{responseType: 'text'})
        .pipe(
            concatMap(async base64 => new File([await (await fetch(`data:${type};base64,${base64}`)).blob()], filename, {type}))
        );
    }

    getFile(contract: Contract) {
        if(!contract.url) {
            return this.refreshContracts().pipe(switchMap(_ => this.getBase64((this.form.value as Contract[]).find(c => c.id == contract.id && c.type == contract.type) || contract)));
        }
        return this.getBase64(contract);
    }

    downloadBlob(file: File, type: Type) {
        const url = URL.createObjectURL(file);
        if(type == "print") {
            this.loading = true;
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = url;
            document.body.appendChild(iframe);
            iframe.contentWindow.onload = () => this.loading = false;
            iframe.contentWindow.print();
        }
        if(type == "download") {
            if (navigator['msSaveOrOpenBlob']) {
                navigator['msSaveOrOpenBlob'](file, file.name);
            } else {
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank';
                a.download = file.name;
                a.click();
            }
        }
        if (type == "display") {
            window.open(url);
        }
        // URL.revokeObjectURL(url);
    }

    nosort() {}

}
