import { Base } from "./base.model";
import { Builder } from "./builder.model";

export class BankAccount extends Base {
    constructor(values: Partial<BankAccount> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    bic: string = null;
    iban: string = null;
    bank: string = null;
}
