<div class="my-2 mx-4">
    <ng-container *ngIf="!form.errors; else error">
        <div *ngFor="let contract of form.controls">
            <div class="d-flex justify-content-between align-items-center py-2">
                <span>{{contract.get('label').value}}</span>
                <ng-container *ngFor="let type of {'display': 'Visualisation','download': 'Téléchargement','print': 'Impression'} | keyvalue: nosort">
                    <a *ngIf="types.includes(type.key)" mat-stroked-button class="mr-5 color-primary-200 border-color-primary-200" (click)="displayContract(contract, type.key)" [matTooltip]="contract.get('id') && !contract.get('downloaded').value?'Attention l\'impression est obligatoire':null" matTooltipClass="warn">{{type.value}}</a>
                </ng-container>
            </div>
        </div>
        <div class="d-flex justify-content-center" *ngIf="!form.length">
            <mat-spinner diameter=50></mat-spinner>
        </div>
    </ng-container>
    <ng-template #error>
        <div class="error color-warn">
            {{(form.errors | values).join("\n")}}
        </div>
    </ng-template>
</div>
<div class="overlay d-flex justify-content-center" *ngIf="loading">
    <mat-spinner></mat-spinner>
</div>