import { Base } from "./base.model";
import { Builder } from "./builder.model";
import { Product } from "./product.model";
import { Equipment } from "./equipment.model";

export class RefProductIncompatibility extends Base {
    constructor(values: Partial<RefProductIncompatibility> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    product_id: number = null;
	product_type: string = null;
    incompatible_product_id: number = null;
	incompatible_product_type: string = null;

    static getIncompatibilities(incompatible_product: Product, equipment: Equipment, incompatibilities: RefProductIncompatibility[]) {
        const incompatible_product_type = incompatible_product.constructor.name.toLowerCase();
        return incompatibilities.filter(incompatibility => 
            incompatibility.incompatible_product_id == incompatible_product.id_ref && 
            incompatibility.incompatible_product_type == incompatible_product_type &&
            (
                equipment.constructor.name.toLowerCase() == incompatibility.product_type ? 
                equipment.id_ref == incompatibility.product_id : 
                equipment[(incompatibility.product_type+"s").replace("ys","ies")].find(p => p.id_ref == incompatibility.product_id)
            )
        );
    }
}
