import { Base } from "./base.model";
import { Builder } from "./builder.model";

export class User extends Base {
    constructor(values: Partial<User> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    code: string = null;
	lastname: string = null;
	firstname: string = null;
	service: string = null;
	function: string = null;
	phone: string = null;
	mail: string = null;
}
