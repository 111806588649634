import { Product } from "./product.model";
import { Builder } from "./builder.model";

export class Accessory extends Product {
    constructor(values: Partial<Accessory> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    quantity: number = 0;
}
