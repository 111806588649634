import { Base } from './base.model';
import { Customer } from './customer.model';
import { Equipment } from './equipment.model';
import { Suborder } from './suborder.model';
import { User } from './user.model';
import { Builder } from "./builder.model";
import { formatDate } from '@angular/common';
import { Action } from './action.model';

export class Order extends Base {
    constructor(values: Partial<Order> = {}) {
        super(values);
        Builder.build.bind(this)(values,{
            suborders: _ => new Suborder(_)
        });
    }

    customer: Customer = new Customer();
    suborders: Suborder[] = [new Suborder()];
    user: User = new User();
    action_name: string = null;
    action_label: string = null;

    static reduce(order: Order): Order {
        const reducedOrder = {...order};
        delete reducedOrder.customer.orders;
        reducedOrder.suborders = reducedOrder.suborders.reduce((suborders, suborder) => {
            suborder.equipments = suborder.equipments.reduce((equipments, equipment) => {
                ['peripherals', 'softwares', 'accessories', 'interventions', 'services'].forEach(productKey => {
                    equipment[productKey] = equipment[productKey].filter(product => product.id_order == order.id);
                });
                const quantity = equipment.quantity;
                delete equipment.quantity;
                const accessories = equipment.accessories.filter(_ => _.quantity);
                delete equipment.accessories;
                for (let q = 0; q < quantity; q++) {
                    equipment.accessories = q ? [] : accessories;             
                    equipments.push(new Equipment(Object.keys(equipment).filter(k => !(k.includes("ref_") && Array.isArray(equipment[k]))).reduce((acc, val) => ({...acc,[val]: equipment[val]}),{})));
                }
                return equipments;
            }, []);
            suborders.push(suborder);
            return suborders;
        }, []);
        return new Order(reducedOrder);
    }

    reduce(): Order {
        return Order.reduce(this);
    }

    static getDisplayedColumns(icon = null, checkbox = false) {
        let columns = {};
        if (checkbox) {
            columns = {
                ...columns,
                checkbox: {type: 'checkbox', color: 'accent', width: 5}
            };
        }
        columns = {
            ...columns,
            'suborders.0.bnppsm_order_number': {label: 'N° de commande', width: 10, class: 'font-weight-bold color-primary-200'},
            'suborders.0.sign_date': {label: 'Date de signature', mapping: _ => _?formatDate(_, "dd-MM-yyyy", "fr-FR"):"-", width: 15},
            'customer.store_registration_number': {label: 'SIRET', width: 10},
            'customer.store_name': {label: 'Dénomination sociale', width: 23},
            'suborders.0.contract_emoney.contract_reference': {label: 'Contrat monétique', width: 7},
            'suborders.0.contract_label': {label: 'Nature de commande', mapping: _ => _?.replace("/", "/<br>"), width: 9},
            action_label: {label: 'État de la commande', width: 11, class: 'font-weight-bold', style: _ => ({"color": Action.getActionsColors()[Object.keys(Action.getGlobalActionLabel()).findIndex(v => v==_)]})},
            'user.firstname': {label: 'Prénom', width: 5},
            'user.lastname': {label: 'Nom', width: 5}
        };
        if (icon !== null) {
            columns = {
                ...columns,
                [icon]: {type: 'icon', width: 5}
            };
        }
        return columns;
    }

    static getFilteredColumns() {
        const displayedColumns = {
            ...Order.getDisplayedColumns(),
            'customer.company_registration_number': {label: 'SIREN'}
        };
        return ['suborders.0.contract_emoney.contract_reference','customer.company_registration_number','suborders.0.bnppsm_order_number','customer.store_name']
        .reduce((acc,val) => ({...acc, [val]: displayedColumns[val]['label']}), {});
    }

    static canDelete(order: Order) {
        return order.suborders.every(suborder => !suborder.action_name);
    }

    canDelete() {
        return Order.canDelete(this);
    }
}
