<form [formGroup]="form">
  <div class="row">
    <ng-container *ngFor="let conf of config">
      <div [ngClass]="'col-'+(conf.colspan || 4)">
        <app-form-field *ngIf="conf.formControlName" [form]="form" [config]="conf" class="mx-1"></app-form-field>
      </div>
    </ng-container>
  </div>
  <div class="d-flex justify-content-center">
    <button *ngIf="canSave()" mat-raised-button color="primary" (click)="saveMessage()" [disabled]="isDisabled()">Sauvegarder</button>
    <button *ngIf="!canSave()" mat-raised-button color="primary" [disabled]="true">Vous ne pouvez pas sauvegarder le message d'un autre utilisateur</button>
  </div>
</form>
