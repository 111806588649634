import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakTokenParsed } from 'keycloak-js';
import { BackendService } from 'src/app/services/backend/backend.service';
import { FormUtilsService } from 'src/app/services/form-utils/form-utils.service';
import { Message } from '../../../utils/model/message.model';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  constructor(public backendService: BackendService, private router: Router, private route: ActivatedRoute, public keycloakService: KeycloakService, private formUtilsService: FormUtilsService) { }

  id = this.route.snapshot.params.id;
  message = new Message(this.route.snapshot.data.resolve.message);
  form = this.formUtilsService.buildForm({...this.message, date: {start: this.message?.start_date, end: this.message?.end_date}}) as FormGroup;
  token: KeycloakTokenParsed;

  @Input()
  config = [
    {},
    {
      formControlName: 'date',
      label: 'Dates d\'activation',
      input: 'date',
      color: 'accent',
      class: 'accent'
    },
    {
      formControlName: 'message',
      required: true,
      input: 'editor',
      colspan: 12,
      editorConfig: {
        uploadUrl: this.backendService.backendUrl+"upload",
        // upload: (file: File) => console.log,
        uploadWithCredentials: true,
        editable: true,
        // spellcheck: true,
        // height: 'auto',
        // minHeight: '0',
        // maxHeight: 'auto',
        // width: 'auto',
        // minWidth: '0',
        // translate: 'yes',
        // enableToolbar: true,
        // showToolbar: true,
        placeholder: null, //'Enter text here...',
        // defaultParagraphSeparator: '',
        // defaultFontName: '',
        // defaultFontSize: '',
        fonts: [
          // {class: 'arial', name: 'Arial'},
          // {class: 'times-new-roman', name: 'Times New Roman'},
          // {class: 'calibri', name: 'Calibri'},
          // {class: 'comic-sans-ms', name: 'Comic Sans MS'}
        ],
        // uploadUrl: 'v1/image',
        // uploadWithCredentials: false,
        sanitize: false, //true,
        // toolbarPosition: 'top',
        // outline: true,
        toolbarHiddenButtons: [
          // ['undo', 'redo'],
          // ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript'],
          // ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
          // ['indent', 'outdent'],
          // ['insertUnorderedList', 'insertOrderedList'],
          // ['heading'],
          // ['fontName'],
          // ['fontSize'],
          // ['textColor', 'backgroundColor'],
          // ['customClasses'],
          ['link', 'unlink', 'insertImage', 'insertVideo', 'insertHorizontalRule'],
          // ['removeFormat'],
          ['toggleEditorMode']
        ]
      }
    },
  ];

  ngOnInit(): void {
    this.token = this.keycloakService.getKeycloakInstance()?.tokenParsed;
  }

  canSave() {
    return true; //!this.message?.ref_user?.['mail'] || this.message?.ref_user?.['mail'] === this.token?.['email'];
  }

  isDisabled() {
    return !this.form.get('message').value;
  }

  public saveMessage(): void {
    const value = this.form.getRawValue();
    this.backendService.request('post', 'messages', {
      body: {...value, start_date: value.date.start, end_date: value.date.end}
    }).subscribe((value) => this.router.navigate([".."], {relativeTo: this.route}));
  }

}
