import { Product } from './product.model';
import { Builder } from "./builder.model";
import { Action } from './action.model';

export class Fare extends Product {
    constructor(values: Partial<Fare> = {}) {
      super(values);
      Builder.build.bind(this)(values);
    }

    contract_duration: number = null;
    contract_type: string = null;
    deee_eco_amount: number = null;
    installation_mode: string = null;
    subscription3g_duration: number = null;
    yearly_maintenance_amount: number = null;
    yearly_subscription3g_amount: number = null;
    rental_equipment_amount: number = null;
    purchase_equipment_amount: number = null;
    id_maintainer: string = null;
    change_request_enabled: boolean = null;
    id_offer: number = null;
    nepting: boolean = null;
    nepting_application_type: string = null;

    static isInstalled(fare: Fare) {
      const action_names = [Object.values(Action.getGlobalActionLabel())[1][0]];
      if(fare?.installation_mode == 'onsite') {
        action_names.push(...['installation_done', 'delivery', 'execution']);
      }
      if(fare?.installation_mode == 'remote') {
        action_names.push(...['delivery', 'execution']);
      }
      return action_names.includes(fare?.action_name);
    }

    static getContractLabel(fare) {
      if(fare.additional_order) return 'Autres services';
      return {
        rental: `Loc. ${fare.contract_duration} mois`,
        purchase: 'Vente'
      }[fare.contract_type];
    }

    static getContractTypeLabel(fare) {
      return {
        rental: `Location`,
        purchase: 'Vente'
      }[fare.contract_type];
    }

    static getContractDurationLabel(fare) {
      return `${fare.contract_duration} mois`;
    }

    static getInstallationLabel(fare) {
      return {
        onsite: 'Sur site',
        remote: 'À distance'
      }[fare.installation_mode];
    }

    isInstalled() {
      return Fare.isInstalled(this);
    }

    getContractLabel() {
        return Fare.getContractLabel(this);
    }

    getContractTypeLabel() {
        return Fare.getContractTypeLabel(this);
    }

    getContractDurationLabel() {
        return Fare.getContractDurationLabel(this);
    }

    getInstallationLabel() {
      return Fare.getInstallationLabel(this);
    }
}
