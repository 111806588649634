import { AbstractControl, ValidationErrors } from '@angular/forms';

// IBAN format: FRkk bbbb bsss sscc cccc cccc cxx
// b = National bank code
// s = Branch code (fr:code guichet)
// c = Account number
// x = National check digits (fr:clé RIB)
export const ibanRegexp: RegExp = /^ *([A-Z]{2})([0-9]{2})([A-Z0-9]+) *$/;
export const countryRules = {
  AD: 24, AE: 23, AL: 28, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22,
  BR: 29, BY: 28, CH: 21, CR: 22, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28,
  EE: 20, ES: 24, FI: 18, FO: 18, FR: 27, GB: 22, GE: 22, GI: 23, GL: 18,
  GR: 27, GT: 28, HR: 21, HU: 28, IE: 22, IL: 23, IQ: 23, IS: 26, IT: 27,
  JO: 30, KW: 30, KZ: 20, LB: 28, LC: 32, LI: 21, LT: 20, LU: 20, LV: 21,
  MC: 27, MD: 24, ME: 22, MK: 19, MR: 27, MT: 31, MU: 30, NL: 18, NO: 15,
  PK: 24, PL: 28, PS: 29, PT: 25, QA: 29, RO: 24, RS: 22, SA: 24, SC: 31,
  SE: 24, SI: 19, SK: 24, SM: 27, TL: 23, TN: 24, TR: 26, UA: 29, VA: 22,
  VG: 24, XK: 20
};

function ibanChecksum(iban) {
  if (!iban) {
    return false;
  }

  iban = iban.toUpperCase().replace(/[^A-Z0-9]/g, '');
  const code = iban.match(ibanRegexp);

  if (!code || iban.length !== countryRules[code[1]]) {
    return false;
  }

  const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: string) => {
    return letter.charCodeAt(0) - 55;
  });

  let checksum = digits.slice(0, 2);
  let fragment = '';

  for (let offset = 2; offset < digits.length; offset += 7) {
    fragment = checksum + digits.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }

  return checksum;
}

export function ibanValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return null;
  }
  const checksum = ibanChecksum(control.value);
  return checksum === 1 ? null : { iban: { checksum } };
}

export function ibanValidatorForm(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return null;
  }
  const checksum = ibanChecksum(Object.values(control.value).join(''));
  return checksum === 1 ? null : { iban: { checksum } };
}
