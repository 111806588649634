import { AbstractControl, ValidationErrors } from '@angular/forms';

function lunhChecksum(value) {
  const digitsString = value.replace(/\s/g, '');

  if (digitsString.length <= 1 || digitsString.match(/[^0-9 ]/)) {
    return null;
  }

  return Array.from(digitsString)
    .reverse()
    .map((char, index) => {
      let digit = Number(char);
      digit = index % 2 == 1 ? digit * 2 : digit;
      digit = digit > 9 ? digit - 9 : digit;
      return digit;
    })
    .reduce((previous, current) => previous + current) % 10;
}

export function luhnValidator(control: AbstractControl): ValidationErrors | null {
  if (!control.value) {
    return null;
  }
  const checksum = lunhChecksum(control.value);
  return checksum === 0 ? null : { luhn: { checksum } };
}
