import { Base } from "./base.model";
import { Builder } from "./builder.model";

export class Address extends Base {
    constructor(values: Partial<Address> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    address: string = null;
    locality: string = null;
    zip_code: string = null;
    city_name: string = null;
    country_code: string = '250';
    name: string = null;
    phone: string = null;
    secondary_phone: string = null;
    email: string = null;
    address_full: string = null;
}
