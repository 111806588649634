import { Component, Input, OnInit } from '@angular/core';
import { Equipment } from '../../model/equipment.model';
import { BackendService } from '../../../services/backend/backend.service';

@Component({
  selector: 'app-equipment-display',
  templateUrl: './equipment-display.component.html',
  styleUrls: ['./equipment-display.component.scss']
})
export class EquipmentDisplayComponent implements OnInit {

  constructor( public backendService: BackendService) { }

  @Input() equipment: Equipment;
  map = {};

  iconMapping = {
    '4G': 'sim_card',
    '3G': 'sim_card',
    IP: 'wifi',
    RTC: 'local_phone',
    BLUETOOTH: 'bluetooth'
  };
  ngOnInit(): void {
    this.equipment.ref_fares.forEach(ref_fare => {
      const installation_mode_label = Equipment.getInstallationLabel(ref_fare);
      const contract_type_label = Equipment.getContractLabel(ref_fare);
      if(! this.map[installation_mode_label]){
        this.map[installation_mode_label] = {};
      }
      this.map[installation_mode_label][contract_type_label] = ref_fare[ref_fare.contract_type+"_equipment_amount"];
    });

  }
  getName(equipment: Equipment) {
    return Equipment.getName(equipment);
  }

}
