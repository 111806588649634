<form [formGroup]="form" class="row">
    <div class="col">
        <app-customer-display [(formGroup)]="form.controls.customer" [userInfo]="false"></app-customer-display>
        <mat-expansion-panel class="my-3 shadow bnpp-title-shadow" [expanded]="true">
            <mat-expansion-panel-header class="bnpp-title">
                <mat-panel-title class="color-primary-700">Références bancaires</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="d-flex justify-content-between my-2 mx-4">
                <div class="col-auto d-flex flex-column" *ngFor="let config of {bic: 'BIC:',bank: 'BANQUE:',iban: 'IBAN:'} | keyvalue: nosort">
                    <label [for]="config.key" class="mat-body-2">{{config.value}}</label>
                    <app-form-field [form]="form.get('customer.bank_account')" [config]="{formControlName: config.key, type: 'label'}"></app-form-field>
                </div>
            </div>
        </mat-expansion-panel>
        
        <ng-container *ngFor="let suborder of form.controls.suborders['controls']; let s=index">
            <mat-expansion-panel class="my-3 shadow bnpp-title-shadow" [expanded]="true">
                <mat-expansion-panel-header class="bnpp-title">
                    <mat-panel-title class="color-primary-700"> Documents contractuels</mat-panel-title>
                </mat-expansion-panel-header>
                <app-contracts-display [(formArray)]="suborder.controls.contracts" [types]="!form.get('id').enabled?['print']:['display']"></app-contracts-display>
            </mat-expansion-panel>

            <mat-expansion-panel class="my-3 shadow bnpp-title-shadow" [expanded]="true"  *ngIf="!form.get('id').enabled">
                <mat-expansion-panel-header class="bnpp-title">
                    <mat-panel-title class="color-primary-700">Synthèse de la commande</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="row my-2 mx-4">
                    <ng-container *ngFor="let conf of config">
                        <div class="col-4 my-2" *ngIf="suborder.get(conf.control).value">
                            <div class="d-flex justify-content-between">
                                <div class="mat-body-strong"><h4 class="m-0 pb-1">Total {{conf.label}}</h4></div>
                            </div>
                            <div class="d-flex justify-content-between p-1">
                                <div>Total {{conf.label}} (HT)</div>
                                <div>{{((conf.multiplier||1)*suborder.get(conf.control).value) | currency}}</div>
                            </div>
                            <div class="d-flex justify-content-between p-1">
                                <div>TVA {{suborder.get('applicable_vat').value}}%</div>
                                <div>{{((conf.multiplier||1)*suborder.get(conf.control).value*suborder.get('applicable_vat').value/100) | currency}}</div>
                            </div>
                            <div class="d-flex justify-content-between color-warn-100 my-2">
                                <h4>Total {{conf.label}} (TTC)</h4>
                                <h4>{{((conf.multiplier||1)*suborder.get(conf.control).value*(1+suborder.get('applicable_vat').value/100)) | currency}}</h4>
                            </div>
                        </div>
                    </ng-container>
                    <div class="col-12">
                        <app-form-field [form]="form" [config]="{
                            formControlName: 'customer.contact_comment',
                            label: 'Commentaire',
                            colspan: 12,
                            input: 'textarea',
                            maxLength: 200
                        }"></app-form-field>
                    </div>
                </div>
            </mat-expansion-panel>
            
            <app-equipments-display [formDisplay]="form.get('id').enabled" [(formArray)]="suborder.controls.equipments" [stepper]="stepper" [refProductRequirements]="refProductRequirements" [refProductIncompatibilities]="refProductIncompatibilities"></app-equipments-display>
        </ng-container>        
    </div>
    <div class="col-auto" *ngIf="form.get('id').enabled">
        <mat-card class="mt-3 shadow bnpp-title-shadow p-0">
            <mat-card-header class="d-flex align-items-center card-form-logiciel bnpp-title">
                <mat-card-title class="color-primary-700">Synthèse de la commande</mat-card-title>
            </mat-card-header>
            <mat-card-content *ngFor="let suborder of form.controls.suborders['controls']" class="d-flex flex-column mx-2">
                <ng-container *ngFor="let conf of config">
                    <div class="my-2" *ngIf="suborder.get(conf.control).value">
                        <div class="d-flex justify-content-between">
                            <div class="mat-body-strong"><h4 class="m-0 pb-1">Total {{conf.label}}</h4></div>
                        </div>
                        <div class="d-flex justify-content-between p-1">
                            <div>Total {{conf.label}} (HT)</div>
                            <div>{{((conf.multiplier||1)*suborder.get(conf.control).value) | currency}}</div>
                        </div>
                        <div class="d-flex justify-content-between p-1">
                            <div>TVA {{suborder.get('applicable_vat').value}}%</div>
                            <div>{{((conf.multiplier||1)*suborder.get(conf.control).value*suborder.get('applicable_vat').value/100) | currency}}</div>
                        </div>
                        <div class="d-flex justify-content-between color-warn-100 my-2">
                            <h4 >Total {{conf.label}} (TTC)</h4>
                            <h4 >{{((conf.multiplier||1)*suborder.get(conf.control).value*(1+suborder.get('applicable_vat').value/100)) | currency}}</h4>
                        </div>
                    </div>
                </ng-container>
            </mat-card-content>
            <mat-card-actions class="d-flex flex-column justify-content-center mx-2 pb-2">
                <button mat-raised-button class="mx-0 mt-2" [ngClass]="loading || !form.valid?null:'background-color-primary-50 opposite-color-foreground-base'" type="submit" (click)="postOrder()" [disabled]="loading || !form.valid">Continuer la commande</button>
                <button mat-raised-button class="mx-0 mt-2 background-color-primary-50 opposite-color-foreground-base" (click)="surrender()">Abandonner la commande</button>
            </mat-card-actions>
        </mat-card>
    </div>
</form>
