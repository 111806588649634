import { FormGroup, ValidationErrors } from '@angular/forms';

export function addressValidator(control: FormGroup): ValidationErrors | null {
  const mapping = {
    zip_code: ['code_postal'],
    city_name: ['nom_de_la_commune', 'libelle_d_acheminement']
  }
  const address = control.getRawValue();
  if (!address || Object.keys(mapping).every(_ => !address[_])) {
    return null;
  }
  const laposte_hexasmal = require('../../services/referential/laposte_hexasmal.json').map(_ => _.fields);
  const addresses = laposte_hexasmal.filter(field => Object.entries(mapping).some(([k,v]) => v.some(key => address[k] == field[key])));
  const filtered_addresses = addresses.filter(field => Object.entries(mapping).every(([k,v]) => v.some(key => (!address[k] && !field[key]) || address[k] == field[key])));
  let global_errors = false;
  Object.entries(mapping).forEach(([k,v]) => {
    let errors = null;
    if(control.get(k).enabled) {
      if(!addresses.length) {
        if(address[k]) errors = { address };
      }
      else if(!filtered_addresses.length) {
        const address_diff = addresses.filter(field => !v.some((key) => (!address[k] && !field[key]) || address[k] == field[key]));
        if(address_diff.length) {
          errors = { addresses: address_diff.reduce((acc, val) => [...acc, Object.entries(mapping).reduce((a, [mk,mv]) => ({...a, [mk]: val[mv[0]]}),{})],[]) };
        }
      }
    }
    if(errors) global_errors = true;
    control.get(k).setErrors(errors);
  });
  if(global_errors) {
    if(!addresses.length) return { address };
    else if(!filtered_addresses.length) return { addresses: addresses.reduce((acc, val) => [...acc, Object.entries(mapping).reduce((a, [mk,mv]) => ({...a, [mk]: val[mv[0]]}),{})],[]) };
  }
  return null;
}
