import { getLocaleId } from "@angular/common";
import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import * as moment from "moment";

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {

    parse(value: any): Date | null {
        moment.locale(getLocaleId(this.locale));
        return moment(value, 'L', true).toDate();
    }
}