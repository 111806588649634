import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Equipment } from 'src/app/utils/model/equipment.model';
import { FormUtilsService } from 'src/app/services/form-utils/form-utils.service';
import { MatStepper } from '@angular/material/stepper';
import { BackendService } from 'src/app/services/backend/backend.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { RefProductRequirement } from '../../model/ref_product_requirement.model';
import { of } from 'rxjs';
import { Software } from '../../model/software.model';
import { RefProductIncompatibility } from '../../model/ref_product_incompatibility.model';

@Component({
  selector: 'app-equipments-display',
  templateUrl: './equipments-display.component.html',
  styleUrls: ['./equipments-display.component.scss']
})
export class EquipmentsDisplayComponent implements OnInit {

  constructor(private formUtilsService: FormUtilsService, private backendService: BackendService, private router: Router, private route: ActivatedRoute, private dialog: MatDialog) { }

  @Input()
  get formArray(): FormArray {
      return this.form;
  }
  set formArray(formArray: FormArray) {
      this.form = formArray;
  }
  form = this.formUtilsService.buildForm([]) as FormArray;

  @Input()
  refProductRequirements: RefProductRequirement[] = [];

  @Input()
  refProductIncompatibilities: RefProductIncompatibility[] = [];

  @Input()
  formDisplay: boolean = true;

  @Input()
  stepper: MatStepper;

  @Input()
  services: boolean = false;

  @Output()
  equipmentClick = new EventEmitter<Equipment>();

  @Input()
  softwareConfig = [
    {
      externalLabel: 'Logiciel',
      formControlName: 'name',
      type: 'label',
      class: 'pl-3',
    },
    {
      externalLabel: 'Numéro de contrat',
      formControlName: 'contract_reference',
      type: 'label',
    },
    {
      externalLabel: 'Emetteur',
      formControlName: 'bank_code',
      type: 'label',
    },
    {
      externalLabel: 'Tarif',
      formControlName: 'fare',
      type: 'label',
      class: 'd-flex justify-content-end color-warn-100',
      suffix: ' € HT'
    },
  ];

  @Input()
  accessoryConfig = [
    {
      externalLabel: 'Accessoire',
      formControlName: 'name',
      type: 'label',
      class: 'pl-3',
    },
    {
      externalLabel: 'Quantité',
      formControlName: 'quantity',
      input: 'number',
    },
    {
      externalLabel: '',
      type: 'label'
    },
    {
      externalLabel: 'Tarif (€ HT)',
      formControlName: 'fare',
      type: 'label',
      class: 'd-flex justify-content-end color-warn-100',
      suffix: ' € HT'
    },
  ];

  ngOnInit(): void {
    if(!this.formDisplay) {
      this.softwareConfig.forEach(c => c.type = 'label');
      this.accessoryConfig.forEach(c => c.type = 'label');
    }
    this.form.controls.forEach(control => {
      ['rental_equipment', 'purchase_equipment', 'yearly_maintenance', 'yearly_subscription3g', 'purchase_softwares'].forEach(_ => {
        if (!control.get(_ + '_total')) {
          (control as FormGroup).addControl(_ + '_total', new FormControl(control.get(_ + '_amount')?.value));
        }
      });
      ['contract_duration', 'contract_type', 'installation_mode'].forEach(_ => {
        if (!control.get(_)) {
          (control as FormGroup).addControl(_, new FormControl(this.form.parent.get(_).value));
        }
      });
    });
  }

  getName(equipment: Equipment) {
    return Equipment.getName(equipment);
  }

  getContractLabel(equipment: Equipment) {
    return Equipment.getContractLabel(equipment);
  }

  getInstallationLabel(equipment: Equipment) {
    return Equipment.getInstallationLabel(equipment);
  }

  getDependantSoftware(software: FormGroup) {
    return RefProductRequirement.getRequirements(new Software(software.getRawValue()), this.refProductRequirements).map(requirement => {
      return (software.parent as FormArray).controls.find((software: FormGroup, i) => software.get("id_ref").value==requirement.product_id);
    })[0];
  }

  canRemoveSoftware(software: FormGroup) {
    return software && software.get('type').value=='purchase' && !software.get('id_order').value && !this.getDependantSoftware(software);
  }

  removeSoftware(equipmentIndex, index, dialog = true) {
    (!dialog ? of(true) : this.dialog.open(DialogComponent, {
      data: {
        title: "Souhaitez-vous supprimer le logiciel que vous avez ajouté ?",
        buttons: {
          "Oui": true,
          "Non": false
        }
      }
    }).beforeClosed()).subscribe(_ => {
      if(_) {
        const softwares = (this.form.get([equipmentIndex, 'softwares']) as FormArray);
        const software = new Software((softwares.get([index]) as FormGroup).getRawValue());
        softwares.removeAt(index);
        RefProductRequirement.getRequired(software, this.refProductRequirements).forEach(requirement => {
          const requirement_index = softwares.controls.findIndex((software: FormGroup, i) => i>=index && software.get("id_ref").value==requirement.product_id);
          if(requirement_index>=0) this.removeSoftware(equipmentIndex, requirement_index, false);
        });
        if(this.stepper && !this.form.valid) {
          this.stepper.previous();
          window.scroll(0, 0);
        }
      }
    });
  }

}
