import {AbstractControl, Validator, ValidationErrors} from '@angular/forms';
import {ConfigService} from '../../services/config/config.service';
import {Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PhoneValidator implements Validator {
  constructor(private configService: ConfigService) {}

  validate(control: AbstractControl): ValidationErrors | null {
    const phoneRegexp: RegExp = /^0[1-9]([ \-\.]?[0-9]{2}){4}$/;

    if (!control.value) {
      return null;
    }
    return phoneRegexp.test(control.value.trim()) ? null : { [this.configService.config.validators.phone.error]: true };
  }
}
