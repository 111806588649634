import { Base } from "./base.model";
import { Builder } from "./builder.model";

export class CommunicationType extends Base {
    constructor(values: Partial<CommunicationType> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    communication_type: string = null;
}
