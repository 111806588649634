import {CdkPortalOutletAttachedRef, ComponentPortal} from '@angular/cdk/portal';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {HttpClient} from '@angular/common/http';
import {
  AfterViewInit,
  Component,
  ComponentRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ValidationErrors, Validators} from '@angular/forms';
import {AngularEditorComponent, AngularEditorService, UploadResponse} from '@kolkov/angular-editor';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormFieldComponent),
      multi: true
    }
  ]
})
export class FormFieldComponent implements OnInit, AfterViewInit, ControlValueAccessor {

  @Input() form: AbstractControl;
  @Input() config: any = {};
  control: FormControl = new FormControl();

  constructor(private http: HttpClient) {
  }

  @ViewChild(AngularEditorComponent) editor: AngularEditorComponent;
  @ViewChildren(CdkTextareaAutosize) cdkTextareaAutosizes: QueryList<CdkTextareaAutosize>;

  ngOnInit(): void {
    if (!this.config.error) {
      this.config.error = (errors: ValidationErrors): string => {
        if (errors && Object.keys(errors)?.[0] === 'required') {
          return (this.config.label || this.config.externalLabel) + ' est obligatoire';
        }
        return (this.config.label || this.config.externalLabel) + ' invalide';
      };
    }
    if (this.config.pattern) {
      let flags = 'g';
      if (this.config.pattern instanceof RegExp) {
        flags = this.config.pattern.flags || flags;
      }
      let pattern: string = (this.config.pattern.source || this.config.pattern);
      pattern = pattern.replace(/.*?(\[[^\[]*?\])(\?|\+|\*(\?|\+)?|\{[0-9]*,?[0-9]*\})$/g, '$1');
      if (pattern.startsWith('[') && pattern.endsWith(']')) {
        const regexp = new RegExp(pattern, 'g');
        let negativeRegexp = new RegExp(`[^${pattern}]`, flags);
        negativeRegexp = new RegExp(pattern.replace(/^\[/g, '[^'), flags);
        if (!this.config.paste) {
          this.config.paste = (event: ClipboardEvent) => {
            event.preventDefault();
            this.form.get(this.config.formControlName).setValue(event.clipboardData.getData('text').replace(negativeRegexp, ''));
          };
        }
        if (!this.config.keypress) {
          this.config.keypress = (event: KeyboardEvent) => {
            if (!new RegExp(pattern, flags).test(event.key)) {
              event.preventDefault();
            }
          };
        }
      }
    }
    const validators = this.config.validators ?? [];
    Object.entries({...this.config, [this.config.type || 'text']: true}).forEach(([k, v]) => {
      try {
        if (Validators[k]) {
          if (v && (v === true || v === false)) {
            validators.push(Validators[k]);
          } else {
            validators.push(Validators[k](v));
          }
        }
      } catch (e) {
      }
    });
    const control = this.form.get(this.config.formControlName);
    if (control) {
      if (validators.length) {
        control.setValidators(validators);
      }
      if (this.config.asyncValidators && this.config.asyncValidators.length) {
        control.setAsyncValidators(this.config.asyncValidators);
      }
      if (validators.length || (this.config.asyncValidators && this.config.asyncValidators.length)) {
        control.updateValueAndValidity();
      }
    }
  }

  writeValue(obj: any): void {
    this.control.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.control.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  uploadFile(file: File): Observable<UploadResponse> {

    const uploadData: FormData = new FormData();

    uploadData.append('file', file, file.name);

    return this.http.post<UploadResponse>(this.editor.config.uploadUrl, uploadData, {
      withCredentials: this.editor.config.uploadWithCredentials,
    });
  }

  insertFile(event): void {
    Array.from((event.target as HTMLInputElement).files).forEach(file => {
      if (this.editor.config.uploadUrl) {
        this.uploadFile(file).subscribe(response => this.watchInsertFile(response, event));
      } else {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent) => {
          const fr = e.currentTarget as FileReader;
          (this.editor['editorService'] as AngularEditorService).createLink(fr.result.toString());
        };
        reader.readAsDataURL(file);
      }
    });
  }

  watchInsertFile(response: UploadResponse, event): void {
    const document = this.editor['editorService']['doc'];
    const selection = document.getSelection();
    document.execCommand('createlink', false, response.imageUrl);
    selection.anchorNode.parentElement.target = '_blank';
    event.srcElement.value = null;
  }

  ngAfterViewInit(): void {
    this.cdkTextareaAutosizes.forEach(cdkTextareaAutosize => setTimeout(_ => cdkTextareaAutosize.resizeToFitContent(true), 0));
    if (this.config.component) {
      this.config.portal = new ComponentPortal(this.config.component.name);
    }
  }

  attached(ref: CdkPortalOutletAttachedRef): void {
    if (ref instanceof ComponentRef) {
      Object.keys(this.config).forEach(k => {
        if (!['formControlName'].includes(k)) {
          ref.instance[k] = this.config[k];
        }
      });
      Object.keys(this.config.component.inputs || {}).forEach(k => {
        ref.instance[k] = this.config.component.inputs[k];
      });
      Object.keys(this.config.component.outputs || {}).forEach(k => {
        if (ref.instance[k] instanceof EventEmitter) {
          ref.instance[k].subscribe(this.config.component.outputs[k]);
        } else {
          this.config.component.outputs[k] = ref.instance[k];
        }
      });
      ref.instance.formControl = this.form.get(this.config.formControlName);
      ref.instance.value = this.form.get(this.config.formControlName).value;
      ref.instance.error = this.config.error || (this.config.label || this.config.externalLabel) + ' invalide';
    }
  }

  nosort(): void {
  }

}
