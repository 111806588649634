import { Base } from "../base.model";
import { Builder } from "../builder.model";

export class Nepting extends Base {
    constructor(values: Partial<Nepting> = {}) {
        super(values);
        Builder.build.bind(this)(values);
    }

    action: string = null;
    request: Object = null;
    response: Object = null;
    error: string = null;
    id_order: number = null;
    retryable: boolean = false;

    static getActionLabelPrefix(action) {
        return Object.entries({
            "Activate": "Activation",
            "Add": "Ajout",
            "Create": "Création",
            "Deactivate": "Désactivation",
            "Get": "Obtention",
            "Manage": "Gestion",
            "Modify": "Modification",
            "Remove": "Suppression",
            "Subscribe": "Abonnement",
            "Switch": "Changement",
            "Unsubscribe": "Désabonnement",
            "Update": "Mise à jour",
        }).find(([k,v]) => action.startsWith(k))?.[1];
    }

    getActionLabelPrefix() {
        return Nepting.getActionLabelPrefix(this.action);
    }

    static getActionLabelSuffix(action) {
        return Object.entries({
            "Application": "de l'Application",
            "Duplicate": "du Duplicatat",
            "Reconciliation": "de la Réconciliation",
            "Store": "de l'Enseigne",
            "Terminal": "du Terminal",
            "Transaction": "de la Transaction",
            "User": "de l'Utilisateur",
            "Merchant": "du Marchand",
        }).find(([k,v]) => action.includes(k))?.[1];
    }

    getActionLabelSuffix() {
        return Nepting.getActionLabelSuffix(this.action);
    }

    static getActionLabel(action) {
        const prefix = Nepting.getActionLabelPrefix(action);
        const suffix = Nepting.getActionLabelSuffix(action);
        if(!prefix || !suffix) return action;
        return `${prefix} ${suffix}`;
    }

    getActionLabel() {
        return Nepting.getActionLabel(this.action);
    }
}
