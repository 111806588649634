import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Message } from 'src/app/utils/model/message.model';
import { SnackBarComponent } from 'src/app/utils/snack-bar/snack-bar.component';
import { BackendService } from '../backend/backend.service';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(private backendService: BackendService, private snackBar: MatSnackBar, public dialog: MatDialog) { }

  displayMessages(messages: Message[] = null): void {
    if(!messages) {
      const params = {searchCriterias: JSON.stringify([
        {
            "logical": "or",
            "modifier": null,
            "path": "startDate",
            "operation": "lessThanOrEqualTo",
            "value": [new Date().toISOString().replace(/[A-Za-z]/g," ")]
        },
        {
            "logical": "or",
            "modifier": null,
            "path": "startDate",
            "operation": "isNull",
            "value": null
        },
        {
            "logical": "and",
            "modifier": null,
            "path": "message",
            "operation": "isNotNull",
            "value": null
        },
        {
            "logical": "or",
            "modifier": null,
            "path": "endDate",
            "operation": "greaterThanOrEqualTo",
            "value": [new Date().toISOString().replace(/[A-Za-z]/g," ")]
        },
        {
            "logical": "or",
            "modifier": null,
            "path": "endDate",
            "operation": "isNull",
            "value": null
        }
      ])};
  
      this.backendService.request('get', 'messages', {params}).subscribe(messages => this.displayMessages(messages));
    } else {
      this.display(messages.shift())?.afterDismissed().subscribe(() => {
        this.displayMessages(messages);
      });
    }
  }
  
  display(message: Message) {
    if (message) {
      return this.snackBar.openFromComponent(SnackBarComponent, {
        panelClass: 'opposite-background-color-foreground-base',
        horizontalPosition: 'center',
        verticalPosition: 'top',
        data: {message: message.message, action: 'Fermer'},
        ...message
      });
    }
    return null;
  }
}
