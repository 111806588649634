import { AbstractControl, ValidationErrors } from '@angular/forms';
import { bicRegexp } from './bic.validator';
import { ibanRegexp } from './iban.validator';

export function bicIbanValidator(control: AbstractControl): ValidationErrors | null {
  if (!control || !control.get('bic').value || !control.get('iban').value) {
    return null;
  }

  const bic = control.get('bic').value.toUpperCase().replace(/[^A-Z0-9]/g, '').match(bicRegexp);
  const iban = control.get('iban').value.toUpperCase().replace(/[^A-Z0-9]/g, '').match(ibanRegexp);
  return bic && iban && bic[2] == iban[1] ? null : { bic_iban: { bic: bic ? bic[2] : null, iban: iban ? iban[1] : null } };
}
