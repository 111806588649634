<div class="mat-app-background">
  <mat-toolbar>
    <mat-toolbar-row class="justify-content-between main-toolbar">
      <div class="main-logo">
        <img [src]="backendService.backendUrl+'img/offer/0.png'">
        <img [src]="backendService.backendUrl+'img/offer/'+getIdOffer()+'.png'">
      </div>
      <nav mat-tab-nav-bar disableRipple="true" *ngIf="token">
        <div mat-tab-link class="pl-0" (click)="displayHelp()">
          <mat-icon class="pl-2 align-self-center w-100 align" [svgIcon]="'header-menu:contact-picto'"></mat-icon>Aide</div>
        <div mat-tab-link class="pl-0" [ngbPopover]="user" [autoClose]="true" triggers="click mouseenter:mouseleave">
          <mat-icon class="pl-2 align-self-center w-100 align" [svgIcon]="'header-menu:compte-picto'"></mat-icon>Utilisateur</div>
        <ng-template #user>
          <div class="user-pop">
            <div class="p-1">{{token.name}}</div>
            <div class="p-1 user-cnx">Dernière connexion le {{getDate(token.iat*1000)}}</div>
          </div>
        </ng-template>
          <a mat-tab-link [routerLink]="'login/'+sessionStorage.getItem('idpHint')" [active]="false &&isRouteActive('login')">
            <mat-icon class="pl-2 align-self-center w-100 align">autorenew</mat-icon>Retour Accueil</a>
          <a mat-tab-link routerLink="logout" [active]="false && isRouteActive('logout')">
            <mat-icon class="pl-2 align-self-center w-100 align" [svgIcon]="'header-menu:deco-picto'"></mat-icon>Déconnexion</a>
      </nav>
    </mat-toolbar-row>
    <mat-toolbar-row class="opposite-theme justify-content-center menu-toolbar">
      <nav mat-tab-nav-bar style="position: sticky;">
        <ng-container *ngFor="let route of router.config[1].children">
            <div *ngIf="route.children && (getIdOffer() || (token && token['groups'].includes('admin'))) && check(route)" class="d-flex" [class.background-gradient-left-primary]="isRouteActive(route.path)" disablePagination:true>
                <a mat-tab-link class="d-flex align-items-center justify-content-between" [routerLink]="route.path" [active]="isRouteActive(route.path)">
                    <mat-icon *ngIf="route.path != 'messages'; else elseIcon" class="w-100 align-icon ml-2 pr-2 " [svgIcon]="'header-menu:'+route.path+'-picto'"></mat-icon>
                    <ng-template #elseIcon><mat-icon class="w-100 align-icon ml-3 pr-2">chat_bubble_outline</mat-icon></ng-template>
                    <span>{{route.data.title || route.path}}</span>
                </a>
            </div>
        </ng-container>
      </nav>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="app-content">
    <router-outlet></router-outlet>
  </div>

  <div class="app-version" *ngIf="token">{{build.version}} - {{build.timestamp.substring(0, 24)}}</div>
</div>
<div class="overlay d-flex justify-content-center" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
