<ng-container *ngIf="form" [formGroup]="form">
    <mat-label *ngIf="config.label"><span [innerHTML]="config.label"></span>
        <span class="label-required" *ngIf="config.required">*</span>
        <mat-icon class="flashing flashing-label" matSuffix [svgIcon]="config.svgIcon" [color]="config.color" *ngIf="config.type == 'label' && (config.svgIcon || config.icon)" [matTooltip]="config.tooltip" [matTooltipClass]="'bnpp-tooltip ' + config.color">{{config.icon}}</mat-icon>
    </mat-label>
    <div class="form-label" [ngClass]="config.class" *ngIf="config.type == 'label'">
        {{form?.get(config.formControlName)?.value}} {{config.suffix}}
    </div>
    <mat-form-field *ngIf="config.type != 'label' && !['checkbox','slide-toggle','radio','iban','editor','number','date'].includes(config.input)" 
        [appearance]="config.appearance || 'outline'" [ngClass]="config.class" [floatLabel]="config.floatLabel || 'auto'">
        <span matPrefix *ngIf="config.prefix">{{config.prefix}}</span>
        <ng-container *ngIf="!config.input || config.input=='input'">
            <input matInput [type]="config.type || 'text'" [formControl]="form?.get(config.formControlName)" [matAutocomplete]="auto" [placeholder]="config.placeholder" [required]="config.required" [pattern]="config.pattern?.source || config.pattern" [minLength]="config.minLength" [maxLength]="config.maxLength || 255" (paste)="config.paste?config.paste($event):null" (keypress)="config.keypress?config.keypress($event):null">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="form?.patchValue($event.option.value)">
                <mat-option *ngFor="let option of (config.autocomplete || {}) | keyvalue :nosort" [value]="option.value">
                {{option.key}}
                </mat-option>
            </mat-autocomplete>
        </ng-container>
        <ng-container *ngIf="config.input=='textarea'">
            <textarea cdkTextareaAutosize [cdkAutosizeMinRows]="config.minRows" [cdkAutosizeMaxRows]="config.maxRows" matInput [formControl]="form?.get(config.formControlName)" [placeholder]="config.placeholder" [required]="config.required" [pattern]="config.pattern" [minLength]="config.minLength" [maxLength]="config.maxLength || 255" (paste)="config.paste?config.paste($event):null" (keypress)="config.keypress?config.keypress($event):null"></textarea>
        </ng-container>
        <ng-container *ngIf="config.input=='select'">
            <mat-select [formControl]="form?.get(config.formControlName)">
                <mat-option *ngFor="let option of config.options | keyvalue: nosort" [value]="option.value">{{option.key}}</mat-option>
            </mat-select>
        </ng-container>
        <span matSuffix *ngIf="config.suffix">{{config.suffix}}</span>
        <mat-icon class="flashing" matSuffix [svgIcon]="config.svgIcon" [color]="config.color" *ngIf="config.svgIcon || config.icon" [matTooltip]="config.tooltip" [matTooltipClass]="'bnpp-tooltip ' + config.color">{{config.icon}}</mat-icon>
        <mat-error class="color-accent" *ngIf="!form?.get(config.formControlName)?.valid">{{config.error(form?.get(config.formControlName)?.errors)}}</mat-error>
        <mat-hint class="color-accent" *ngIf="config.hint">{{config.hint}}</mat-hint>
    </mat-form-field>
    <div *ngIf="config.input=='checkbox'" class="d-flex flex-column justify-content-between w-100 h-75">
        <mat-checkbox [formControl]="form?.get(config.formControlName)"></mat-checkbox>
    </div>
    <div *ngIf="config.input=='slide-toggle'" class="d-flex flex-column justify-content-between w-100 h-75">
        <mat-slide-toggle [formControl]="form?.get(config.formControlName)"></mat-slide-toggle>
    </div>
    <div *ngIf="config.input=='radio'" class="d-flex flex-column justify-content-between w-100 h-75">
        <mat-label>{{config.label}}</mat-label>
        <mat-radio-group [formControl]="form?.get(config.formControlName)">
            <mat-radio-button *ngFor="let option of config.options | keyvalue :nosort; let index=index" [value]="option.key" [checked]="form?.get(config.formControlName).value?.toString()==option.key?.toString()">{{option.value}}</mat-radio-button>
        </mat-radio-group>
    </div>
    <ng-container *ngIf="config.input=='editor'">
        <div class="angular-editor-toolbar-set">
            <input style="display: none" type="file" (change)="insertFile($event)" #fileInput>
            <button type="button" class="angular-editor-button" (click)="fileInput.click()" title="Insert File" tabindex="-1"><mat-icon>link</mat-icon>
            </button>
        </div>
        <angular-editor [formControl]="form?.get(config.formControlName)" [config]="config.editorConfig || {}"></angular-editor>
    </ng-container>
    <ng-container *ngIf="config.input=='iban'">
        <app-iban-form [formControl]="form?.get(config.formControlName)" [label]="config.label" [maxLength]="config.maxLength || 255" [error]="config.error(form?.get(config.formControlName)?.errors)" [value]="form?.get(config.formControlName).value"></app-iban-form>
    </ng-container>
    <ng-container *ngIf="config.type != 'label' && config.input=='number'">
        <app-number-form [formControl]="form?.get(config.formControlName)" [label]="config.label" [error]="config.error(form?.get(config.formControlName)?.errors)" [min]="config.min || 0" [max]="config.max" [value]="form?.get(config.formControlName).value"></app-number-form>
    </ng-container>
    <ng-container *ngIf="config.input=='date'">
        <mat-form-field
        [appearance]="config.appearance || 'outline'" [ngClass]="config.class" [floatLabel]="config.floatLabel || 'auto'">
            <mat-date-range-input [formGroup]="form?.get(config.formControlName)" [rangePicker]="picker" [comparisonStart]="config.comparisonStart" [comparisonEnd]="config.comparisonEnd" [min]="config.min" [max]="config.max">
                <input matStartDate formControlName="start" placeholder="Date de début">
                <input matEndDate formControlName="end" placeholder="Date de fin">
                </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker [color]="config.color"></mat-date-range-picker>
        </mat-form-field>
    </ng-container>
</ng-container>
