<mat-card class="main-card primary shadow bnpp-title-shadow p-0" *ngIf="refSoftwares.length">
  <mat-card-header class="d-flex align-items-center card-form-logiciel bnpp-title">
    <mat-card-title class="color-primary-700">Logiciels</mat-card-title>
  </mat-card-header>
  <form *ngIf="refSoftwares.length">
    <mat-grid-list cols="12" rowHeight="335px" gutterSize="10px">
      <mat-grid-tile colspan="4" class="background-color-primary-100">
        <mat-list>
          <ng-container *ngFor="let availableSoftware of refSoftwares">
            <mat-list-item *ngIf="canChooseSoftware(availableSoftware)" (click)="addSoftware(availableSoftware)"
                           class="clickable d-flex p-2 mb-2">
              <mat-icon class="background-color-primary-300 color-primary-700 mr-2" matListIcon>add</mat-icon>
              {{ availableSoftware.name }}
            </mat-list-item>
          </ng-container>
        </mat-list>
      </mat-grid-tile>
      <mat-grid-tile colspan="8">
        <mat-list>
          <mat-list-item class="mat-list-header">
            <div class="mat-head w-100 px-1" *ngFor="let conf of config"
                 [ngClass]="conf.class">{{ conf.externalLabel || conf.label }}
            </div>
            <mat-icon class="mat-head"
                      style="width: 24px !important;min-width: 24px !important;max-width: 24px !important;"></mat-icon>
          </mat-list-item>
          <mat-list-item *ngFor="let software of form.controls; let s=index" [formGroup]="form.controls[s]"
                         class="justify-content-between">
            <ng-container *ngFor="let conf of getConfig(s)">
              <app-form-field [form]="form.get([s])" [config]="conf"></app-form-field>
            </ng-container>
            <mat-icon [ngClass]="{'d-none': !contractReferenceTooltip(software)}" color="primary"
                      matTooltip="Si votre contrat monétique a été créé ce jour, il faudra reporter la commande de matériel à demain"
                      matTooltipClass="primary">help_outline
            </mat-icon>
            <mat-icon [ngClass]="{'d-none': contractReferenceTooltip(software)}"
                      [color]="form.get([s]).valid?'primary':'warn'" [matTooltip]="software.get('description').value"
                      [matTooltipClass]="form.get([s]).valid?'primary':'warn'">help_outline
            </mat-icon>
            <mat-icon [ngClass]="{'invisible': !isSoftwareRemovable(software)}" class="clickable"
                      (click)="removeSoftware(s)">delete
            </mat-icon>
          </mat-list-item>
        </mat-list>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
</mat-card>
