import { Intervention } from './intervention.model';
import { Peripheral } from './peripheral.model';
import { Service } from './service.model';
import { Accessory } from './accessory.model';
import { Software } from './software.model';
import { Builder } from "./builder.model";
import { Fare } from './fare.model';
import { CommunicationType } from './communication-type.model';
import { formatDate } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';

export class Equipment extends Fare {
  constructor(values: Partial<Equipment> = {}) {
    super(values);
    Builder.build.bind(this)(values,{
      peripherals: _ => new Peripheral(_),
      softwares: _ => new Software(_),
      accessories: _ => new Accessory(_),
      interventions: _ => new Intervention(_),
      services: _ => new Service(_),
      ref_peripherals: _ => new Peripheral(_),
      ref_softwares: _ => new Software(_),
      ref_accessories: _ => new Accessory(_),
      ref_interventions: _ => new Intervention(_),
      ref_services: _ => new Service(_),
      ref_communication_types: _ => new CommunicationType(_),
      ref_fares: _ => new Fare(_)
    });
  }

    equipment_change_request: boolean = false;
    code: string = null;
    manufacturer: string = null;
    type: string = null;
    communication_type: string = null;
    softwares_total: number = null;
    services_total: number = null;
    purchase_services_amount: number = null;
    purchase_softwares_amount: number = null;
    peripherals: Peripheral[] = [];
    softwares: Software[] = [];
    accessories: Accessory[] = [];
    interventions: Intervention[] = [];
    services: Service[] = [];

    ref_peripherals: Partial<Peripheral>[] = [];
    ref_softwares: Partial<Software>[] = [];
    ref_accessories: Partial<Accessory>[] = [];
    ref_interventions: Partial<Intervention>[] = [];
    ref_services: Partial<Service>[] = [];
    ref_communication_types: Partial<CommunicationType>[] = [];
    ref_fares: Partial<Fare>[] = [];

    ref_communication_type: Partial<CommunicationType> = new CommunicationType();
    ref_fare: Partial<Fare> = new Fare();

    quantity: number = 1;
    total: number = null;
    rental_equipment_total: number = this.rental_equipment_amount * this.quantity;
    purchase_equipment_total: number = this.purchase_equipment_amount * this.quantity;
    purchase_services_total: number = this.purchase_services_amount * this.quantity;
    purchase_softwares_total: number = this.purchase_softwares_amount * this.quantity;
    yearly_maintenance_total: number = this.yearly_maintenance_amount * this.quantity;
    yearly_subscription3g_total: number = this.yearly_subscription3g_amount * this.quantity;
    installation_type: string = null;
    serial_number: string = null;
    ref_equipment: string = null;
    id_order: number = null;
    nepting: boolean = null;

    static filterInstalled(equipments: Equipment[]) {
        return equipments.filter(equipment => Equipment.isInstalled(equipment)).map(equipment => ({
            ...equipment,
            softwares: equipment.softwares.filter(software => Fare.isInstalled(new Fare({...software, installation_mode: equipment.installation_mode}))),
            accessories: equipment.accessories.filter(accessory => Fare.isInstalled(new Fare({...accessory, installation_mode: equipment.installation_mode}))),
            peripherals: equipment.peripherals.filter(peripheral => Fare.isInstalled(new Fare({...peripheral, installation_mode: equipment.installation_mode})))
        }))
    }
    
    static filterUninstalled(equipments: Equipment[]) {
        return equipments.filter(equipment => !Equipment.isInstalled(equipment)).map(equipment => ({
            ...equipment,
            softwares: equipment.softwares.filter(software => !Fare.isInstalled(new Fare({...software, installation_mode: equipment.installation_mode}))),
            accessories: equipment.accessories.filter(accessory => !Fare.isInstalled(new Fare({...accessory, installation_mode: equipment.installation_mode}))),
            peripherals: equipment.peripherals.filter(peripheral => !Fare.isInstalled(new Fare({...peripheral, installation_mode: equipment.installation_mode})))
        }))
    }

    static updateEquipment(equipment, id_offer=null) {
        equipment.ref_communication_type = equipment.ref_communication_types?.find(ref => 
            ["communication_type"].every(key => ref[key] == equipment[key])
        ) || equipment.ref_communication_type;
        equipment.ref_fare = equipment.ref_fares?.sort((a,b) => Number(b.id_offer==id_offer)-Number(a.id_offer==id_offer)).find(ref => 
            ["contract_duration", "contract_type", "id_maintainer", "installation_mode"].every(key => ref[key] == equipment[key] && (!id_offer || ref.id_offer==id_offer))
        ) || equipment.ref_fare;
        if(!equipment.peripherals.length) equipment.peripherals = equipment.ref_peripherals as any;
        return equipment;
    }

    updateEquipment(id_offer=null) {
        return Equipment.updateEquipment(this, id_offer);
    }

    static getName(equipment) {
      if(equipment.manufacturer && equipment.code) {
        return `${equipment.manufacturer.toUpperCase()} ${equipment.code}`;
      }
      return equipment.name;
    }

    static getDisplayedColumns(icon = null, checkbox = false) {
        let columns = {};
        if (checkbox) {
            columns = {
                ...columns,
                checkbox: {type: 'checkbox', color: 'accent', width: 5, disabled: (element: Equipment, selection: SelectionModel<unknown>) => selection.isMultipleSelection() && selection.hasValue() && !["contract_type","contract_duration","installation_mode","id_maintainer"].every(_ => selection.selected[0][_] == element[_])}
            };
        }
        columns = {
            ...columns,
            created_date: {label: 'Date de commande', mapping: _ => _?formatDate(_, "dd-MM-yyyy", "fr-FR"):_, width: 10},
            manufacturer: {label: 'Marque', width: 10},
            code: {label: 'Modèle', width: 10},
            contract_type: {label: 'Type', width: 10, mapping: _ => Fare.getContractTypeLabel({contract_type: _})},
            contract_duration: {label: 'Durée', width: 10, mapping: _ => Fare.getContractDurationLabel({contract_duration: _})},
            installation_mode: {label: 'Installation', width: 10, mapping: _ => Fare.getInstallationLabel({installation_mode: _})},
            serial_number: {label: 'N° de série', width: 30},
        };
        if (icon !== null) {
            columns = {
                ...columns,
                [icon]: {type: 'icon', width: 5}
            };
        }
        return columns;
    }

    static getFilteredColumns() {
        const displayedColumns = {
            ...Equipment.getDisplayedColumns()
        };
        return ['manufacturer','code','serial_number']
        .reduce((acc,val) => ({...acc, [val]: displayedColumns[val]['label']}), {});
    }

    static canDelete(equipment: Equipment) {
        return false;
    }

    canDelete() {
        return Equipment.canDelete(this);
    }

    getName() {
      return Equipment.getName(this);
    }
    
    static getEquipmentActionLabel(action) {
      switch (action.action_name)
      {
          case "delivery":
              return 'Livré';
              break;
          case "equipment_no_return":
              return 'Non restitué';
              break;
          case "equipment_return":
              return 'Restitué';
              break;
          case "installation_done":
          case "order_end":
              return 'En service';
              break;
          default:
              return 'En service';
      }
    }
    static getEquipmentActionColor(action) {
        switch (action.action_name)
        {
            case "equipment_no_return":
            case "equipment_return":
                return '#ee5842';
                break;
            default:
                return '#21716C';
        }
  }
  getEquipmentActionLabel(action) {
      return Equipment.getEquipmentActionLabel(action);
  }
  getEquipmentActionColor(action) {
      return Equipment.getEquipmentActionColor(action);
  }
}
