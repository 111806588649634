export enum ActionEnum {

    // post = 'Posté',
    // deliver = 'Délivré',
    // return = 'Restitué',
    // reject = 'Rejeté',
    // credit = 'Remboursé',
    // reverse = 'Annulé',
    
    order = 'Commande',
    sign = '',// 'Signature',
    nepting = 'Nepting',
    order_ack = 'Commande reçue avec succès par le mainteneur',
    handling = 'Commande prise en charge',
    quality_call = 'Appel qualité',
    preparation_start = 'Début de la préparation',
    preparation_end = 'Fin de la préparation',
    shipping = 'Commande expédiée',
    installation_appointment = 'Prise de RDV pour l\'installation',
    installation_done = 'Installé',
    delivery = 'Livré',
    execution = 'Exécuté',
    order_end = 'Mise en service',
    cancellation = 'Commande annulée',
    contract_update = 'Contrat mis à jour',
    customer_update = 'Données client en attente de mise à jour',
    customer_update_done = 'Données client mises à jour',
    equipment_update = 'Equipement mis à jour',
    equipment_update_done = 'Equipement mis à jour',
    purchase_maintenance_end = 'Fin de maintenance',
    purchase_sim3g_end = 'Fin de maintenance 3G',
    equipment_no_return = 'Equipement non restitué',
    equipment_return = 'Equipement restitué',
}
