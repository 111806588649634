import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { combineLatest, EMPTY, of } from 'rxjs';
import { catchError, debounceTime, filter, tap } from 'rxjs/operators';
import { BackendService } from 'src/app/services/backend/backend.service';
import { FormUtilsService } from 'src/app/services/form-utils/form-utils.service';
import { Order } from 'src/app/utils/model/order.model';
import { DialogComponent } from '../../dialog/dialog.component';
import { RefProductRequirement } from '../../model/ref_product_requirement.model';
import { RefProductIncompatibility } from '../../model/ref_product_incompatibility.model';

@Component({
  selector: 'app-order-display',
  templateUrl: './order-display.component.html',
  styleUrls: ['./order-display.component.scss']
})
export class OrderDisplayComponent implements OnInit {

  constructor(private formUtilsService: FormUtilsService, public backendService: BackendService, private keycloakService: KeycloakService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog) { }

  @Input()
  get formGroup(): FormGroup {
      return this.form;
  }
  set formGroup(formGroup: FormGroup) {
      this.form = formGroup;
  }
  form = this.formUtilsService.buildForm(new Order()) as FormGroup;

  @Input()
  refProductRequirements: RefProductRequirement[] = [];

  @Input()
  refProductIncompatibilities: RefProductIncompatibility[] = [];

  @Input()
  stepper: MatStepper;

  @Input()
  config = [
    {
      label: 'loyer mensuel',
      control: 'rental_equipments_total',
    },
    {
      label: 'vente',
      control: 'purchase_equipments_total'
    },
    {
      label: 'logiciels',
      control: 'purchase_softwares_total'
    },
    {
      label: 'accessoires',
      control: 'purchase_services_total'
    },
    {
      label: 'maintenance',
      control: 'yearly_maintenance_total'
    },
    {
      label: 'abonnement carte SIM',
      control: 'yearly_subscription3g_total'
    },
  ];

  loading = false;

  ngOnInit(): void {
    (this.form.get('suborders') as FormArray).controls.forEach(suborder => {
      this.loadContracts(suborder.get('equipments')).subscribe(() => {});
    });
    this.loadContracts(this.form.get('customer')).subscribe(() => {});
  }

  nosort() {}

  loadContracts(form: AbstractControl) {
    return combineLatest([form.valueChanges, form.statusChanges.pipe(filter(status => !['INVALID', 'PENDING'].includes(status)))])
    .pipe(
      debounceTime(1000),
      tap(() => {
        const errors = {};
        Object.entries((this.form.get('suborders') as FormArray).controls).forEach(([index,suborder]) => {
          const control = suborder.get("contracts");
          errors[index] = control.errors;
          control.setErrors(null);
        });
        if (this.form.enabled && !this.form.get('id').value) {
          this.loading = true;
          (this.form.get('suborders') as FormArray).controls.forEach(suborder => {
            this.formUtilsService.patchForm(suborder.get('contracts'), []);
          });
          this.backendService.requestWithoutCatchError('post', 'contracts', {body: Order.reduce(this.form.getRawValue())}).pipe(
            catchError(err => of(null))
          ).subscribe(contracts => {
            Object.entries((this.form.get('suborders') as FormArray).controls).forEach(([index,suborder]) => {
              const control = suborder.get("contracts");
              const validContracts = contracts && contracts[index] && contracts[index].every(contract => contract);
              if(!validContracts) control.setErrors({
                "": "Une erreur technique est survenue. Veuillez contacter l'administrateur du site ou réessayez ultérieurement."
              });
              else control.setErrors(null);
              this.formUtilsService.patchForm(control, validContracts?contracts[index]:[]);
            });
            this.loading = false;
          });
        } else {
          Object.entries((this.form.get('suborders') as FormArray).controls).forEach(([index,suborder]) => {
            suborder.get("contracts").setErrors(errors[index]);
          });
        }
      })
    );
  }

  cleanObject(value) {
    if (Array.isArray(value)) {
      return value.map(_ => this.cleanObject(_));
    } else if (value && typeof value === 'object' && value.toString() === '[object Object]') {
      return Object.keys(value).reduce((acc, val) => ({...acc, [val]: this.cleanObject(value[val])}), {})
    } else {
      if(typeof value === 'string' || value instanceof String) {
        value = value.trim();
        if(value === '') value = null;
      }
      return value;
    }
  }

  postOrder() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.loading = true;
    const order = this.cleanObject(Order.reduce(this.form.getRawValue()));
    this.backendService.requestWithoutCatchError('post', 'inactive/orders', {body: order}).pipe(
      catchError((err: HttpErrorResponse) => {
        console.error(err);
        this.dialog.closeAll();
        this.dialog.open(DialogComponent, {
          data: {
            buttons: {
              'OK': null
            },
            title: `Une erreur s'est produite lors de l'envoi de la commande`,
          },
        });
        return EMPTY;
      })
    ).subscribe({
        next: order => {
            if (order) {
                sessionStorage.removeItem("order");
                sessionStorage.removeItem("amgca");
                order = new Order(order);
                (this.form.get("suborders.0.contracts") as FormArray).controls.forEach((control: FormGroup) => {
                  if(!control.get("id")) control.addControl("id", new FormControl(null));
                });
                this.form.get('id').setValue(order.id);
                if (this.stepper) {
                  this.stepper.next();
                }
                this.form.patchValue(order);
                // order.suborders.forEach(suborder => {
                //   suborder.customer = order.customer;
                //   this.backendService.mail(suborder).subscribe(_ => _);
                // });
            }
        },
        complete: () => this.loading = false
    });
  }

  surrender() {
    this.dialog.open(DialogComponent, {
      data: {
        title: "Souhaitez-vous abandonner la saisie de la commande ?",
        buttons: {
          "Oui": true,
          "Non": false
        }
      }
    }).beforeClosed().subscribe(_ => {
      if(_) {
        this.router.navigate([".."], {relativeTo: this.route})
      }
    });
  }

}
