import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {auditTime, distinctUntilChanged, filter, switchMap} from 'rxjs/operators';
import {AmgcaService} from 'src/app/services/amgca/amgca.service';
import {BackendService} from 'src/app/services/backend/backend.service';
import {FormUtilsService} from 'src/app/services/form-utils/form-utils.service';
import {ReferentialService} from 'src/app/services/referential/referential.service';
import {Customer, CustomerStore} from 'src/app/utils/model/customer.model';
import {bicIbanValidator} from 'src/app/utils/validators/bic-iban.validator';
import {bicValidator} from 'src/app/utils/validators/bic.validator';
import {ibanValidator} from 'src/app/utils/validators/iban.validator';
import {luhnValidator} from 'src/app/utils/validators/luhn.validator';
import {nafValidator} from 'src/app/utils/validators/naf.validator';
import {PhoneValidator} from 'src/app/utils/validators/phone.validator';
import {addressValidator} from '../../validators/address.validator';
import {mccValidator} from '../../validators/mcc.validator';
import {LoginValidator} from '../../validators/login.validator';
import {ConfigService} from '../../../services/config/config.service';
import {Offer} from '../../model/offer.model';
import {forkJoin, Observable, of} from 'rxjs';
import {DialogComponent} from '../../dialog/dialog.component';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit {

  constructor(
    private formUtilsService: FormUtilsService,
    private referentialService: ReferentialService,
    private backendService: BackendService,
    private dialog: MatDialog,
    private amgcaService: AmgcaService,
    private loginValidator: LoginValidator,
    private phoneValidator: PhoneValidator,
    private configService: ConfigService,
  ) {
  }

  @Input()
  get formGroup(): FormGroup {
    return this.form;
  }

  set formGroup(formGroup: FormGroup) {
    this.form = formGroup;
  }

  @Input()
  get offer(): Offer {
    return this.refOffer;
  }

  set offer(offer: Offer) {
    this.refOffer = offer;
  }

  @Input()
  get additionalOrders(): any {
    return this.additionalOrder;
  }

  set additionalOrders(additionalOrders: any) {
    this.additionalOrder = additionalOrders;
  }

  @Input()
  get idMaintainer(): string {
    return this.maintainer;
  }

  set idMaintainer(maintainer: string) {
    this.maintainer = maintainer;
  }

  @Input()
  get refCustomer(): Partial<Customer> {
    return this.rCustomer;
  }

  set refCustomer(rCustomer: Partial<Customer>) {
    this.rCustomer = rCustomer;
  }

  maintainer: string;
  refOffer: Offer;
  rCustomer: Partial<Customer>;
  additionalOrder: boolean;
  form = this.formUtilsService.buildForm(new Customer()) as FormGroup;
  customerForm = this.formUtilsService.buildForm(new Customer()) as FormGroup;

  nafs = require('../../../services/referential/naf.json');
  mccs = require('../../../services/referential/mcc.json');

  @Input()
  config = {
    Société: [
      {
        formControlName: 'store_registration_number',
        label: 'SIRET',
        required: true,
        pattern: /[0-9]{14}/,
        minLength: 14,
        maxLength: 14,
        validators: [luhnValidator]
      },
      {
        formControlName: 'company_registration_number',
        label: 'SIREN',
        pattern: /[0-9]{9}/,
        minLength: 9,
        maxLength: 9,
        validators: [luhnValidator]
      },
      {
        formControlName: 'customer_intracommunity_vat',
        label: 'TVA Intracommunautaire'
      },
      {
        formControlName: 'company_name',
        label: 'Dénomination sociale',
        colspan: 6,
        required: true,
        maxLength: 50,
      },
      {
        formControlName: 'store_name',
        label: 'Enseigne',
        colspan: 6,
        required: true,
        maxLength: 50,
      },
      // {
      //     formControlName: 'billing_address.name',
      //     label: 'Nom et prénom du dirigeant',
      //     required: true,
      //     maxLength: 50,
      // },
      {
        formControlName: 'civility',
        label: 'Civilité',
        input: 'select',
        options: {
          'M.': 'M.',
          'Mme.': 'Mme.',
          'Mlle.': 'Mlle.'
        },
        colspan: 2,
        required: true,
        maxLength: 50,
      },
      {
        formControlName: 'lastname',
        label: 'Nom du dirigeant',
        colspan: 5,
        required: true,
        maxLength: 50,
      },
      {
        formControlName: 'firstname',
        label: 'Prénom du dirigeant',
        colspan: 5,
        required: true,
        maxLength: 50,
      },
      {
        formControlName: 'billing_address.phone',
        label: 'Téléphone portable',
        type: 'tel',
        required: true,
        pattern: /[0-9]{10}/,
        minLength: 10,
        maxLength: 10,
        validators: [this.phoneValidator],
        error: (error: { [key: string]: string }): string => {
          if (Object.keys(error)?.[0] === 'required') {
            return this.configService.config.validators.phone.required;
          }
          return Object.keys(error)?.[0];
        },
      },
      {
        formControlName: 'fiscal_address.phone',
        label: 'Téléphone fixe',
        type: 'tel',
        pattern: /[0-9]{10}/,
        minLength: 10,
        maxLength: 10,
        validators: [this.phoneValidator],
        error: (error: { [key: string]: string }): string => {
          return Object.keys(error)?.[0];
        },
      },
      {
        formControlName: 'billing_address.email',
        label: 'Email',
        type: 'email',
        required: true,
        pattern: /\S*/,
        maxLength: 80,
      },
      {
        formControlName: 'french_naf_code',
        label: 'Code NAF',
        colspan: 2,
        required: true,
        pattern: /\S{5}/,
        minLength: 5,
        maxLength: 5,
        validators: [nafValidator]
      },
      {
        formControlName: 'french_naf_label',
        label: 'Libellé NAF',
        colspan: 6,
        autocomplete: {}
      },
      {
        formControlName: 'mcc',
        label: 'Code MCC',
        required: true,
        pattern: /[0-9]{4}/,
        minLength: 4,
        maxLength: 4,
        validators: [mccValidator],
        autocomplete: {}
      },
    ],
    Nepting: [
      {
        display: false, // changed in ngOnInit
        formControlName: 'billing_address.email',
        label: 'Email',
        type: 'label',
        colspan: 6,
      },
      {
        formControlName: 'login',
        label: 'Login',
        required: false, // changed in ngOnInit
        pattern: /[0-9a-z_]+/,
        maxLength: 64,
        updateOn: 'blur',
        error: (error: { [key: string]: boolean }): string => {
          if (error && Object.keys(error)?.[0] === 'required') {
            return this.configService.config.validators.login.required;
          }
          return error ? Object.keys(error)[0] : null;
        },
        asyncValidators: [this.loginValidator.validate.bind(this.loginValidator)],
      },
    ],
    'Informations disponibles sur \'Societe.com\'': [
      {
        form: this.customerForm,
        formControlName: 'store_registration_number',
        colspan: 2,
        label: 'SIRET',
        type: 'label',
        tooltip: 'En cas d\'incohérence avec les informations récupérées, il faudra éclaircir la situation avec votre commerçant avant d\'effectuer toute modification dans "Gestion contrat monétique - AMGCA"',
        color: 'accent',
        icon: 'help_outline',
      },
      {
        form: this.customerForm,
        formControlName: 'company_name',
        colspan: 3,
        label: 'Dénomination sociale',
        type: 'label'
      },
      {
        form: this.customerForm,
        formControlName: 'store_name',
        colspan: 3,
        label: 'Enseigne',
        type: 'label'
      },
      {
        form: this.customerForm,
        formControlName: 'store_address.address_full',
        label: 'Adresse de point de vente',
        colspan: 4,
        type: 'label'
      },
    ],
    Adresses: [
      {
        formControlName: 'store_address.address',
        label: 'Adresse de point de vente',
        tooltip: 'Information récupérée, à modifier si erronée dans "Gestion contrat monétique - AMGCA"',
        color: 'accent',
        icon: 'help_outline',
        colspan: 5,
        required: true,
        maxLength: 64,
        autocomplete: {}
      },
      {
        formControlName: 'store_address.locality',
        label: 'Lieu-dit',
        colspan: 3,
        maxLength: 50,
        autocomplete: {},
        error: (error) => {
          let e = 'Lieu-dit invalide';
          if (error?.addresses) {
            e += `: voulez-vous dire ${error?.addresses.map(a => a.locality).filter((v, i, a) => a.indexOf(v) === i).join(' ou ')}?`;
          }
          return e;
        }
      },
      {
        formControlName: 'store_address.zip_code',
        label: 'Code Postal',
        colspan: 1,
        required: true,
        pattern: /[0-9]{5}/,
        minLength: 5,
        maxLength: 5,
        autocomplete: {},
        // error: (error) => {
        //     let e = "Code Postal invalide";
        //     if(error?.addresses) {
        //         e+=`: voulez-vous dire ${error?.addresses.map(a => a.zip_code).filter((v,i,a) => a.indexOf(v)===i).join(" ou ")}?`;
        //     }
        //     return e;
        // }
      },
      {
        formControlName: 'store_address.city_name',
        label: 'Ville',
        colspan: 3,
        required: true,
        maxLength: 50,
        autocomplete: {},
        error: (error) => {
          let e = 'Ville invalide';
          if (error?.addresses) {
            e += `: voulez-vous dire ${error?.addresses.map(a => a.city_name).filter((v, i, a) => a.indexOf(v) === i).join(' ou ')}?`;
          }
          return e;
        }
      },
      {
        formControlName: 'delivery_address.address',
        label: 'Adresse de livraison',
        tooltip: 'Modifiable exceptionnellement si le point de vente est en travaux par exemple',
        color: 'accent',
        icon: 'help_outline',
        colspan: 5,
        required: true,
        maxLength: 64,
        autocomplete: {}
      },
      {
        formControlName: 'delivery_address.locality',
        label: 'Lieu-dit',
        colspan: 3,
        maxLength: 50,
        autocomplete: {},
        error: (error) => {
          let e = 'Lieu-dit invalide';
          if (error?.addresses) {
            e += `: voulez-vous dire ${error?.addresses.map(a => a.locality).filter((v, i, a) => a.indexOf(v) === i).join(' ou ')}?`;
          }
          return e;
        }
      },
      {
        formControlName: 'delivery_address.zip_code',
        label: 'Code Postal',
        colspan: 1,
        required: true,
        pattern: /[0-9]{5}/,
        minLength: 5,
        maxLength: 5,
        autocomplete: {},
        // error: (error) => {
        //     let e = "Code Postal invalide";
        //     if(error?.addresses) {
        //         e+=`: voulez-vous dire ${error?.addresses.map(a => a.zip_code).filter((v,i,a) => a.indexOf(v)===i).join(" ou ")}?`;
        //     }
        //     return e;
        // }
      },
      {
        formControlName: 'delivery_address.city_name',
        label: 'Ville',
        colspan: 3,
        required: true,
        maxLength: 50,
        autocomplete: {},
        error: (error) => {
          let e = 'Ville invalide';
          if (error?.addresses) {
            e += `: voulez-vous dire ${error?.addresses.map(a => a.city_name).filter((v, i, a) => a.indexOf(v) === i).join(' ou ')}?`;
          }
          return e;
        }
      },
      {
        formControlName: 'billing_address.address',
        label: 'Adresse de facturation',
        tooltip: 'Modifiable pour envoi au comptable par exemple',
        color: 'accent',
        icon: 'help_outline',
        colspan: 5,
        required: true,
        maxLength: 64,
        autocomplete: {}
      },
      {
        formControlName: 'billing_address.locality',
        label: 'Lieu-dit',
        colspan: 3,
        maxLength: 50,
        autocomplete: {},
        error: (error) => {
          let e = 'Lieu-dit invalide';
          if (error?.addresses) {
            e += `: voulez-vous dire ${error?.addresses.map(a => a.locality).filter((v, i, a) => a.indexOf(v) === i).join(' ou ')}?`;
          }
          return e;
        }
      },
      {
        formControlName: 'billing_address.zip_code',
        label: 'Code Postal',
        colspan: 1,
        required: true,
        pattern: /[0-9]{5}/,
        minLength: 5,
        maxLength: 5,
        autocomplete: {},
        // error: (error) => {
        //     let e = "Code Postal invalide";
        //     if(error?.addresses) {
        //         e+=`: voulez-vous dire ${error?.addresses.map(a => a.zip_code).filter((v,i,a) => a.indexOf(v)===i).join(" ou ")}?`;
        //     }
        //     return e;
        // }
      },
      {
        formControlName: 'billing_address.city_name',
        label: 'Ville',
        colspan: 3,
        required: true,
        maxLength: 50,
        autocomplete: {},
        error: (error) => {
          let e = 'Ville invalide';
          if (error?.addresses) {
            e += `: voulez-vous dire ${error?.addresses.map(a => a.city_name).filter((v, i, a) => a.indexOf(v) === i).join(' ou ')}?`;
          }
          return e;
        }
      },
      // {
      //     formControlName: 'fiscal_address.address',
      //     label: 'Adresse fiscale',
      //     colspan: 5,
      //     required: true,
      //     maxLength: 64,
      //     autocomplete: {}
      // },
      // {
      //     formControlName: 'fiscal_address.locality',
      //     label: 'Lieu-dit',
      //     colspan: 3,
      //     maxLength: 50,
      //     autocomplete: {},
      //     error: (error) => {
      //         let e = "Lieu-dit invalide";
      //         if(error?.addresses) {
      //             e+=`: voulez-vous dire ${error?.addresses.map(a => a.locality).filter((v,i,a) => a.indexOf(v)===i).join(" ou ")}?`;
      //         }
      //         return e;
      //     }
      // },
      // {
      //     formControlName: 'fiscal_address.zip_code',
      //     label: 'Code Postal',
      //     colspan: 1,
      //     required: true,
      //     pattern: /[0-9]{5}/,
      //     minLength: 5,
      //     maxLength: 5,
      //     autocomplete: {},
      //     // error: (error) => {
      //     //     let e = "Code Postal invalide";
      //     //     if(error?.addresses) {
      //     //         e+=`: voulez-vous dire ${error?.addresses.map(a => a.zip_code).filter((v,i,a) => a.indexOf(v)===i).join(" ou ")}?`;
      //     //     }
      //     //     return e;
      //     // }
      // },
      // {
      //     formControlName: 'fiscal_address.city_name',
      //     label: 'Ville',
      //     colspan: 3,
      //     required: true,
      //     maxLength: 50,
      //     autocomplete: {},
      //     error: (error) => {
      //         let e = "Ville invalide";
      //         if(error?.addresses) {
      //             e+=`: voulez-vous dire ${error?.addresses.map(a => a.city_name).filter((v,i,a) => a.indexOf(v)===i).join(" ou ")}?`;
      //         }
      //         return e;
      //     }
      // },
    ],
    'Références bancaires': [
      {
        formControlName: 'bank_account.iban',
        label: 'IBAN',
        input: 'iban',
        colspan: 12,
        required: true,
        minLength: 27,
        maxLength: 27,
        validators: [ibanValidator]
      },
      {
        formControlName: 'bank_account.bic',
        label: 'BIC',
        colspan: 2,
        required: true,
        pattern: /\S{8,11}/,
        minLength: 8,
        maxLength: 11,
        validators: [bicValidator]
      },
      {
        formControlName: 'bank_account.bank',
        label: 'Banque',
        colspan: 10
      },
    ],
    'Contact livraison & installation': [
      {
        formControlName: 'delivery_address.name',
        label: 'Nom et Prénom',
        required: true,
        maxLength: 50,
      },
      {
        formControlName: 'delivery_address.phone',
        label: 'Téléphone',
        type: 'tel',
        required: true,
        pattern: /[0-9]{10}/,
        minLength: 10,
        maxLength: 10,
        validators: [this.phoneValidator],
        error: (error: { [key: string]: string }): string => {
          if (Object.keys(error)?.[0] === 'required') {
            return this.configService.config.validators.phone.required;
          }
          return Object.keys(error)?.[0];
        },
      },
      {
        formControlName: 'delivery_address.email',
        label: 'Email',
        type: 'email',
        required: true,
        pattern: /\S*/,
        maxLength: 80,
      },
      {
        formControlName: 'contact_comment',
        label: `Commentaires (à renseigner si important, exemple : heure de pré-appel souhaité - horaires et jours d’ouverture – connexion à une caisse de type Concert V2 …)<br>
              <span class="color-primary">Pour l’offre COMPACT : si le dirigeant souhaite suivre l’activité de plusieurs matériels, ajouter dans cette zone les noms et prénoms des autres utilisateurs</span>`,
        colspan: 12,
        rowspan: 2,
        minRows: 6,
        maxRows: 6,
        input: 'textarea',
        maxLength: 200
      }
    ]
  };

  @Input()
  toggles = {
    '': false,
    bank_account: true,
    delivery_address: true,
    billing_address: true,
    fiscal_address: true,
    contact_comment: true,
    civility: true,
    lastname: true,
    firstname: true
  };

  nosort(): void {
  }

  updateCustomerForm(siret = null): void {
    if (!siret) {
      siret = this.form.get('store_registration_number').value;
    }
    if (siret) {
      forkJoin({
        login: this.getMerchantId().pipe(
          switchMap(merchantId => this.getLoginFromUser(merchantId))
        ),
        customer: this.referentialService.entrepriseSearch(siret)
      }).subscribe({
        next: (value: { login: string[] | null, customer: CustomerStore }) => {
          this.customerForm.patchValue(value.customer);
          this.updateLoginAndToggleForm(value.login?.[0]);
        }
      });
    }
  }

  getLoginFromUser(merchantId: string | null): Observable<string[] | null> {
    if (!merchantId) {
      return of(null);
    }
    const options = {
      params: {
        searchCriterias: JSON.stringify(
          [
            {
              logical: 'and',
              path: 'merchantId',
              operation: 'equal',
              value: merchantId,
            }
          ]
        ),
      },
    };
    return this.backendService.request('get', 'nepting/user/login', options);
  }

  getMerchantId(): Observable<string | null> {
    if (!this.offer) {
      return of(null);
    }
    if (this.offer?.id_ref) {
      this.offer.id = this.offer.id_ref;
    }
    const options = {
      body: this.offer,
      params: {
        refCustomerId: this.refCustomer?.id_ref?.toString() ?? this.formGroup.get('id_ref').value?.toString(),
        idMaintainer: this.idMaintainer,
      },
      responseType: 'text' as 'text',
    };
    return this.backendService.request('post', 'nepting/generate/merchantId', options);
  }

  updateLoginAndToggleForm(login?: string): void {
    if (arguments.length === 1) {
      this.form.get('login').patchValue(login);
      this.formUtilsService.toggleForm(this.form.get('login'), !login);
    } else {
      this.getMerchantId().pipe(
        switchMap(merchantId => this.getLoginFromUser(merchantId))
      ).subscribe((loginArray: string[]) => {
        this.updateLoginAndToggleForm(loginArray?.[0] || null);
      });
    }
  }

  isControlExposed(control: AbstractControl): boolean {
    return !!Object.values(this.config).reduce(
      (acc, val) => [...acc, ...val], []).find(conf => !conf['form'] && this.form.get(conf.formControlName) === control
    );
  }

  isMissingDataWhenRegistrationNumberIsFilled(): boolean {
    return this.form.get('store_registration_number').value
      && this.form.get('company_name').disabled
      && !this.form.get('company_name').value;
  }

  displayPopupMissingData(): void {
    this.dialog.open(DialogComponent, {
      data: {
        title: 'Il y a eu une erreur lors de l\'acquisition des informations client, merci de bien vouloir réessayer plus tard',
        buttons: {
          Continuer: true,
        }
      }
    });
  }

  ngOnInit(): void {
    this.config.Nepting[0].display = this.offer?.nepting_user && !this.additionalOrders;
    this.config.Nepting[1].required = this.offer?.nepting_user && !this.additionalOrders;
    if (!this.form.parent || this.form.root.get('customer') === this.form) {
      this.updateCustomerForm();
      ['store_address', 'delivery_address', 'billing_address', 'fiscal_address'].forEach(type => {
        this.form.get(type).setValidators([addressValidator]);
        ['locality'].forEach(attribute => {
          const control = this.form.get(`${type}.${attribute}`);
          control.setValue(control.value ?? '', {emitEvent: false});
        });
      });
      Object.entries(this.toggles).forEach(([k, v]) => this.formUtilsService.toggleForm(k ? this.form.get(k) : this.form, v));

      this.form.get('bank_account').setValidators([bicIbanValidator]);
      this.form.get('bank_account').updateValueAndValidity();

      if (this.form.get('billing_address.name').value && !this.form.get('firstname').value && this.form.get('firstname').pristine) {
        this.form.get('firstname').setValue(this.form.get('billing_address.name').value);
      }

      const names = ['civility', 'lastname', 'firstname'];
      names.forEach(controlName => this.form.get(controlName).valueChanges.subscribe(v => {
        if (this.form.get('billing_address.name').pristine) {
          this.form.get('billing_address.name').setValue(names.map(_ => this.form.get(_).value).join(' '));
        }
      }));

      ['customer_intracommunity_vat', 'company_registration_number', 'french_naf_label', 'bank_account.bank'].forEach(
        controlName => this.form.get(controlName).disable()
      );

      this.form.get('company_registration_number').valueChanges.pipe(distinctUntilChanged(), filter(v => v)).subscribe(v => {
        this.form.get('customer_intracommunity_vat').setValue(`FR${((12 + 3 * (v % 97)) % 97)
          .toString()
          .padStart(2, '0')}${v}`);
      });
      this.form.get('store_registration_number').valueChanges.pipe(distinctUntilChanged(), filter(v => v)).subscribe(v => {
        if (!this.form.get('id_ref').value || this.form.get('store_registration_number').dirty) {
          if (this.form.get('store_registration_number').valid) {
            this.form.get('company_registration_number').setValue((v || '').substring(0, 9));
            this.backendService.requestRef('get', 'refCustomers', {params: {store_registration_number: v}})
              .subscribe((customers: Partial<Customer>[]) => {
                if (customers && customers.length) {
                  const customer = customers.filter(_ => _.store_registration_number === v).pop();
                  this.refCustomer = customer;
                  this.loginValidator.setRefCustomerId(this.refCustomer.id_ref.toString());
                  this.updateLoginAndToggleForm();
                  if (customer) {
                    // this.dialog.open(DialogComponent, {
                    //   data: {
                    //     content: 'Attention, le SIRET de ce client existe déjà ! Que souhaitez-vous faire ?',
                    //     buttons: {
                    //       'Continuer avec mon client': true,
                    //       'Continuer avec un nouveau SIRET': false
                    //     }
                    //   }
                    // }).beforeClosed().subscribe(import_customer => {
                    //   if (import_customer === true) {
                    //     this.form.patchValue(customer);
                    //   } else if (import_customer === false) {
                    //     this.referentialService.entrepriseSearch(v).subscribe(_ => {
                    //       this.form.patchValue(_);
                    //       if (this.form.get('store_name').value === this.form.get('company_name').value) {
                    //         this.form.get('store_name').markAsPristine();
                    //       }
                    //       else {
                    //         this.form.get('store_name').markAsDirty();
                    //       }
                    //     });
                    //   }
                    // });
                    this.form.patchValue(customer, {emitEvent: false});
                    if (sessionStorage.getItem('amgca')) {
                      const amgcaCustomer = this.amgcaService.amgcaToOrder(JSON.parse(sessionStorage.getItem('amgca'))).customer;
                      this.form.patchValue(ReferentialService.filterObject(amgcaCustomer), {emitEvent: false});
                    }
                    return;
                  }
                }
                this.referentialService.entrepriseSearch(v).subscribe(customer => {
                  this.form.patchValue(customer);
                  if (this.form.get('store_name').value === this.form.get('company_name').value) {
                    this.form.get('store_name').markAsPristine();
                  } else {
                    this.form.get('store_name').markAsDirty();
                  }
                  if (sessionStorage.getItem('amgca')) {
                    const amgcaCustomer = this.amgcaService.amgcaToOrder(JSON.parse(sessionStorage.getItem('amgca'))).customer;
                    this.form.patchValue(ReferentialService.filterObject(amgcaCustomer), {emitEvent: false});
                  }
                });
              });
            this.updateCustomerForm(v);
          } else {
            this.form.get('id').setValue(null);
            if (this.isMissingDataWhenRegistrationNumberIsFilled()) {
              this.displayPopupMissingData();
            }
          }
        }
      });
      this.form.get('french_naf_code').valueChanges.pipe(distinctUntilChanged(), filter(v => v)).subscribe(v => {
        this.config.Société.find(_ => _.formControlName === 'french_naf_code').autocomplete = !this.form.get('french_naf_code').valid ?
          Object.keys(this.nafs).filter(naf => naf.includes(v)).reduce((acc, val) => ({
            ...acc,
            [val]: {french_naf_code: val}
          }), {})
          : {};
        this.referentialService.getFrenchNafLabel(v).subscribe(_ => this.form.patchValue(_));
        this.referentialService.getFrenchApeCode(v).pipe(switchMap((_: any) =>
          this.referentialService.getMcc(_.french_ape_code))
        ).subscribe(_ => this.form.patchValue(_));
      });
      this.form.get('mcc').valueChanges.pipe(distinctUntilChanged(), filter(v => v)).subscribe(v => {
        this.config.Société.find(_ => _.formControlName === 'mcc').autocomplete = !this.form.get('mcc').valid ?
          Object.values(this.mccs).filter((mcc: string) => mcc.includes(v)).reduce((acc: object, val: string) => ({
            ...acc,
            [val]: {mcc: val}
          }), {})
          : {};
      });
      this.form.get('bank_account.iban').valueChanges.pipe(distinctUntilChanged(), filter(v => v)).subscribe(v => {
        this.referentialService.getBIC(v).subscribe(_ => this.form.patchValue(_));
      });
      this.form.get('bank_account.bic').valueChanges.pipe(distinctUntilChanged(), filter(v => v)).subscribe(v => {
        this.referentialService.getBank(v).subscribe(_ => this.form.patchValue(_));
      });
      ['store_address', 'delivery_address', 'billing_address', 'fiscal_address'].forEach(type => {
        ['address'].forEach(attribute => {
          const formControlName = `${type}.${attribute}`;
          const config = this.config.Adresses.find(_ => _.formControlName === formControlName);
          this.form.get(formControlName).valueChanges.pipe(distinctUntilChanged(), filter(v => v), auditTime(1000)).subscribe(v => {
            v = this.referentialService.normalizeCityName(v);
            this.form.get(formControlName).setValue(v, {emitEvent: false});
            if ((!this.form.get(type + '.id_ref').value && !JSON.parse(sessionStorage.getItem('amgca'))) ||
              this.form.get(formControlName).dirty) {
              this.referentialService.addresseSearch(v).subscribe(_ => {
                config.autocomplete = _.reduce((acc, val) => {
                  acc[val.label] = {[type]: val};
                  return acc;
                }, {});
                if (Object.values(config.autocomplete).length === 1) {
                  this.form.patchValue(Object.values(config.autocomplete)[0], {emitEvent: false});
                  config.autocomplete = {};
                }
              });
            }
          });
        });
        ['locality', 'zip_code', 'city_name'].forEach(attribute => {
          const formControlName = `${type}.${attribute}`;
          const config = this.config.Adresses.find(_ => _.formControlName === formControlName);
          this.form.get(formControlName).valueChanges.pipe(distinctUntilChanged(), filter(v => v), auditTime(1000)).subscribe(v => {
            v = this.referentialService.normalizeCityName(v);
            if ((!this.form.get(type + '.id_ref').value && !JSON.parse(sessionStorage.getItem('amgca'))) ||
              this.form.get(formControlName).dirty) {
              this.referentialService.laposteSearch(v).subscribe(_ => {
                config.autocomplete = _.reduce((acc, val) => {
                  acc[Object.values(val).join(' ')] = {[type]: val};
                  return acc;
                }, {});
                if (Object.values(config.autocomplete).length === 1) {
                  this.form.patchValue(Object.values(config.autocomplete)[0], {emitEvent: false});
                  config.autocomplete = {};
                }
              });
            }
            this.form.get(formControlName).setValue(v, {emitEvent: false});
          });
        });
      });
      const storeFormAddress = this.form.get('store_address');
      ['address', 'locality', 'zip_code', 'city_name'].forEach(attribute => {
        storeFormAddress.get(attribute).valueChanges.pipe(distinctUntilChanged(), auditTime(100)).subscribe(v => {
          if (!storeFormAddress.get(attribute).invalid) {
            ['delivery_address', 'billing_address', 'fiscal_address'].forEach(controlName => {
              const addressForm = this.form.get([controlName, attribute]);
              if ((!this.isControlExposed(addressForm) || addressForm.enabled || !addressForm.value) && addressForm.pristine) {
                if (attribute !== 'address') {
                  v = this.referentialService.normalizeCityName(v);
                }
                addressForm.setValue(v, {emitEvent: false});
              }
            });
          }
        });
      });
      const billingForm = this.form.get('billing_address');
      billingForm.valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
        ['name', 'phone', 'email'].forEach(attribute => {
          if (!billingForm.get(attribute).invalid) {
            ['store_address', 'delivery_address', 'fiscal_address'].forEach(controlName => {
              if (!(attribute === 'phone' && controlName === 'fiscal_address')) {
                const addressForm = this.form.get([controlName, attribute]);
                if ((!this.isControlExposed(addressForm) || addressForm.enabled || !addressForm.value) && addressForm.pristine) {
                  addressForm.setValue(billingForm.get(attribute).value, {emitEvent: false});
                }
              }
            });
          }
        });
        const phoneForm = this.form.get('phone');
        if (
          !billingForm.get('phone').invalid &&
          ((!this.isControlExposed(phoneForm) || phoneForm.enabled || !phoneForm.value)) &&
          phoneForm.pristine) {
          phoneForm.setValue(billingForm.get('phone').value);
        }
      });
      const fiscalForm = this.form.get('fiscal_address');
      fiscalForm.valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
        const phoneForm = this.form.get('secondary_phone');
        if (
          !fiscalForm.get('phone').invalid &&
          ((!this.isControlExposed(phoneForm) || phoneForm.enabled || !phoneForm.value)) &&
          phoneForm.pristine) {
          phoneForm.setValue(fiscalForm.get('phone').value);
        }
      });
      this.form.get('bnpp').valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
        try {
          this.form.get('bnpp').setValue(JSON.parse(v));
        } catch (e) {
        }
      });
      this.form.get('contact_comment').valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
        this.form.get('contact_comment').setValue(v, {onlySelf: true, emitEvent: false});
      });
      const companyForm = this.form.get('company_name');
      companyForm.valueChanges.pipe(distinctUntilChanged()).subscribe(v => {
        const storeFormName = this.form.get('store_name');
        if (
          !companyForm.invalid &&
          ((!this.isControlExposed(storeFormName) || storeFormName.enabled || !storeFormName.value)) &&
          storeFormName.pristine) {
          storeFormName.setValue(companyForm.value);
        }
      });
      if (JSON.parse(sessionStorage.getItem('amgca'))) {
        ['delivery_address', 'billing_address', 'fiscal_address'].forEach(controlName => {
          ['address', 'locality', 'zip_code', 'city_name'].forEach(attribute => {
            this.form.get(`${controlName}.${attribute}`).setValue(this.form.get(`store_address.${attribute}`).value);
          });
        });
      }
    }
    this.loginValidator.setOffer(this.offer);
    this.loginValidator.setIdMaintainer(this.idMaintainer);
  }
}
